import React from 'react'
import classNames from 'classnames'

import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import { ProductCookie } from '../../../services/storage/cookies/ProductCookie'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { type RegisterModalProps } from './RegisterModalProps'
import { SignUp } from '../../molecules'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { replaceValues } from 'src/services/utils/replaceAll'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useIstestAB8814 } from '../../../services/hooks/useIstestAB8814'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'

import './RegisterModal.scss'

export const RegisterModal: React.FC<RegisterModalProps> = ({
  closeFunction,
  toggleOpenLoginCloseRegister,
  isRegisterModal,
  urlRedirect,
  changeUrlRedirect
}) => {
  const [isSuccessful, setIsSuccessful] = React.useState(false)
  const data = useQuerySharedComponentsFromPaymentPage()
  const { applicationCookie } = useApplicationCookie()
  const [footerInfo, setFooterInfo] = React.useState({
    text: data.registerModal?.footerText ?? 'Already have an account?',
    button: {
      label: data.registerModal?.footerCta,
      onClick: toggleOpenLoginCloseRegister,
      noLink: true
    }
  })
  const registerModal = React.useRef<HTMLDivElement | null>(null)
  const referrerCookie = new ReferrerCookie()
  const [statusSignUp, updateStatusSignUp] = React.useState('initial')
  const productCookie = new ProductCookie()
  const [isTestAB8814] = useIstestAB8814({
    product: getParamUrlValue('product') ?? productCookie.product
  })

  const onSuccess = React.useCallback((): void => {
    setIsSuccessful(true)
  }, [])

  const onResetSuccess = React.useCallback((): void => {
    setIsSuccessful(false)
  }, [])

  const handleSuccessAction = React.useCallback(() => {
    if (!urlRedirect) {
      changeUrlRedirect?.(null)
    }
    closeFunction()
  }, [urlRedirect, changeUrlRedirect, closeFunction])

  const handleChangeStatusSignUp = React.useCallback((status: string): void => {
    updateStatusSignUp(status)
  }, [])

  const Form = (
    <SignUp
      onResetSuccess={onResetSuccess}
      onSuccess={onSuccess}
      isModal
      closeModal={handleSuccessAction}
      showThanks={urlRedirect}
      handleChangeStatus={handleChangeStatusSignUp}
      isTestAB8814={isTestAB8814}
    />
  )

  React.useEffect(() => {
    const eventProps = {
      source: referrerCookie.getReferrer()
    }
    sendAmplitudeData('click_registraton_link', eventProps)
  }, [])

  React.useEffect(() => {
    const boxFooter = {
      text: data.registerModal?.footerText ?? 'Already have an account?',
      button: {
        label: data.registerModal?.footerCta,
        onClick: toggleOpenLoginCloseRegister,
        noLink: true
      }
    }

    if (isRegisterModal) {
      setFooterInfo(boxFooter)
    }

    if (!isSuccessful) {
      setFooterInfo(boxFooter)
    } else {
      setFooterInfo(undefined)
    }
  }, [isRegisterModal, isSuccessful, toggleOpenLoginCloseRegister])

  const modalIsSuccess = (): boolean | undefined => {
    if (isSuccessful) {
      location.reload()
      return false
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSuccessful])

  const handleClickOutside = (event): void => {
    const clickOutOfSelect = registerModal.current && !registerModal.current.contains(event.target)

    if (clickOutOfSelect) {
      closeFunction()
      modalIsSuccess()
    }
  }

  const closeModalAndReloadPage = (): void => {
    closeFunction()
    modalIsSuccess()
  }

  const titleModal = (): string => {
    if (isTestAB8814 && applicationCookie?.form?.name && statusSignUp === 'initial') {
      return (
        replaceValues(data.registerModal?.alternativeTitle, { FORM_NAME: applicationCookie.form.name }) ??
        `Save your ${applicationCookie.form.name} by creating a free account`
      )
    }

    return (data.registerModal?.title as string) ?? ''
  }

  // testAB8814
  const classRegisterModal = classNames('register-modal', {
    'register-modal--testAB8814': isTestAB8814
  })

  return (
    <>
      <div className={classRegisterModal}>
        <div ref={registerModal} className='main-container'>
          <div className='button-form-container'>
            <BoxForm
              title={titleModal()}
              body={Form}
              footer={footerInfo}
              closeInsideModal
              closeModalAndReloadPage={closeModalAndReloadPage}
            />
          </div>
        </div>
      </div>
    </>
  )
}
