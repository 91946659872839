/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'

import { Footer } from '../Footer'
import { Header } from '../Header'
import { type LayoutProps } from './LayoutProps'
import { MicroFooter } from '../MicroFooter'
import { showWarningViaConsole } from '@legal/shared/utils/showWarningViaConsole'

export const Layout: React.FC<LayoutProps> = ({
  hideHeader = false,
  hideMobileHeader = false,
  isAnonymousPaymentPage = false,
  showMobileMicroFooter = false,
  headerHasNoProducts = false,
  headerFullWidth = false,
  noFooter = false,
  children,
  headerBackground,
  ctaFooter,
  hideNavbar = false,
  hideDesktopNavbar = false,
  hideDashboardButton = false,
  registerModalOpen = false,
  toggleRegister,
  loginModalOpen = false,
  toggleLogin,
  disclaimerLinks = false,
  environment,
  showContactBar = false,
  footerContactBar = false,
  bottomOverlayContent,
  changeUrlRedirect,
  isTestAB8814 = false,
  currentStepName,
  logoShowModal = false,
  showModalOnLinks = false,
  mobileHideGoToDashboardButton = false,
  mobileHideDocumentsList = false,
  mobileHideResources = false,
  logoClick,
  showContactBarMobile = false,
  isFooterGivenClass = true,
  isDisclaimerHided = false
}) => {
  const [isScrolling, setIsScrolling] = React.useState()
  const [isTabletOrDesktop, setIsTabletOrDesktop] = React.useState(false)
  const [includesFormUrl, setIncludesFormUrl] = React.useState(false)

  const handleScroll = (): void => {
    setIsScrolling(window.scrollY > 35)
  }

  React.useEffect(() => {
    showWarningViaConsole()
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  React.useEffect(() => {
    if (!!(window.screen.width > 960) !== isTabletOrDesktop) {
      setIsTabletOrDesktop(!isTabletOrDesktop)
    }
    setIncludesFormUrl(window.location.pathname.includes('form'))
  }, [isTabletOrDesktop])

  React.useEffect(() => {
    if (isScrolling) {
      document.body.classList.add('scrollDown')
    } else {
      document.body.classList.remove('scrollDown')
    }
  }, [isScrolling])

  return (
    <div className={'layout-section'}>
      {!hideHeader && (
        <Header
          showContactBarMobile={showContactBarMobile}
          hideMobileHeader={hideMobileHeader}
          isScrolling={isScrolling}
          headerBackground={headerBackground}
          headerHasNoProducts={headerHasNoProducts}
          headerFullWidth={headerFullWidth}
          hideNavbar={hideNavbar}
          hideDesktopNavbar={hideDesktopNavbar}
          hideDashboardButton={hideDashboardButton}
          registerModalOpen={registerModalOpen}
          toggleRegister={toggleRegister}
          loginModalOpen={loginModalOpen}
          toggleLogin={toggleLogin}
          showContactBar={showContactBar}
          changeUrlRedirect={changeUrlRedirect}
          isTestAB8814={isTestAB8814}
          currentStepName={currentStepName}
          logoShowModal={logoShowModal}
          showModalOnLinks={showModalOnLinks}
          mobileHideGoToDashboardButton={mobileHideGoToDashboardButton}
          mobileHideDocumentsList={mobileHideDocumentsList}
          mobileHideResources={mobileHideResources}
          logoClick={logoClick}
          // fin testab 8814
        />
      )}
      <main style={{ flex: isTabletOrDesktop && includesFormUrl ? '1 1 700px' : '1 1 auto' }}>{children}</main>
      {
        // TEST AB 6875
        showMobileMicroFooter && (
          <MicroFooter
            loginModalOpen={loginModalOpen}
            toggleLogin={toggleLogin}
            registerModalOpen={registerModalOpen}
            toggleRegister={toggleRegister}
            // testAB8814
            changeUrlRedirect={changeUrlRedirect}
          />
        )
      }
      <Footer
        // istest8814
        isTestAB8814={isTestAB8814}
        footerContactBar={footerContactBar}
        isAnonymousPaymentPage={isAnonymousPaymentPage}
        // fin testab 8814
        disclaimerLinks={disclaimerLinks}
        environment={environment}
        ctaFooter={ctaFooter}
        noFooter={noFooter}
        bottomOverlayContent={bottomOverlayContent}
        isFooterGivenClass={isFooterGivenClass}
        isDisclaimerHided={isDisclaimerHided}
      />
    </div>
  )
}
