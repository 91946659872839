import React from 'react'

import { useConfigs } from '@legal/core/configs'

export const LinkStrapi = ({
  text,
  href,
  givenClass
}: {
  text: string
  href: string
  givenClass: string
}): React.JSX.Element => {
  const { TARGET_ADDRESS } = useConfigs()
  return (
    <a
      className={`${givenClass} bottom-text-link`}
      href={href.replace('$www$', TARGET_ADDRESS)}
      rel='noopener noreferrer'
      target='_blank'
    >
      {text}
    </a>
  )
}
