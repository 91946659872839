import { logError, logInfo } from '@legal/shared/logger'
import { type CreditCard } from '../domain'
import { CreditCardRepositoryApi } from '../infra'

export async function findCreditCardsActiveByCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (creditCards: CreditCard[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): Promise<void> {
  await CreditCardRepositoryApi.findActivesByCustomer()
    .then((creditCards) => {
      logInfo('FindCreditCardsActiveByCustomerUseCase')
      successCallback(creditCards)
    })
    .catch((error) => {
      logError('FindCreditCardsActiveByCustomerUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
