/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { MULTIVALUES_SEPARATOR } from '../constants/formConstants'
import { checkReferenceValueAgainstFormValue } from './newStepStructure'
import { groupFromField } from './GetGroup'

export const referenceHandler = (group, form, structure): unknown => {
  if (hasNoRef(group)) return true
  if (hasSingleRef(group)) {
    if (singleRefHandlerCheck(group.refField[0], form))
      return referenceHandler(groupFromField(group.refField[0].name, structure), form, structure)
    return false
  }
  if (multiRefHandlerCheck(group, form))
    return referenceHandler(groupFromField(group.refField.name, structure), form, structure)
  return false
}

const hasNoRef = (group): boolean => {
  return !group.refField
}
const hasSingleRef = (group): boolean => {
  return group.refField.length === 1 && !group.referenceGroupLogic
}
const hasMultiValues = (refFieldValue): boolean => {
  return typeof refFieldValue === 'string' ? refFieldValue.includes(MULTIVALUES_SEPARATOR) : false
}

const multiValuesHandler = (refField, form): boolean => {
  let formValue
  if (form[refField.name]?.value) {
    formValue = form[refField.name].value.value ?? form[refField.name].value
  }
  return checkReferenceValueAgainstFormValue(refField.value, formValue)
}
const singleRefHandlerCheck = (refField, form): boolean | string => {
  if (hasMultiValues(refField.value)) return multiValuesHandler(refField, form)
  if (form[refField.name] && form[refField.name].component === 'Check') return form[refField.name].value === '1'
  if (form[refField.name]?.value && form[refField.name].component === 'SearchSelect')
    return refField.value === form[refField.name].value.value
  return form[refField.name] ?
      form[refField.name].component !== 'Check' && refField.value === form[refField.name].value
    : ''
}
const multiRefHandlerCheck = (group, form): boolean => {
  const isReferencesLogicOrActive = group.referenceGroupLogic === null ? false : group.referenceGroupLogic
  const resolvedRefsArray = []

  group.refField.forEach((singleRef) => {
    let formValue

    if (form[singleRef.name]?.value) {
      formValue = form[singleRef.name].value.value ?? form[singleRef.name].value
    }
    const isGroupNameEqual = group.name === singleRef.name
    const hasGroupRefValue = singleRef.value !== ''

    resolvedRefsArray.push(
      checkReferenceValueAgainstFormValue(singleRef.value, formValue) ||
        (isGroupNameEqual && !hasGroupRefValue && formValue !== '')
    )
  })
  return isGroupVisible(resolvedRefsArray, isReferencesLogicOrActive)
}

const isGroupVisible = (resolvedRefsArray, isReferencesLogicOrActive): boolean => {
  if (resolvedRefsArray.length > 0) {
    const isAtLeastOneReferenceFalse = resolvedRefsArray.some((ref) => ref === false)
    const areAllReferencesFalse = resolvedRefsArray.every((ref) => ref === false)

    if (!isLogicallyGroupVisible(isReferencesLogicOrActive, isAtLeastOneReferenceFalse, areAllReferencesFalse)) {
      return false
    }
  }
  return true
}

const isLogicallyGroupVisible = (
  isReferencesLogicOrActive,
  isAtLeastOneReferenceFalse,
  areAllReferencesFalse
): boolean => {
  return !(
    (isReferencesLogicOrActive && areAllReferencesFalse) ||
    (!isReferencesLogicOrActive && isAtLeastOneReferenceFalse)
  )
}
