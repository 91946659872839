import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import { type DeleteDocumentModalProps } from './DeleteDocumentModalProps'
import { Modal } from '../../layout'
import './DeleteDocumentModal.scss'

export const DeleteDocumentModal: React.FC<DeleteDocumentModalProps> = ({
  closeFunction,
  deleteFunction,
  documentName,
  data
}) => {
  return (
    <Modal
      title={`${data.strapiMyDocumentsPage?.documentsSection?.deleteDocumentModal?.title} ${documentName}`}
      size='S'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
    >
      <p className='modal--delete-document__body__text'>
        This file will be permanently removed from your profile. Are you sure you want to do this?
      </p>
      <div className='modal--delete-document__actions'>
        <Button
          givenClass='modal--delete-document__button'
          color='secondary2'
          label={data.strapiMyDocumentsPage?.documentsSection?.deleteDocumentModal?.primaryActionText ?? ''}
          onClick={closeFunction}
          noLink
          dataQa='modal--delete-document__button'
        />
        <Button
          givenClass='modal--delete-document__button-primary'
          color='primary3'
          label={data.strapiMyDocumentsPage?.documentsSection?.deleteDocumentModal?.secondaryActionText ?? ''}
          onClick={deleteFunction}
          noLink
          dataQa='modal--delete-document__button-secondary'
        />
      </div>
    </Modal>
  )
}
