import React from 'react'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type ProductFaqs8814Props } from './ProductFaqs8814Props'
import { ProductFaqsItem8814 } from '../ProductFaqsItem8814'
import { cloneObjectOrArray } from '../../../services/utils/cloneObjectOrArray/cloneObjectOrArray'
import { useQueryFormPage } from '@legal/shared/data/graphql/queries/useQueryFormPage'
import './ProductFaqs8814.scss'

export const ProductFaqs8814: React.FC<ProductFaqs8814Props> = ({
  faqs,
  formContext,
  stepActive,
  formType,
  formSubType
}) => {
  const [faqsAll, setFaqsAll] = React.useState(null)
  const { productsFaqs } = useQueryFormPage()
  React.useEffect(() => {
    let faqsNew = faqs[0] || null
    if (faqsNew) {
      faqsNew = cloneObjectOrArray([faqsNew])
      faqs.forEach((elementFaq, index) => {
        if (index !== 0) {
          elementFaq.faq.forEach((faq) => {
            faqsNew[0].faq.push(faq)
          })
        }
      })
    }

    setFaqsAll(faqsNew)
  }, [faqs])

  const handleChangeActiveItem = (): void => {
    const eventProps = {
      step_name: stepActive?.name,
      document_type: formType,
      document_subtype: formSubType || formContext.formStateName || '',
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }

    sendAmplitudeData('view_faq', eventProps)
  }

  const contentAllFaqs = faqsAll?.map((group) => (
    <ProductFaqsItem8814 key={group.name} faqs={group.faq} changeActiveItem={handleChangeActiveItem} />
  ))

  return (
    <div className='productFaqs8814' id='product-faqs'>
      <div className='productFaqs8814__content'>
        <h3 className='productFaqs8814__title sans-serif'>{productsFaqs?.item}</h3>
        {contentAllFaqs}
      </div>
    </div>
  )
}
