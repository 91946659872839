import React from 'react'

export const useHashFirsTime = (): {
  isHashInUrlFirstTime: () => boolean
} => {
  const [hashInUrl, setHashInUrl] = React.useState<boolean | null>(null)

  const isHashInUrlFirstTime = (): boolean => {
    if (hashInUrl === null && window.location.hash) {
      setHashInUrl(true)
      return true
    } else {
      setHashInUrl(false)
      return false
    }
  }

  return {
    isHashInUrlFirstTime
  }
}
