import { Link } from 'gatsby'
import React from 'react'

import { useConfigs } from '@legal/core/configs'

import { type DocumentSubMenuProps } from './DocumentSubMenuProps'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { stringSlugify } from '../../../services/utils/stringSlugify'

export const DocumentSubMenu: React.FC<DocumentSubMenuProps> = ({ product }) => {
  const { TARGET_ADDRESS } = useConfigs()

  const productLink = product.categoryUrl ? `${product.categoryUrl}/${product.slug}` : product.slug
  const dataQaPrefix = stringSlugify(product.linkText)

  return (
    <li className='menu-items-li'>
      <Link
        title={product.linkText}
        data-qa={`${dataQaPrefix}_document-list`}
        onClick={() => setCookiePolicyFunc}
        to={`${TARGET_ADDRESS}/${productLink}`}
      >
        {product.linkText}
      </Link>
    </li>
  )
}
