/* eslint-disable @typescript-eslint/no-explicit-any */

export const stepFromField = (field, formStructure): any => {
  let stepObject = {}
  formStructure.steps.forEach((step) => {
    step.groups.forEach((group) => {
      group.fields.forEach((currentField) => {
        if (currentField === field) stepObject = step
      })
    })
  })
  return stepObject
}
