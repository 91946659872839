/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames'

import { type DialogMenuIconProps } from './DialogMenuIconProps'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import shape from '../../../assets/images/svg/shape.svg'
import './DialogMenuIcon.scss'

export const DialogMenuIcon: React.FC<DialogMenuIconProps> = ({
  dialogMenuTip,
  onCloseFunc,
  onOpenFunc,
  formType,
  formSubtype,
  formContext,
  currentStep,
  isTestAB8814 = false
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const iconContainer = React.useRef<HTMLDivElement | null>(null)
  const messageContainer = React.useRef<HTMLDivElement | null>(null)

  const onClose = (): void => {
    setIsVisible(false)
    if (onCloseFunc) onCloseFunc()
  }

  const onOpen = (): void => {
    setIsVisible(true)
    if (onOpenFunc) onOpenFunc()

    const eventProps = {
      document_type: formType,
      document_subtype: formSubtype ?? formContext.formStateName ?? '',
      step_name: currentStep
    }
    sendAmplitudeData('view_tooltip', eventProps)
  }

  const handleClickOutside = (event): void => {
    const clickOutOfDialogMenu = iconContainer.current && !iconContainer.current.contains(event.target)

    if (clickOutOfDialogMenu) {
      onClose()
    }
  }

  React.useEffect(() => {
    if (isVisible && iconContainer.current) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isVisible])

  const DialogMenuIconClass = classNames({
    'dialog-menu-icon-container': true,
    visible: isVisible,
    'enable-over': true,
    // istest8814
    '--istestAB8814': isTestAB8814
    // fin testab 8814
  })

  return (
    <div className={DialogMenuIconClass} ref={iconContainer}>
      <img className='dialog-menu-icon-image' onClick={onOpen} src={shape} alt='' />
      <div className={'dialog-menu-icon-message'} ref={messageContainer}>
        <p>{dialogMenuTip}</p>
        <div
          className={`dialog-menu-icon-close-button ${isTestAB8814 && 'dark'}`}
          onClick={onClose}
          role='button'
          tabIndex={0}
        ></div>
      </div>
    </div>
  )
}
