import { ENDPOINTS } from './Endpoints'
import { PricingTest } from '../../../../services/constants/pricingTest'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { SubscriptionDataToSubscription } from '../adapters'
import { TestABCookie } from '../../../../services/storage/cookies/TestABCookie'
import { existsParamInUrl } from '../../../../services/utils/paramsUrl'
import { get } from '@legal/shared/HttpClient'
import { pricingId } from '@legal/shared/data/constants/pricingId'

export async function findSubscriptionTypeByCode(tokenRequired: boolean): Promise<Subscription[]> {
  const testABCookie = new TestABCookie(PricingTest.TEST_CODE)
  const isPricingTest19858 = testABCookie.value === 'true' || existsParamInUrl(PricingTest.TEST_CODE)
  const SUBSCRIPTIONS_CODE = isPricingTest19858 ? PricingTest.SUBSCRIPTION_CODE : pricingId.default
  const subscriptionsData = await get<SubscriptionData[]>(
    ENDPOINTS.FIND_SUBSCRIPTTION_TYPE_BY_CODE(SUBSCRIPTIONS_CODE),
    tokenRequired
  )
  return subscriptionsData.map(SubscriptionDataToSubscription)
}
