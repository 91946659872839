/* eslint-disable no-console */
const STYLES_TITLE = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;'
const STYLES_MESSAGES = 'font-size: 13px;'

const TITLE = 'WARNING!'

const warningMessages = [
  'Elements of this website (such as its source code, software, trademarks, information texts, content, structure, design, and other assets) are copyrighted and no licenses are granted - whether express or implied - to users, software developers, and/or other third parties.',
  'The use of this website’s browser console to access and insert and/or execute expressions, commands, or codes that directly or indirectly interfere, manipulate, or damage any element of this website (especially its source code) is strictly forbidden. These actions could involve:(i) your private data or personal information being sent to an unauthorized third party and/or owner/operator of a false website and consequently stolen and processed for fraudulent purposes, or (ii) that malware can be installed on your device to access your files (photos, videos, documents), camera, microphone, printer, or devices and in turn making your privacy more vulnerable. The owner and operator of this website (the copyright holder) will not be liable if any direct or indirect, incidental, special, exemplary, or consequential damage occurs to an individual due to the infringements noted above in this warning notice.',
  'Additionally, all users are prohibited from totally or partially copying, reproducing, publishing, transforming, providing, distributing, transmitting, publicly communicating, displaying, or making use of any elements of this website.',
  'The owner and operator of this website has the right to take any and all necessary legal action in the event of an infringement of its intellectual property rights - such as compensation for direct and indirect damages - if it is considered appropriate and the best defense of its legitimate rights and interests.'
]

const consoleLogWithHideFileLineCode = (message: string, styles: string): void => {
  setTimeout(console.log.bind(console, `%c${message}`, styles), 0)
}

export const showWarningViaConsole = (): void => {
  consoleLogWithHideFileLineCode(TITLE, STYLES_TITLE)
  warningMessages.forEach((message) => {
    consoleLogWithHideFileLineCode(message, STYLES_MESSAGES)
  })
}
