import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class SubscriptionCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('subscription') })
  }

  get subscriptionId(): string | undefined {
    return this.read('subscriptionId')
  }

  set subscriptionId(name) {
    this.write({ key: 'subscriptionId', data: name })
  }

  get subscriptionTypeId(): string | undefined {
    return this.read('subscriptionTypeId')
  }

  set subscriptionTypeId(name) {
    this.write({ key: 'subscriptionTypeId', data: name })
  }

  get subscriptionTypeName(): string | undefined {
    return this.read('subscriptionTypeName')
  }

  set subscriptionTypeName(name) {
    this.write({ key: 'subscriptionTypeName', data: name })
  }
}
