import { logError, logInfo } from '@legal/shared/logger'
import { ContactFormRepositoryApi } from '../infra'
import { type SendContactFormRequest } from './interfaces'

export async function sendContactFormUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: SendContactFormRequest
}): Promise<void> {
  await ContactFormRepositoryApi.send(request)
    .then(() => {
      logInfo('SendContactFormUseCase', request)
      successCallback()
    })
    .catch((error: Error | undefined) => {
      logError('SendContactFormUseCase', request, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
