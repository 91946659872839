import { UtmCookie } from '../storage/cookies/UtmCookie'

const UTMS = [
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_campaigntype',
  'utm_content',
  'utm_term',
  'utm_adgroup',
  'utm_network',
  'utm_device',
  'utm_devicemodel',
  'utm_matchtype',
  'utm_loc_physical_ms'
]

export const setUtm = ({ itemsParamsUrl }): void => {
  const utmCookie = new UtmCookie()

  for (const utm of UTMS) {
    if (itemsParamsUrl[utm]) utmCookie[utm] = itemsParamsUrl[utm]
  }
}
