/* eslint-disable react-compiler/react-compiler */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'

export function useModal(enableBackgroundScroll = true): {
  modalOpen: boolean
  changeModalState: () => void
  closeModal: () => void
  changeUrlRedirect: (url: any) => void
  urlRedirect: null
} {
  const [modalOpen, setModalOpen] = React.useState(false)

  // testAB8814
  const [urlRedirect, setUrlRedirect] = React.useState(null)

  React.useEffect(() => {
    handleBodyScroll()
  }, [modalOpen])

  const handleBodyScroll = (): void => {
    const disableScroll = modalOpen
    if (disableScroll && enableBackgroundScroll) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'unset'
      document.body.style.position = 'relative'
      document.body.style.width = '100%'
    }
  }

  const changeModalState = (): void => {
    setModalOpen(!modalOpen)
  }

  const closeModal = (): void => {
    if (urlRedirect) {
      setUrlRedirect(null)
    }
    setModalOpen(false)
  }

  const changeUrlRedirect = (url): void => {
    setUrlRedirect(url)
  }

  return {
    modalOpen,
    changeModalState,
    closeModal,
    changeUrlRedirect,
    urlRedirect
  }
}
