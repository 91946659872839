import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQueryAllDocumentsFooter = (): Queries.STRAPI_ALL_DOCUMENTS_PAGE | undefined => {
  const { STRAPI_CONFIG } = useConfigs()

  const { allStrapiAllDocumentsPage } = useStaticQuery(graphql`
    query {
      allStrapiAllDocumentsPage {
        nodes {
          slug
          footerTitle
          domain {
            name
          }
        }
      }
    }
  `)
  return allStrapiAllDocumentsPage.nodes.find(
    (node: Queries.STRAPI_ALL_DOCUMENTS_PAGE) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  )
}
