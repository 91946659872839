/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { type Application } from '../domain'
import { ApplicationRepositoryApi } from '../infra'
import { type CreateApplicationRequest } from './interfaces'
import { CreateApplicationRequestToApplication } from './adapters/CreateApplicationRequestToApplication'

export async function createApplicationUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (application: Partial<Application>) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateApplicationRequest
}): Promise<void> {
  await ApplicationRepositoryApi.create(request)
    .then((applicationId: string) => {
      const data = { ...request }
      if (data.htmlPreview) delete data.htmlPreview
      logInfo('CreateApplicationUseCase', data)
      successCallback(CreateApplicationRequestToApplication(request, applicationId))
    })
    .catch((error: Error | undefined) => {
      if (request.htmlPreview) delete request.htmlPreview
      logError('CreateApplicationUseCase', request, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
