import React from 'react'

import { DocumentListMenu, DocumentMainMenu } from '../../organisms'
import { Modal } from '../../layout'
import { type NewDocumentModalProps } from './NewDocumentModalProps'
import { useAllFeaturedProducts } from '@legal/shared/hooks'
import { useQueryCreateDocument } from '@legal/shared/data/graphql'
import './NewDocumentModal.scss'

export const NewDocumentModal: React.FC<NewDocumentModalProps> = ({ closeFunction }) => {
  const navbarItems = useAllFeaturedProducts()
  const dataCreateDocument = useQueryCreateDocument()

  return (
    <Modal
      customClass='modal--new-document'
      title={dataCreateDocument.title}
      size='XL'
      type='default'
      closeFunction={closeFunction}
    >
      <div className={'modal--new-document__body__content'}>
        <DocumentListMenu navbarItems={navbarItems} data={dataCreateDocument} />
        <DocumentMainMenu products={navbarItems}></DocumentMainMenu>
      </div>
    </Modal>
  )
}
