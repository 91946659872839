import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import { Modal } from '../../layout'
import { type TimeoutModalContentProps } from './TimeoutModalContentProps'
import './TimeoutModalContent.scss'

export const TimeoutModalContent: React.FC<TimeoutModalContentProps> = ({
  closeFunction,
  title,
  subtitle,
  btnLabel
}) => {
  return (
    <Modal title={title} size='S' type='default' closeFunction={closeFunction} customClass='timeout-modal'>
      <div className='modal--timeout__container'>
        <p className='modal--timeout__paragraph'>{subtitle}</p>
      </div>
      <div className='modal--timeout__actions'>
        <Button
          givenClass='message--timeout__button'
          label={btnLabel ?? ''}
          link={window.location.href}
          isExternal
          dataQa='timeout-modal-button'
        />
      </div>
    </Modal>
  )
}
