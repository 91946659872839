import * as REQUEST from './apiRequests'
import { CreditCardRepository } from '../domain'

export const CreditCardRepositoryApi: CreditCardRepository = {
  async change(body) {
    await REQUEST.changeCreditCard(body)
  },
  async delete(cardId) {
    await REQUEST.deleteCreditCard(cardId)
  },
  async findActivesByCustomer() {
    return REQUEST.findCreditCardsActiveByCustomer()
  }
}
