import React from 'react'

import { type IConfig } from '@legal/core/configs/IConfig'
import { useConfigs } from '@legal/core/configs'

export const useRichTextSubstitution = (
  originalText: string | undefined | null
): { replacedText: string | undefined } => {
  const configs = useConfigs()
  const replacedText = React.useMemo(() => replaceText(originalText, configs), [originalText, configs])
  return { replacedText }
}

function replaceText(originalText: string | undefined | null, configs: IConfig): string | undefined {
  if (!originalText) return undefined
  let replacedText = originalText

  replacedText = replacedText.replaceAll('$www$', configs.TARGET_ADDRESS)
  replacedText = replacedText.replaceAll('$app$', configs.APP_SUBDOMAIN)

  return replacedText
}
