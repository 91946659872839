import React from 'react'

import { clearCookie, getCookie, writeCookie } from './CookieManager'
import { COOKIE_NAME } from './CookieNames'
import { cloneObjectOrArray } from '../../../services/utils/cloneObjectOrArray/cloneObjectOrArray'

export const CookieContext = React.createContext<{
  application: object | undefined
  setApplication: React.Dispatch<React.SetStateAction<object | undefined>>
}>({
  application: undefined,
  setApplication: () => {}
})

export const CookieContextProvider = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const [applicationData, setApplicationData] = React.useState<object | undefined>(getCookie(COOKIE_NAME.APPLICATION))

  const setApplication = (application: React.SetStateAction<object | undefined>): void => {
    if (application === undefined) {
      clearCookie(COOKIE_NAME.APPLICATION)
      setApplicationData(undefined)
      return
    }

    const cloneApplication = cloneObjectOrArray(application)
    writeCookie({ cookieName: COOKIE_NAME.APPLICATION, data: cloneApplication })

    setApplicationData(cloneApplication)
  }

  return (
    <CookieContext.Provider value={{ application: applicationData, setApplication }}>{children}</CookieContext.Provider>
  )
}
