/* eslint-disable @typescript-eslint/no-explicit-any */

import { logError, logInfo } from '../logger'
import { type ApiError } from './ApiError'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getConfigs } from '@legal/core/configs'

async function ApiRequest(method: string, endpoint: string, tokenRequired = false, body?: any): Promise<Response> {
  const { token } = new UserCookie()
  if (tokenRequired && !token) throw new Error('Token required')

  const responseData = new Promise<Response>((resolve, reject) => {
    const bodyData = body instanceof FormData ? body : JSON.stringify(body)
    const { API_CONFIG } = getConfigs()
    fetch(`${API_CONFIG.API_PREFIX}/${endpoint}`, {
      method,
      headers: {
        ...(!(body instanceof FormData) && { Accept: 'application/json' }),
        ...(!(body instanceof FormData) && { 'Content-Type': 'application/json' }),
        Authorization: token ? `Bearer ${token}` : API_CONFIG.HTTP_SECRET_KEY
      },
      ...(body !== undefined && { body: bodyData })
    })
      .then(async (response) => {
        if (response.status >= 400) {
          const json: ApiError | undefined = await response.json()
          logError(`Endpoint: ${endpoint}`, { _status: response.status, _result: { data: json } })
          reject(json)
          return json
        }

        logInfo(`Endpoint: ${endpoint}`, response)
        resolve(response)
      })
      .catch((error: object | undefined) => {
        logError(`Endpoint: ${endpoint}`)
        reject(error)
      })
  })

  return await responseData
}

export async function get<T>(endpoint: string, tokenRequired = false): Promise<T> {
  const response = await ApiRequest('GET', endpoint, tokenRequired)
  return await response.json()
}

export async function post<T>(endpoint: string, body: any, tokenRequired = false): Promise<T> {
  const response = await ApiRequest('POST', endpoint, tokenRequired, body)
  return await response.json()
}

export async function put<T>(endpoint: string, body?: any, tokenRequired = false): Promise<T> {
  const response = await ApiRequest('PUT', endpoint, tokenRequired, body)
  return await response.json()
}

export async function del<T>(endpoint: string, tokenRequired = false): Promise<T> {
  const response = await ApiRequest('DELETE', endpoint, tokenRequired)
  return await response.json()
}
