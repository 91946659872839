import React from 'react'

export function highlightSubstrings(headerTitle: string, substrings: string[]): React.ReactNode {
  const regexPattern = substrings.map((substring) => `(${substring})`).join('|')
  const regex = new RegExp(regexPattern, 'gi')

  const parts = headerTitle.split(regex)

  return (
    <>
      {parts.map((part, index) => {
        if (part && substrings.some((substring) => part.toLowerCase() === substring.toLowerCase())) {
          return <strong key={index}>{part}</strong>
        }
        return part
      })}
    </>
  )
}
