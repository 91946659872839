/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'

import { type TemplateHeaderProps } from './TemplateHeaderProps'
import './TemplateHeader.scss'

export const TemplateHeader: React.FC<TemplateHeaderProps> = ({ givenClass, title, subtitle, buttons }) => {
  return (
    <div className={`template-header ${givenClass ?? ''}`}>
      {title && subtitle && (
        <div className={'left-side'}>
          {title && <div className={'main-title serif --large'}>{title}</div>}
          {subtitle && <div className={'sub-title sans-serif --medium'}>{subtitle}</div>}
        </div>
      )}
      {buttons && <div className={'right-side'}>{buttons}</div>}
    </div>
  )
}
