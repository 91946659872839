import React from 'react'

import { MenuSectionTitle } from '../MenuSectionTitle'

import { AllTypeDocumentsItemMenu } from '../AllTypeDocumentsItemMenu'
import { type DocumentListMenuSectionProps } from './DocumentListMenuSectionProps'
import { ProductListMenu } from '../../../components/molecules/ProductListMenu'
import './DocumentListMenuSection.scss'

export const DocumentListMenuSection: React.FC<DocumentListMenuSectionProps> = ({ products, type, icon }) => {
  return (
    <div className='products__list__section'>
      <MenuSectionTitle icon={icon} title={type} />
      <ul>
        {products.slice(0, 6).map((product) => (
          <ProductListMenu key={product.slug} {...product} />
        ))}
        <AllTypeDocumentsItemMenu type={type}></AllTypeDocumentsItemMenu>
      </ul>
    </div>
  )
}
