/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'
import { ButtonIcon } from '@npm_leadtech/legal-lib-components/ButtonIcon'
import { MenuItems } from '@npm_leadtech/legal-lib-components/MenuItems'
import React from 'react'
import { TooltipMenu } from '@npm_leadtech/legal-lib-components/TooltipMenu'

import Delete from '../../../assets/images/componentsSvg/delete-24-p.svg'
import { type DocumentActionsMenuProps } from './DocumentActionsMenuProps'
import Duplicate from '../../../assets/images/componentsSvg/icon-duplicate-24-px.svg'
import EditIcon from '../../../assets/images/componentsSvg/edit-24-px.svg'
import EditTextIcon from '../../../assets/images/componentsSvg/edit-text-24-px.svg'
import IAIcon from '../../../assets/images/componentsSvg/ai-24px.svg'
import MenuCollapse from '../../../assets/images/componentsSvg/menu-colapse-24-p.svg'
import PreviewIcon from '../../../assets/images/componentsSvg/eye-24-px-optimized.svg'
import { useIsSmallDevice } from '@legal/shared/hooks'
import './DocumentActionsMenu.scss'

export const DocumentActionsMenu: React.FC<DocumentActionsMenuProps> = ({
  editActive = false,
  editClicked = () => {},
  editNameClicked,
  previewAction,
  previewClicked,
  deleteAction,
  deleteClicked,
  duplicateAction = false,
  duplicateClicked = () => {},
  ratafiaAction,
  ratafiaClicked,
  data
}) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const isSmallDevice = useIsSmallDevice()

  const options = []

  if (editActive) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.editActionText,
      image: <EditIcon />,
      onClick: editClicked,
      dataQa: 'edit_document_button'
    })
  }

  if (previewAction) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.previewActionText,
      image: <PreviewIcon />,
      onClick: previewClicked,
      dataQa: 'preview_document_button'
    })
  }

  options.push({
    content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.editNameText,
    image: <EditTextIcon />,
    onClick: editNameClicked,
    dataQa: 'edit_document_name_action'
  })

  if (deleteAction) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.deleteText,
      image: <Delete />,
      onClick: deleteClicked,
      dataQa: 'delete_document_action'
    })
  }

  if (duplicateAction) {
    options.push({
      content: data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.duplicateText,
      image: <Duplicate />,
      onClick: duplicateClicked,
      dataQa: 'duplicate_document_action'
    })
  }

  if (typeof window === 'undefined') return null

  if (!isSmallDevice)
    return (
      <div className='document-actions__list'>
        {ratafiaAction && (
          <ButtonIcon
            givenClass='ai_dashboard_icon'
            onClick={ratafiaClicked}
            textTooltip={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.iaText}
            dataQa='ai_dashboard_doc'
          >
            <IAIcon />
          </ButtonIcon>
        )}
        <TooltipMenu
          options={options}
          label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.actionsText}
        />
      </div>
    )

  const bottomOverlayBody = <MenuItems className={'overlay-list-my-docs'} list={options} />

  return (
    <ul className={'document-actions__list'}>
      <li className='document-actions__item'>
        {ratafiaAction && (
          <ButtonIcon
            givenClass='ai_dashboard_icon'
            onClick={ratafiaClicked}
            textTooltip={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.iaText}
            dataQa='ai_dashboard_doc'
          >
            <IAIcon />
          </ButtonIcon>
        )}
      </li>
      <li
        className={`document-actions__item menu ${openMenu ? '--is-open' : ''}`}
        onClick={() => {
          setOpenMenu(!openMenu)
        }}
      >
        <MenuCollapse />
        <span className={'sans-serif --extra-small'}>
          {data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.actionsText}
        </span>
        {openMenu && (
          <BottomOverlay
            open={openMenu}
            body={bottomOverlayBody}
            onClose={() => {
              setOpenMenu(false)
            }}
          ></BottomOverlay>
        )}
      </li>
    </ul>
  )
}
