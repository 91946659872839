import { Divider } from '@npm_leadtech/legal-lib-components/Divider'
import React from 'react'

import { type HeaderWithIconProps } from './HeaderWithIconProps'
import security from '../../../assets/images/svg/icon-24-px-security.svg'
import './HeaderWithIcon.scss'

export const HeaderWithIcon: React.FC<HeaderWithIconProps> = ({ givenClass, icon, title, subtitle }) => {
  return (
    <div className={`header-with-icon ${givenClass ?? ''}`}>
      <Divider image={icon ?? security} containerClass='center' lineClass='shorter-line' />
      {title && <h3 className='header-with-icon__title'>{title}</h3>}
      {subtitle && <h5 className='header-with-icon__subtitle'>{subtitle}</h5>}
    </div>
  )
}
