import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'

import {
  type SendContactFormRequest,
  getContactCustomerDetailsUseCase,
  sendContactFormUseCase
} from '@legal/contactForm'
import { type ContactFormProps } from './ContactFormProps'
import FormComponents from '../FormComponents'
import { TypeSelector } from '../../../services/form/TypeSelector'
import { TypeSelectorValidate } from '../../../services/form/TypeSelectorValidate'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { groupFromField } from '../../../services/form/GetGroup'
import iconInfo from '../../../assets/images/svg/icon-info-24-px.svg'
import { refHandler } from '../../../services/form/contactRefHandler'
import { referenceHandler } from '../../../services/form/referenceHandler'
import './ContactForm.scss'

export const ContactForm: React.FC<ContactFormProps> = ({ json, data }) => {
  const [form, setForm] = React.useState(json.fields)
  const [structure] = React.useState(json.structure)
  const [formState, setFormState] = React.useState<'start' | 'success' | 'error'>('start')

  React.useEffect(() => {
    const getContactCustomerDetails = async (): Promise<void> => {
      const userCookie = new UserCookie()
      if (!userCookie.token) return
      await getContactCustomerDetailsUseCase({
        successCallback: (reference) => {
          const changedForm = { ...form }
          if (changedForm.reference) {
            changedForm.reference.value = reference
            setForm(changedForm)
          }
        }
      })
    }
    getContactCustomerDetails()
  }, [])

  const handleChange = (e): void => {
    const target = e.target
    const value = target.value
    const name = target.name
    const tempForm = { ...form }
    const field = tempForm[name]
    field.value = TypeSelector(field.component, value, target.checked)
    field.validate = true
    setForm(tempForm)
  }

  const scrollToRef = (ref): void => {
    window.scrollTo({
      top: document.getElementById(ref).getBoundingClientRect().top + window.scrollY - 300,
      behavior: 'smooth'
    })
  }

  const onSubmit = async (e): Promise<boolean | undefined> => {
    e.preventDefault()
    const validateForm = (): boolean => {
      const tempForm = { ...form }
      const structureForm = { ...structure }
      let validated = true
      let refErrorElement = ''
      Object.keys(tempForm).forEach(function (field) {
        if (refHandler(groupFromField(field, structureForm), tempForm) && refHandler(tempForm[field], tempForm)) {
          if (tempForm[field].required) {
            if (!TypeSelectorValidate(tempForm[field], tempForm[field].value, tempForm[field].checked)) {
              tempForm[field].validate = false
              validated = false
              if (refErrorElement === '') refErrorElement = field
            }
          }
        }
      })
      setForm(tempForm)
      if (refErrorElement !== '') scrollToRef(refErrorElement)
      return validated
    }

    if (!validateForm()) {
      return false
    }
    const data: SendContactFormRequest = Object.values(form).reduce((obj, field) => {
      if (field.value !== '' && refHandler(field, form) && refHandler(groupFromField(field.name, structure), form)) {
        obj[field.name] = field.value
      }
      return obj
    }, {})

    data.requestType = data.subject.label

    await sendContactFormUseCase({
      successCallback: () => {
        setFormState('success')
      },
      errorCallback: () => {
        setFormState('error')
      },
      request: data
    })
  }

  const showForm = React.useCallback(() => {
    setFormState('start')
  }, [])

  const restartForm = React.useCallback(() => {
    setFormState('start')
    const tempForm = { ...form }

    Object.keys(tempForm).forEach(function (field) {
      tempForm[field].value = ''
    })

    setForm(tempForm)
  }, [form])

  const feedback = {
    error: {
      theme: 'error',
      title: 'Something went wrong',
      text: 'Please try it again',
      dataQa: 'error-message',
      button: {
        label: 'Try again',
        onClick: showForm,
        noLink: true,
        dataQa: 'error-message-button'
      }
    },
    success: {
      theme: 'success',
      title: 'Thank You!',
      text: 'Your message has been sent!',
      dataQa: 'success-message',
      button: {
        label: 'Send New Message',
        onClick: restartForm,
        noLink: true,
        dataQa: 'success-message-button'
      }
    }
  }

  const start = (
    <form onSubmit={onSubmit} className='m-form'>
      <div className='m-form-container'>
        {structure.steps.map((step) => (
          <div className='m-form-step' key={step.name}>
            {step.groups.map((group) => (
              <div key={group.name}>
                {referenceHandler(group, form) && (
                  <div className='m-form-group m-contact-form'>
                    {group.fields.map((item) => {
                      const field = form[item]
                      const FieldComponent = FormComponents[field.component]
                      return (
                        <div key={`${field.name}_contact-field`}>
                          {referenceHandler(field, form) && <FieldComponent onChange={handleChange} {...field} />}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <p className='required-fields'>{data.requiredFields}</p>
        <br />
        <div className='information-required'>
          <img src={iconInfo} alt=''></img>
          <p>{data.informationDescription}</p>
        </div>
        <Button givenClass='send-message-button' label={data.buttonCta} noLinkNoFunc dataQa='send-message-button' />
      </div>
    </form>
  )

  const FeedbackComponent = <Feedback {...feedback[formState]} large />

  const FormPhase = {
    start,
    success: FeedbackComponent,
    error: FeedbackComponent
  }

  return <>{FormPhase[formState]}</>
}
