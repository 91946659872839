/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { SubscriptionRepositoryApi } from '../infra'

export async function subscriptionUpdatePayUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: { subscriptionPriceId: string }
}): Promise<void> {
  await SubscriptionRepositoryApi.updatePay(request.subscriptionPriceId)
    .then(() => {
      logInfo('SubscriptionUpdatePayUseCase', request)
      successCallback()
    })
    .catch((error: unknown) => {
      logError('SubscriptionUpdatePayUseCase', request, error as Error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
