export class TimerLocalStorage {
  get timer(): number | null {
    const timer = localStorage.getItem('timer')
    if (timer && parseInt(timer)) {
      return parseInt(timer)
    }
    return null
  }

  set timer(seconts) {
    localStorage.setItem('timer', `${seconts}`)
  }
}
