import React from 'react'

import { ProductCookie } from '../../storage/cookies/ProductCookie'
import { getParamUrlValue } from '../../utils/paramsUrl'
import { versionSupertest } from '../versionSupertest/versionSupertest'

export const useVersion = (versionName?: string): { version: string | undefined } => {
  const [version, setVersion] = React.useState<string | undefined>()

  React.useEffect(() => {
    const product = getParamUrlValue('product')
    const version = versionSupertest({
      dataVersion: versionName ?? undefined,
      product
    })
    setVersion(version)

    const productCookie = new ProductCookie()
    productCookie.product = product
  }, [])

  return { version }
}
