import React, { useEffect, useState } from 'react'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { TextInput } from '@npm_leadtech/legal-lib-components/TextInput'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useDispatchDataAccordionItem } from '@npm_leadtech/legal-lib-components/Accordion'
import { validatorEmail } from '@legal/shared/utils/validatorEmail'

import { CreateCustomerWithSubscriptionUseCase, UpdateEmailCustomer } from '@legal/customer'
import { type EmailInformationProps } from './EmailInformationProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { replaceValues } from '../../../services/utils/replaceAll'

import iconInfo from '../../../assets/images/svg/icon-info-24-px.svg'

import './EmailInformation.scss'

export const EmailIformation: React.FC<EmailInformationProps> = ({
  paymentGatewayData,
  validatorsI18n,
  callbackUserCreate
}): React.JSX.Element => {
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isLoading, setIsloading] = useState(false)
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const { dispatch } = useDispatchDataAccordionItem()
  const { applicationCookie } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const userCookie = new UserCookie()

  useEffect(() => {
    if (!userCookie.token) {
      dispatch({ type: 'locked', accordionItem: 1 })
    } else {
      dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'success' })
      dispatch({ type: 'locked', accordionItem: 0 })
      dispatch({ type: 'locked', accordionItem: 1 })

      if (userCookie.email) {
        setEmail(userCookie.email)
        dispatch({ type: 'message', accordionItem: 0, message: userCookie.email })
      }
    }
  }, [])

  const setError = (error): string => {
    let errorMessage = validatorsI18n.checkCorrectEmail
    if (error?.status === 412) {
      errorMessage = replaceValues(validatorsI18n.canotBeUsedOnSiteName, { SITE_NAME }) ?? ''
    }
    return errorMessage
  }

  const onClick = (): void => {
    const validator = validatorEmail(email, validatorsI18n)
    if (!validator.isValid) {
      setErrorMessage(validator.message)
      setIsValid(false)
      return
    }
    setIsloading(true)
    setErrorMessage('')
    setIsValid(true)
    dispatch({ type: 'loading', accordionItem: 0 })
    dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'default' })
    dispatch({ type: 'message', accordionItem: 0, message: '' })

    if (userCookie.token) {
      updateEmailCustomer(email)
    } else {
      createCustomer(email)
    }
  }

  const createCustomer = (email: string): void => {
    if (!applicationCookie?.id || !subscriptionCookie.subscriptionId) return
    CreateCustomerWithSubscriptionUseCase({
      request: {
        email,
        applicationId: applicationCookie.id,
        subscriptionId: subscriptionCookie.subscriptionId
      },
      successCallback: (token) => {
        userCookie.token = token
        userCookie.email = email
        dispatch({ type: 'locked', accordionItem: 0 })
        dispatch({ type: 'message', accordionItem: 0, message: email })
        callbackUserCreate()
        dispatch({ type: 'unlocked', accordionItem: 1 })
        dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'success' })
        setTimeout(() => {
          dispatch({ type: 'locked', accordionItem: 1 })
        }, 1000)
      },
      errorCallback: (error) => {
        const errorMessage = setError(error)
        setErrorMessage(errorMessage)
        setIsValid(false)
        dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'error' })
      },
      finallyCallback: () => {
        setIsloading(false)
        dispatch({ type: 'loading', accordionItem: 0 })
      }
    })
  }

  const updateEmailCustomer = (email: string): void => {
    UpdateEmailCustomer.Execute({
      email,
      userToken: userCookie.token
    })
      .then((response) => {
        const { token } = response.result
        userCookie.token = token
        userCookie.email = email
        dispatch({ type: 'message', accordionItem: 0, message: email })
        dispatch({ type: 'unlocked', accordionItem: 1 })
        dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'success' })
      })
      .catch((error: unknown) => {
        const errorMessage = setError(error)
        setErrorMessage(errorMessage)
        setIsValid(false)
        dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'error' })
      })
      .finally(() => {
        setIsloading(false)
        dispatch({ type: 'loading', accordionItem: 0 })
      })
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setEmail(value)
  }

  return (
    <div className='email-information'>
      <TextInput
        type={'text'}
        placeholder={paymentGatewayData?.emailInput.placeholder}
        givenClass='email-information__input'
        value={email}
        name={'email'}
        label={paymentGatewayData?.emailInput.label}
        validate={isValid}
        errorMessage={errorMessage}
        isValidGroup={true}
        onChange={onChange}
      />
      <div className='email-information__infomessage'>
        <img src={iconInfo} alt='information message right' />
        <span>{paymentGatewayData?.emailInformationMessage}</span>
      </div>
      {!userCookie.email && !userCookie.token && (
        <Button
          disabled={isLoading}
          label={paymentGatewayData?.btnContinueLabel ?? ''}
          noLink
          onClick={onClick}
          functionParameters={''}
          dataQa={'continueEmailInformation'}
        />
      )}
    </div>
  )
}
