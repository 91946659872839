import { type CustomerAuthRequest, type CustomerAuthResponse } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function CustomerAuth({
  username,
  password,
  applicationId,
  subscriptionId
}: CustomerAuthRequest): Promise<CustomerAuthResponse> {
  const body = { username, password, applicationId, subscriptionId }
  if (!applicationId) delete body.applicationId
  if (!subscriptionId) delete body.subscriptionId
  return await post<CustomerAuthResponse>(ENDPOINTS.CUSTOMER_AUTH, body)
}
