/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, { useEffect, useState } from 'react'
import sanitizeHtml from 'sanitize-html'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type Subscription, getSubscriptionTypeByCodeUseCase, getSubscriptionUseCase } from '@legal/subscription'
import { type PaymentSummaryProps } from './PaymentSummaryProps'
import { PricingTest } from '../../../services/constants/pricingTest'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import bitmap1 from '../../../assets/images/svg/Bitmap-1.svg'
import bitmap2 from '../../../assets/images/svg/Bitmap-2.svg'
import comodosecure from '../../../assets/images/png/comodo-secure.png'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useBenefitsListToShow } from '@legal/shared/hooks/useBenefitsListToshow'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'
import { useSubscriptionDataStrapi } from '@legal/shared/hooks/useSubscriptionDataStrapi'
import { useTestAB } from '../../../services/hooks/useTestAB'

import ssl from '../../../assets/images/svg/SSL_certificate.svg'

const DISPLAY_PRICE = '{{DISPLAY_PRICE}}'

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  currencySymbol,
  subscriptionInformation,
  paymentSummaryData
}) => {
  const [isPricingTest19858] = useTestAB({ id: PricingTest.TEST_CODE, inUrlParam: true, inCookie: true })
  const { currency } = useQuerySharedComponentsFromPaymentPage()

  const { applicationCookie } = useApplicationCookie()
  const [currentSubscription, setCurrentSubscription] = useState<Subscription>()
  const [currentSubscriptionInitialAmount, setCurrentSubscriptionInitialAmount] = useState<string>()
  const [displayPrice, setDisplayPrice] = useState<string>()

  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()

  const userToken = userCookie.token ?? ''

  useEffect(() => {
    if (!subscriptionCookie) {
      window.location = '/pricing/'
    }
  }, [])

  useEffect(() => {
    if (subscriptionInformation) {
      getSubscriptionInformation(userToken)
    }
  }, [subscriptionInformation])

  useEffect(() => {
    if (currentSubscription) {
      const initialDocumentType = applicationCookie?.form?.name
      const eventProps = {
        initial_document_type: stringSlugify(initialDocumentType) ?? null,
        change_price_allowed: currentSubscription.type.name !== 'Best Value',
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      }
      sendAmplitudeData('show_payment_page', eventProps)
    }
  }, [currentSubscription])

  const getSubscriptionInformation = async (userToken?: string): Promise<void> => {
    const subscriptionPriceId = subscriptionCookie.subscriptionId ?? window.history.state?.subscriptionId
    if (!userToken) {
      await getSubscriptionTypeByCodeUseCase({
        successCallback: (subscriptions) => {
          const subscriptionFound = subscriptions.find(({ id }) => id === subscriptionCookie.subscriptionTypeId)
          setCurrentSubscription(subscriptionFound)
          setCurrentSubscriptionInitialAmount(subscriptionFound?.initialAmountFormated)
          setDisplayPrice(subscriptionFound?.displayPrice)
        }
      })
      return
    }
    await getSubscriptionUseCase({
      request: { subscriptionId: subscriptionPriceId },
      successCallback: (response) => {
        if (response.id === subscriptionCookie.subscriptionId) {
          setCurrentSubscription(response)
          setCurrentSubscriptionInitialAmount(response.price?.initialAmount)
          setDisplayPrice(response.displayPrice)
        }
      }
    })
  }

  const { benefitsListToShow } = useBenefitsListToShow({ subscription: currentSubscription })
  const { subscriptionData } = useSubscriptionDataStrapi({
    nameSubscription: currentSubscription?.type?.name ?? ''
  })

  return (
    <div className='right-container'>
      {currentSubscription && (
        <div className='m-paymentForm-container inner-container top'>
          <div>
            <div className='right-container__title'>
              <h3 className='bold sans-serif --big'>{paymentSummaryData?.title}</h3>
            </div>
            <hr />
            <>
              <p>
                {isPricingTest19858 && displayPrice == currentSubscriptionInitialAmount ?
                  applicationCookie?.form?.name
                : subscriptionData?.name}
              </p>
              {currentSubscriptionInitialAmount && (
                <h2 className='price'>
                  {currencySymbol}
                  {isPricingTest19858 ? displayPrice : currentSubscriptionInitialAmount}
                  <span className='currency currency-small'>{`${currency}${isPricingTest19858 && displayPrice != currentSubscriptionInitialAmount ? '/mo' : ''}`}</span>
                </h2>
              )}
              {benefitsListToShow?.map((item, index) => (
                <small
                  key={`subscription-content-${index}`}
                  className='small-text sans-serif --small'
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(item.includes(DISPLAY_PRICE) ? paymentSummaryData?.cancelAnyTime : item)
                  }}
                />
              ))}
              <br />
            </>
          </div>
          <br />
          <h3 className='sans-serif --big'>{paymentSummaryData?.orderSummary}</h3>
          <hr className='bottom-hr' />
          <p>
            {paymentSummaryData?.totalAmount}{' '}
            <span className='bold'>{`${currencySymbol}${currentSubscriptionInitialAmount}`}</span>
            <span className='currency'>
              {currentSubscriptionInitialAmount && `${currency} ${subscriptionData?.timeDuration ?? ''}`}
            </span>
          </p>
        </div>
      )}
      <div className='m-paymentForm-container inner-container bottom'>
        <p>{paymentSummaryData?.payWithConfidence}</p>
        <p>{paymentSummaryData?.thisSiteIsSSLSecured}</p>
        <div className='m-paymentForm-images'>
          <img alt='' src={bitmap1} />
          <img alt='' src={comodosecure} />
          <img alt='' src={bitmap2} />
          <img alt='' src={ssl} />
        </div>
      </div>
    </div>
  )
}
