/* eslint-disable prefer-const */

const getFormattedAmount = ({ amount, currrencySymbol }: { amount: string; currrencySymbol: string }): string => {
  let [dollars, cents] = amount.toString().split('.')

  if (cents) {
    cents = cents.length === 1 ? `${cents}0` : cents
    return `${currrencySymbol}${dollars}.${cents}`
  }

  return `${currrencySymbol}${amount}`
}

export default getFormattedAmount
