import React from 'react'

import { PreviewAndPricing } from '../PreviewAndPricing'
import { type PrintAndDownloadProps } from './PrintAndDownloadProps'
import './PrintAndDownload.scss'

export const PrintAndDownload: React.FC<PrintAndDownloadProps> = ({
  title,
  template,
  formType,
  formSubType,
  draftActive,
  benefitsList,
  handleContinue,
  subscription,
  loading,
  version,
  loadingDataToPaymentPage,
  callbackBottomOverlay
}) => {
  return (
    <PreviewAndPricing
      title={title}
      template={template}
      formType={formType}
      formSubType={formSubType}
      draftActive={draftActive}
      benefitsList={benefitsList}
      handleContinue={handleContinue}
      loading={loading}
      subscription={subscription}
      version={version}
      loadingDataToPaymentPage={loadingDataToPaymentPage}
      callbackBottomOverlay={callbackBottomOverlay}
    />
  )
}
