/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { type GetTransactionStatusNoAuthResponse } from './interfaces'
import { TransactionRepositoryApi } from '../infra'

export async function getTransactionStatusNoAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transactionId
}: {
  successCallback: (result: GetTransactionStatusNoAuthResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transactionId: string
}): Promise<void> {
  await TransactionRepositoryApi.getStatusNoAuth(transactionId)
    .then((result: GetTransactionStatusNoAuthResponse) => {
      logInfo('GetTransactionStatusNoAuthUseCase')
      successCallback(result)
    })
    .catch((error) => {
      logError('GetTransactionStatusNoAuthUseCase', { transactionId }, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
