/* eslint-disable @typescript-eslint/no-explicit-any */

import { newStepStructure } from './newStepStructure'
import { referenceHandler } from './referenceHandler'

export const getNoRenderedFields = (form, structure): any[] => {
  const NoRenderFieldsArray = []

  const stepsDifferences = newStepStructure(form, structure).stepsDifference

  if (structure.steps) {
    structure.steps.forEach((step) => {
      step.groups.forEach((group) => {
        if (!referenceHandler(group, form, structure) || stepsDifferences.includes(step)) {
          group.fields.forEach((field) => {
            NoRenderFieldsArray.push(field)
          })
        }
      })
    })
  }

  return NoRenderFieldsArray
}
