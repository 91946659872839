import { graphql, useStaticQuery } from 'gatsby'

export const useQueryFormPage = (): Queries.STRAPI_FORM_PAGE => {
  const { strapiFormPage } = useStaticQuery(graphql`
    query {
      strapiFormPage {
        toggleHideShowPreviewLabel
        toggleHideShowLabel
        toggleEnableDisablePreviewLabel
        benefitsTitle
        printAndDownload {
          item
        }
        priceContent {
          item
        }
        productsFaqs {
          item
        }
        multiStepFormWizard {
          btnBackLabel
          btnCompleteAplicationLabel
          btnSaveAndContinueLabel
          btnSaveAndFinishLabel
          btnSkipStepLabel
          noStepsDefined
          someErrorWith
          headerTitleTemplate
          headerSubtitleTemplate
        }
        siteTitle
        continueModalContent {
          title
          textFeedback
          titleFeedback
          ctaFeedback
        }
        templateContainer {
          item
        }
      }
    }
  `)
  return strapiFormPage as Queries.STRAPI_FORM_PAGE
}
