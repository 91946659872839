/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import './UploadZone.scss'

import Upload from '../../../assets/images/componentsSvg/upload-doc.svg'

interface UploadZoneProps {
  data: Record<string, any>
}

export const UploadZone: React.FC<UploadZoneProps> = ({ data }) => {
  return (
    <div className='upload-zone' data-qa='drag-upload-zone'>
      <Upload />
      <p>{data.strapiMyDocumentsPage?.dragFileText}</p>
    </div>
  )
}
