import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'

export async function updateApplicationNameUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId,
  name
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
  name: string
}): Promise<void> {
  await ApplicationRepositoryApi.updateName(applicationId, name)
    .then(() => {
      logInfo(`UpdateApplicationNameUseCase applicationId: ${applicationId}`, { name })
      successCallback()
    })

    .catch((error: Error | undefined) => {
      logError(`UpdateApplicationNameUseCase applicationId: ${applicationId}`, { name }, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
