import { logError, logInfo } from '@legal/shared/logger'
import { ContactFormRepositoryApi } from '../infra'

export async function getContactCustomerDetailsUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (reference: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): Promise<void> {
  await ContactFormRepositoryApi.getDetails()
    .then((reference) => {
      logInfo('GetContactCustomerDetailsUseCase')
      successCallback(reference)
    })

    .catch((error: Error | undefined) => {
      logError('GetContactCustomerDetailsUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
