/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { TextInput as DSMTextInput } from '@npm_leadtech/legal-lib-components/TextInput'
import React from 'react'

import { formatEuropeanNumber } from '../../../services/utils/formatEuropeanNumber'
import { formatUSANumber } from '../../../services/utils/formatUSANumber'

const FILTER_NUMBER = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  ',',
  '.',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight'
]

const formatNumberValue = {
  USA: 'USA number',
  Europe: 'European number'
}

const formattterNumberValue = {
  [formatNumberValue.Europe]: formatEuropeanNumber,
  [formatNumberValue.USA]: formatUSANumber
}

export const TextInput = React.forwardRef(({ valueType, value, type, onChange, format, ...props }, ref) => {
  const [formatNumber, setFormatNumber] = React.useState(false)
  const [valueAux, setValueAux] = React.useState('')

  const setCorrectCursorPosition = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target
    const rawValue = input.value
    const cursorPosition = input?.selectionStart

    if (cursorPosition !== null) {
      const rawBeforeCursor = rawValue.slice(0, cursorPosition)
      const formattedBeforeCursor = formatUSANumber(rawBeforeCursor)
      const commaOffset = formattedBeforeCursor.length - rawBeforeCursor.length

      setTimeout(() => {
        const newCursorPosition = Math.max(0, cursorPosition + commaOffset)
        input.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 0)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event)
    if (valueType === formatNumberValue.USA || valueType === formatNumberValue.Europe) {
      setCorrectCursorPosition(event)
    }
    setValueAux(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (formatNumber) {
      if (!FILTER_NUMBER.includes(event.key)) {
        event.preventDefault()
      }

      if (event.key === '+') {
        event.preventDefault()
      }

      if (event.key === 'Backspace' || event.key === 'Delete') {
        if (event.target.value.length === 0) {
          setValueAux('')
        }
      }

      if (event.key === '-') {
        if (event.target.value.length > 0) {
          event.preventDefault()
          const valueTarget = event.target.value
          if (valueTarget[0] !== '-') {
            event.target.value = `-${valueTarget}`
            handleOnChange(event)
          }
        } else {
          if (valueAux === '-') {
            event.preventDefault()
          }
          setValueAux('-')
        }
      }
    }
  }

  const isFormatNumberSimple = (format: string): boolean => {
    if (format) {
      let resultado = true
      for (const charFormat of format) {
        if (charFormat !== '#') {
          resultado = false
        }
      }
      return resultado
    }
    return false
  }

  React.useEffect(() => {
    if (format) {
      setFormatNumber(isFormatNumberSimple(format))
    }
  }, [])

  let txtValue = value
  if (typeof formattterNumberValue[valueType] === 'function') {
    txtValue = formattterNumberValue[valueType](txtValue)
  }

  if (format && isFormatNumberSimple(format)) {
    type = 'number'
  }

  return (
    <DSMTextInput
      {...props}
      format={format}
      type={type}
      value={txtValue}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
    />
  )
})

TextInput.displayName = 'TextInput'
