import * as REQUESTS from './apiRequest'
import { DocumentRepository } from '../domain'

export const DocumentRepositoryApi: DocumentRepository = {
  async getAll(request) {
    return await REQUESTS.getDocuments(request)
  },
  async download(documentId) {
    return await REQUESTS.downloadDocument(documentId)
  },
  async upload(formdata) {
    return await REQUESTS.createDocument(formdata)
  },
  async delete(documentId) {
    return await REQUESTS.deleteDocument(documentId)
  },
  async updateName(documentId, name) {
    return await REQUESTS.updateDocumentName(documentId, name)
  }
}
