import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { FilterNotSingleDocument } from '../domain/services'
import { type Subscription } from '../domain'
import { SubscriptionRepositoryApi } from '../infra'

export async function getSubscriptionTypeByCodeUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  filterNotSingleDocument = false,
  tokenRequired = false
}: {
  successCallback: (subscriptions: Subscription[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  filterNotSingleDocument?: boolean
  tokenRequired?: boolean
}): Promise<void> {
  await SubscriptionRepositoryApi.findTypeByCode(tokenRequired)
    .then((subscriptions) => {
      logInfo('GetSubscriptionTypeByCodeUseCase')
      if (filterNotSingleDocument) subscriptions = FilterNotSingleDocument(subscriptions)
      successCallback(subscriptions)
    })
    .catch((error: ApiError) => {
      logError(`GetSubscriptionTypeByCodeUseCase ${error.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
