import React from 'react'

import phoneInfo from '../../../assets/images/svg/info_white_16px.svg'
import { useConfigs } from '@legal/core/configs'

export const ContactInfoContent = (): React.ReactElement => {
  const {
    SITE_METADATA: { SITE_PHONE, SITE_SCHEDULE_MOBILE }
  } = useConfigs()

  return (
    <div className='contact-info-content'>
      <div className='contact-info-content__site-phone'>
        <img src={phoneInfo} alt='phone info' />
        {SITE_PHONE}
      </div>
      <div className='contact-info-content__schedule-phone'>{SITE_SCHEDULE_MOBILE}</div>
    </div>
  )
}
