import { DocumentBlob } from '../../domain'
import { DocumentBlobData } from '../dataObjects/DocumentBlobData'
import { ENDPOINTS } from './Endpoints'
import { documentBlobDataToDocumentBlob } from '../adapters/documentBlobDataToDocumentBlob'
import { get } from '@legal/shared/HttpClient'

export async function downloadDocument(documentId: string): Promise<DocumentBlob> {
  const result = await get<DocumentBlobData>(ENDPOINTS.DOWNLOAD_DOCUMENT(documentId), true)
  return documentBlobDataToDocumentBlob(result)
}
