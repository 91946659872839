import { graphql, useStaticQuery } from 'gatsby'

export const useQueryNavbarItem = (): Queries.STRAPI__COMPONENT_MICROSITE_MENU_ITEM[] => {
  const { strapiNavbarItem } = useStaticQuery(graphql`
    query {
      strapiNavbarItem {
        navItems {
          name
        }
      }
    }
  `)

  return strapiNavbarItem.navItems ?? []
}
