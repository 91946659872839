/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Logo } from '@npm_leadtech/legal-lib-components/Logo'
import { LogoText } from '@npm_leadtech/legal-lib-components/LogoText'
import { MobileBottomBar } from '@npm_leadtech/legal-lib-components/MobileBottomBar'
import React from 'react'
import { SidemenuTab } from '@npm_leadtech/legal-lib-components/SidemenuTab'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { ContactInfoContent } from '../../atoms'
import DocSvg from '../../../assets/images/componentsSvg/doc-24-p.svg'
import DocSvgMob from '../../../assets/images/componentsSvg/doc-24-p-mobile.svg'
import { DocumentActionsNewMenu } from 'src/components/molecules/DocumentNewActionsMenu'
import LogoutSvg from '../../../assets/images/componentsSvg/logout-24-p.svg'
import Menu from '../../../assets/images/componentsSvg/menu-24-p.svg'
import NewDoc from '../../../assets/images/componentsSvg/newdoc-24p-menu.svg'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import ProfSvg from '../../../assets/images/componentsSvg/profile-24-p.svg'
import ProfSvgMob from '../../../assets/images/componentsSvg/profile-24-p-mobile.svg'
import QuestSvg from '../../../assets/images/componentsSvg/question-24-p.svg'
import QuestSvgMob from '../../../assets/images/componentsSvg/question-24-p-mobile.svg'
import { type SideMenuProps } from './SideMenuProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import logout from '../../../assets/images/png/logout-24-p@2x.png'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { stringSlugify } from 'src/services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useQuerySideMenu } from '@legal/shared/data/graphql/queries/useQuerySideMenu'

import './SideMenu.scss'

export const SideMenu: React.FC<SideMenuProps> = ({ newDocumentModal, activeMenuOption, documentUploadFileDialog }) => {
  const [openMenu, setOpenMenu] = React.useState(false)
  const { IS_JONSNOW, STRAPI_CONFIG, PA_UPLOAD_DOCUMENT_ENABLED } = useConfigs()
  const { applicationCookie, clearApplicationCookie } = useApplicationCookie()
  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const paymentCookie = new PaymentCookie()
  const sideMenuData = useQuerySideMenu()

  const logOutFunction = (): void => {
    const initialDocumentType = applicationCookie?.form?.name
    const eventProps = {
      initial_document_type: stringSlugify(initialDocumentType) ?? null,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('log_out', eventProps)
    clearApplicationCookie()
    userCookie.clear()
    subscriptionCookie.clear()
    paymentCookie.clear()
    setCookiePolicyFunc()
  }

  const openMainMenu = (): void => {
    if (PA_UPLOAD_DOCUMENT_ENABLED) {
      setOpenMenu(!openMenu)
    } else {
      newDocumentModal?.changeModalState()
    }
  }

  const getMenuOption = (isMobile = false): any => {
    const menuOptions = [
      {
        id: 0,
        image: isMobile ? <DocSvgMob /> : <DocSvg />,
        text:
          isMobile ?
            sideMenuData.sideMenu.sideMenuItems[0].mobileText
          : sideMenuData.sideMenu.sideMenuItems[0].desktopText,
        link: '/my-documents/'
      },
      {
        id: 1,
        image: isMobile ? <QuestSvgMob /> : <QuestSvg />,
        text:
          isMobile ?
            sideMenuData.sideMenu.sideMenuItems[1].mobileText
          : sideMenuData.sideMenu.sideMenuItems[1].desktopText,
        link: '/help-center/'
      },
      {
        id: 2,
        image: isMobile ? <ProfSvgMob /> : <ProfSvg />,
        text:
          isMobile ?
            sideMenuData.sideMenu.sideMenuItems[2].mobileText
          : sideMenuData.sideMenu.sideMenuItems[2].desktopText,
        link: '/my-profile/'
      }
    ]

    if (!isMobile) {
      return menuOptions
    }

    return [
      menuOptions[0],
      menuOptions[1],
      {
        type: 'button',
        image: <NewDoc />,
        onClick: openMainMenu,
        dataQa: 'upload_document_mobile_button'
      },
      menuOptions[2],
      {
        id: 4,
        image: <Menu />,
        text: sideMenuData.sideMenu.menuText,
        menu: [
          {
            image: <LogoutSvg />,
            content: sideMenuData.sideMenu.logoutText,
            onClick: logOutFunction
          }
        ]
      }
    ]
  }

  const renderMenuOptions = (isMobile): React.JSX.Element[] =>
    getMenuOption(isMobile).map((option) => {
      const { id, text, image, type, onClick, link, dataQa, ...rest } = option
      if (type === 'button') {
        return (
          <Button key={`side-menu-button_${id}`} noLink onClick={onClick} dataQa={dataQa}>
            {image}
          </Button>
        )
      }
      return (
        <SidemenuTab
          key={`side-menu-tab_${id}`}
          mobile={isMobile}
          onClick={
            link ?
              async () => {
                await navigate('/my-account' + link)
              }
            : undefined
          }
          active={activeMenuOption === id}
          image={image}
          text={text}
          {...rest}
        />
      )
    })

  const renderDesktopBar = (): React.JSX.Element => (
    <div className='sidemenu-desktop'>
      <div className='sidemenu-top-container'>
        <button
          className='top-button transparent-button logo'
          onClick={() => {
            setCookiePolicyFunc()
          }}
        >
          <Logo siteName={STRAPI_CONFIG.DOMAIN} width='188' height='33' type='white' />
        </button>
        <div className='button-tabs'>
          <Button
            color='primary3'
            givenClass='top-button stretch-button'
            label={sideMenuData.documentsSection.newDocumentButtonText}
            noLink
            onClick={newDocumentModal?.changeModalState}
            functionParameters={''}
            dataQa='new_document_desktop_button'
          />
          {renderMenuOptions()}
        </div>
      </div>
      <div className='sidemenu-bottom-container'>
        {!IS_JONSNOW && <ContactInfoContent />}
        <button className='sidemenu-logout' onClick={logOutFunction}>
          <LogoText logo={logout}>Log Out</LogoText>
        </button>
      </div>
    </div>
  )

  return (
    <div className='sidemenu'>
      {renderDesktopBar()}
      <div className='top-bar_mobile'>
        <button
          className='top-button transparent-button'
          onClick={() => {
            setCookiePolicyFunc()
          }}
        >
          <Logo siteName={STRAPI_CONFIG.DOMAIN} width='188' height='33' type='white' />
        </button>
      </div>
      <MobileBottomBar>{renderMenuOptions(true)}</MobileBottomBar>
      <DocumentActionsNewMenu
        sideMenuData={sideMenuData}
        newApplication={() => {
          setOpenMenu(false)
          newDocumentModal?.changeModalState()
        }}
        uploadDocument={() => {
          setOpenMenu(false)
          documentUploadFileDialog()
        }}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        data={{}}
      />
    </div>
  )
}
