import React from 'react'

import { type PaymentFormLoaderProps } from './PaymentFormLoaderProps'

export const PaymentFormLoader: React.FC<PaymentFormLoaderProps> = ({ children }) => {
  return (
    <div className='top-flex-container'>
      <div className='m-paymentForm-container left-container'>{children}</div>
    </div>
  )
}
