import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import { type ButtonProps } from '@npm_leadtech/legal-lib-components/Button'
import { SignIn } from '../../molecules'
import { replaceValues } from 'src/services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'

import './LoginTemplate.scss'

export const LoginTemplate: React.FC = () => {
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const loginData = useQueryLogin()
  const Form = <SignIn />

  const boxFooter: {
    text: string
    button: ButtonProps
  } = {
    text: replaceValues(loginData?.footerText, { SITE_NAME }) ?? `New to ${SITE_NAME}?`,
    button: {
      dataQa: 'register',
      label: loginData.footerButtonText ?? 'Try it for Free!',
      link: '/register',
      LinkComponent: Link
    }
  }

  return (
    <div className='login-container'>
      <div className='login-module-container'>
        <BoxForm title={loginData.title ?? 'Sign In to Your Account'} body={Form} footer={boxFooter} />
      </div>
    </div>
  )
}

export const useQueryLogin = (): Queries.STRAPI_LOGIN_PAGE => {
  const { strapiLoginPage } = useStaticQuery(graphql`
    query {
      strapiLoginPage {
        title
        footerText
        footerButtonText
      }
    }
  `)

  return strapiLoginPage
}
