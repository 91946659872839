import { logError, logInfo } from '@legal/shared/logger'
import { DocumentRepositoryApi } from '../infra'

export async function deleteDocumentUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: { documentId: string }
  successCallback: () => void
  errorCallback?: () => void
  finallyCallback?: () => void
}): Promise<void> {
  await DocumentRepositoryApi.delete(request.documentId)
    .then(() => {
      logInfo('deleteDocumentUseCase success', request)
      successCallback()
    })
    .catch((error: unknown) => {
      logError(`deleteDocumentUseCase error`, undefined, error as Error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
