/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import type { AlternativeStepProps, FormPlainJsonProps, FormProps, NormalizedFormProps } from './FormProps'
import type {
  AlternativeStepStrapiDataProps,
  FormStrapiDataProps,
  GroupFaqItemStrapiDataProps,
  GroupFieldStrapiDataProps,
  GroupRefGroupItemStrapiDataProps,
  GroupStrapiDataProps,
  NextStepStrapiDataProps,
  StepStrapiDataProps
} from './FormStrapiProps'
import { setValues } from './setValues'

export const newLastStep = {
  name: 'Print&Download',
  label: 'Print & Download',
  sectionNav: 'Print & Download',
  slug: 'print-and-download',
  defaultNextStep: null,
  refStep: {
    name: null,
    value: null,
    nextStep: null
  },
  refSteps: [],
  groups: []
}
const hasChangeStepLink = (step: StepStrapiDataProps): AlternativeStepStrapiDataProps => {
  return step.changeTo[0]
}

const fillRefStepsArray = (step: StepStrapiDataProps): AlternativeStepProps[] => {
  return step.changeTo.map((step: AlternativeStepStrapiDataProps) => {
    return {
      name1: step.fieldRef.name ?? null,
      value1: step.value,
      name2: step.fieldRef2?.name ?? null,
      value2: step.value2,
      name3: step.fieldRef3?.name ?? null,
      value3: step.value3,
      name4: step.fieldRef4?.name ?? null,
      value4: step.value4,
      nextStep: step?.changeStep?.stepName ?? null
    }
  })
}

const hasFaqGroup = (group: GroupStrapiDataProps): boolean => {
  return group.faq.length > 0
}

const fillGroupFaq = (group: GroupStrapiDataProps): GroupFaqItemStrapiDataProps[] => {
  const faq: GroupFaqItemStrapiDataProps[] = []
  group.faq.forEach((singleFaq: GroupFaqItemStrapiDataProps): void => {
    faq.push(singleFaq)
  })
  return faq
}
const hasRefFieldGroup = (group: GroupStrapiDataProps): boolean => {
  return group.refGroup.length > 0
}
const fillGroupRefField = (group: GroupStrapiDataProps): GroupRefGroupItemStrapiDataProps[] => {
  const refField: GroupRefGroupItemStrapiDataProps[] = []
  group.refGroup.forEach((refGroup: GroupRefGroupItemStrapiDataProps): void => {
    refField.push({
      name: refGroup.name,
      value: refGroup.value
    })
  })
  return refField
}
const fillSteps = (
  json: FormProps,
  step: StepStrapiDataProps,
  stepNumber: number,
  refSteps: AlternativeStepProps[],
  isTestAB8814: boolean
): FormProps => {
  json.steps.push({
    name: step.stepName ?? stepNumber,
    label: step.label ?? '',
    sectionNav: step.sectionNav ?? null,
    slug: step.urlStep ?? '',
    defaultNextStep: step.nextStep?.stepName ?? null,
    refStep: {
      name: step.changeTo[0]?.fieldRef ? step.changeTo[0].fieldRef.name : null,
      value: step.changeTo[0]?.value ?? null,
      nextStep: getRefStepsNextStep(step, isTestAB8814)
    },
    refSteps,
    groups: []
  })
  return json
}

const fillGroups = (
  json: FormProps,
  group: GroupStrapiDataProps,
  stepNumber: number,
  faq: GroupFaqItemStrapiDataProps[],
  refField: GroupRefGroupItemStrapiDataProps[]
): FormProps => {
  json.steps[stepNumber].groups.push({
    name: group.name,
    label: group.groupLabel,
    faq,
    tooltip: group.groupTooltip,
    required: group.required ?? false,
    errorMessage: group.errorMessage ?? '',
    referenceGroupLogic: group.referenceGroupLogic,
    fields: [],
    refField
  })
  return json
}

const strapiFormAdapter = (form: any): FormStrapiDataProps => {
  return {
    ...form,
    country: `[${form.country.strapi_json_value.join(', ')}]`
  }
}
export const normalizeFormJson = (json: Queries.StrapiFormDataFragment, isTestAB8814 = false): NormalizedFormProps => {
  const plainJson: FormPlainJsonProps = {}
  let structureJson: FormProps = { steps: [] }
  let wrongForm = false

  const formJson: FormStrapiDataProps = strapiFormAdapter(json)

  formJson.stepsForm.forEach((step: StepStrapiDataProps, index: number): void => {
    if (step && !wrongForm) {
      const groups: GroupStrapiDataProps[] = step.groupsField
      const stepNumber: number = index

      // testAB8814
      if (isTestAB8814) {
        const defaultNextStep: NextStepStrapiDataProps | null = step.nextStep
        if (defaultNextStep === null) {
          step.nextStep = {
            stepName: newLastStep.name
          }
        }
      }

      const refSteps: AlternativeStepProps[] = hasChangeStepLink(step) ? fillRefStepsArray(step) : []
      structureJson = fillSteps(structureJson, step, stepNumber, refSteps, isTestAB8814)

      groups.forEach((group: GroupStrapiDataProps, index: number): void => {
        const fields: GroupFieldStrapiDataProps[] = group.fieldsData
        const groupNumber: number = index
        const faq: GroupFaqItemStrapiDataProps[] = hasFaqGroup(group) ? fillGroupFaq(group) : []
        const refField: GroupRefGroupItemStrapiDataProps[] = hasRefFieldGroup(group) ? fillGroupRefField(group) : []
        structureJson = fillGroups(structureJson, group, stepNumber, faq, refField)

        fields.forEach((field: GroupFieldStrapiDataProps): void => {
          structureJson.steps[stepNumber].groups[groupNumber].fields.push(field.name)
          plainJson[field.name] = setValues(field)
        })
      })
    } else {
      wrongForm = true
    }
  })

  // testAB8814
  if (isTestAB8814) structureJson.steps.push(newLastStep)

  return { plainJson, structureJson }
}

const getRefStepsNextStep = (step: StepStrapiDataProps, isTestAB8814: boolean): string | null => {
  if (step.changeTo[0]?.fieldRef?.name) {
    if (step.changeTo[0]?.changeStep?.stepName) {
      return step.changeTo[0].changeStep.stepName
    }
    if (isTestAB8814) {
      return newLastStep.name
    }
  }
  return null
}
