import React from 'react'
import classNames from 'classnames'

import { type JumbotronSimplifiedProps } from './JumbotronSimplifiedProps'
import './JumbotronSimplified.scss'

export const JumbotronSimplified: React.FC<JumbotronSimplifiedProps> = ({ title, subtitle }) => {
  const classJumbotronSimplified = classNames({
    'jumbotron--simplified': true
  })

  return (
    <section className={classJumbotronSimplified}>
      <div className='wrapper wrapper--responsive'>
        <h2 className='serif jumbotron--simplified__title'>{title}</h2>
        <h3 className='sans-serif jumbotron--simplified__subtitle'>{subtitle}</h3>
      </div>
    </section>
  )
}
