import React, { type FC } from 'react'
import { FormFaq } from '@npm_leadtech/legal-lib-components/FormFaq'

import { type ProductFaqsProps } from './ProductFaqsProps'
import './ProductFaqs.scss'

export const ProductFaqs: FC<ProductFaqsProps> = ({ faqsArray }) => {
  return (
    <div id='product-faqs'>
      <ul>
        {faqsArray.map((faq, key) => (
          <FormFaq title={faq.title} key={key} answer={faq.content} />
        ))}
      </ul>
    </div>
  )
}
