export const getLastStepBeforeResumingApplication = (savedStepsApiObj, newStepStructure): number => {
  let lastStep = 1
  newStepStructure.steps.forEach((newStructureStep, index: number) => {
    Object.keys(savedStepsApiObj).forEach((step) => {
      if (newStructureStep.name.toLowerCase() === step) {
        lastStep = index + 2
      }
    })
  })
  if (lastStep > newStepStructure.steps.length) {
    lastStep = newStepStructure.steps.length
  }
  return lastStep
}
