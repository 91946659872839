/* eslint-disable @typescript-eslint/no-explicit-any */
const arrayCompareEqual = (array1: any[], array2: any[]): boolean => {
  const jsonArray1 = JSON.stringify(array1)
  const jsonArray2 = JSON.stringify(array2)
  if (jsonArray1 === jsonArray2) {
    return true
  }
  return false
}

export { arrayCompareEqual }
