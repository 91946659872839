import { logError, logInfo } from '@legal/shared/logger'
import { CustomerAuthForgotPassword } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export function CustomerAuthForgotPasswordUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: { email: string }
}): void {
  const user = new UserCookie()
  if (user.token) {
    logError('CreateCustomerWithSubscription', { error: 'User already authenticated' })
    return
  }
  CustomerAuthForgotPassword(request.email)
    .then(() => {
      logInfo('CustomerAuthForgotPasswordUseCase')
      successCallback()
    })

    .catch((error: Error | undefined) => {
      logError('CustomerAuthForgotPasswordUseCase', undefined, error)
      if (errorCallback) errorCallback(error)
    })
    .finally(() => {
      if (finallyCallback) finallyCallback()
    })
}
