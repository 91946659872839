/* eslint-disable @typescript-eslint/no-explicit-any */
import { type CancelSubscriptionRequest, type CancelSubscriptionResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { SubscriptionRepositoryApi } from '../infra'

export async function cancelSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CancelSubscriptionResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CancelSubscriptionRequest
}): Promise<void> {
  await SubscriptionRepositoryApi.cancel(request.subscriptionId, request.unsubscribeReason)
    .then((response) => {
      logInfo('CancelSubscriptionUseCase')
      successCallback(response)
    })
    .catch((error) => {
      logError('CancelSubscriptionUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
