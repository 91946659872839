import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import './Loading.scss'

export const Loading: React.FC = () => {
  return (
    <div className='page--status'>
      <Spinner className='page--status__spinner spinner--primary' />
    </div>
  )
}
