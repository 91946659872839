import { logError, logInfo } from '@legal/shared/logger'
import { DocumentRepositoryApi } from '../infra'

export async function updateDocumentNameUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: { documentId: string; name: string }
  successCallback?: () => void
  errorCallback?: (error?: string) => void
  finallyCallback?: () => void
}): Promise<void> {
  await DocumentRepositoryApi.updateName(request.documentId, request.name)
    .then(() => {
      logInfo(`updateDocumentNameUseCase success ${request.name}`)
      if (successCallback) successCallback()
    })
    .catch((error: unknown) => {
      logError(`updateDocumentNameUseCase error ${request.name}`, undefined, error as Error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
