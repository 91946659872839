import { type Customer } from '../../domain'
import { type CustomerData } from '../dataObject'

export function CustomerDataToCustomer(data: CustomerData): Customer {
  return {
    id: data.customer.id,
    email: data.customer.email,
    token: data.token,
    canUnsubscribeOffer: data.canUnsubscribeOffer,
    isAutoGenerated: data.customer.isAutoGenerated,
    canUpdateCard: data.canUpdateCard,
    canUpgradeAccount: data.canUpgradeAccount,
    type: data.customer.type ?? undefined,
    isCompany: data.customer.type === 'company',
    reference: data.customer.reference,
    firstName: data.customer.firstName ?? undefined,
    lastName: data.customer.lastName ?? undefined,
    company: {
      sector: data.customer.companySector ?? undefined,
      size: data.customer.companySize ?? undefined
    },
    subscriptions: data.subscriptions
  }
}
