import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { logError, logInfo } from '@legal/shared/logger'
import { type GeoLocation } from '@legal/customer'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class GetCustomerGeoLocation {
  public static async Execute(): Promise<HttpClientResponse<GeoLocation>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX
    })

    const responsePromise = new Promise<HttpClientResponse<GeoLocation>>((resolve, reject) => {
      http
        .get('customer/geoip')
        .then((response) => {
          const responseData = response as HttpClientResponse<GeoLocation>
          logInfo('GetCustomerGeoLocation', responseData)
          resolve(responseData)
        })
        .catch((error: object | undefined) => {
          logError('GetCustomerGeoLocation', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
