/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { TestABCookie } from '../../storage/cookies/TestABCookie'
import { getParamUrlValue } from '../../utils/paramsUrl'

const isActiveParamUrlOrCookie = ({ testId }: { testId: string }): unknown => {
  let isActive = null

  const versionParamInUrl = versionInParamUrl(testId)
  if (versionParamInUrl) {
    isActive = versionParamInUrl
  }

  const versionInCookie = !isActive && validateVersionInCookie(testId)
  if (versionInCookie) {
    isActive = versionInCookie
  }

  return isActive
}

const versionInParamUrl = (testId: string): unknown => {
  return getParamUrlValue(testId)
}

const validateVersionInCookie = (testId: string): boolean | null => {
  if (testId) {
    const versionTestCookie = new TestABCookie(testId)
    if (versionTestCookie && versionTestCookie.value === 'true') {
      return true
    }
  }
  return null
}

export { isActiveParamUrlOrCookie }
