import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class UserCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('user') })
  }

  get token(): string | undefined {
    return this.read('token')
  }

  set token(name) {
    this.write({ key: 'token', data: name })
  }

  get email(): string | undefined {
    return this.read('email')
  }

  set email(name) {
    this.write({ key: 'email', data: name })
  }

  get subscriptionCodeId(): string | undefined {
    return this.read('subscriptionCodeId')
  }

  set subscriptionCodeId(name) {
    this.write({ key: 'subscriptionCodeId', data: name })
  }

  get paymentGateway(): string | undefined {
    return this.read('paymentGateway')
  }

  set paymentGateway(name) {
    this.write({ key: 'paymentGateway', data: name })
  }
}
