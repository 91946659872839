import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'

import { type DeleteCardErrorModalProps } from './DeleteCardErrorModalProps'
import { Modal } from '../../layout'
import './DeleteCardErrorModal.scss'

export const DeleteCardErrorModal: React.FC<DeleteCardErrorModalProps> = ({
  closeFunction,
  onClickButton,
  dataTexts
}) => {
  return (
    <Modal
      title={dataTexts?.title ?? ''}
      size='S'
      type='default'
      closeFunction={closeFunction}
      customClass='delete-card-error-modal'
    >
      <Feedback
        theme='error'
        title={dataTexts?.titleFeedback ?? ''}
        text={dataTexts?.textFeedback ?? ''}
        button={{
          label: dataTexts?.ctaFeedback ?? '',
          onClick: onClickButton,
          noLink: true,
          dataQa: 'delete-card-error-modal__button'
        }}
      />
    </Modal>
  )
}
