import { navigate } from 'gatsby'

import { PaymentCookie } from '../storage/cookies/PaymentCookie'
import { ProductCookie } from '../../services/storage/cookies/ProductCookie'
import { getAndSetDataLayer } from '@legal/shared/utils'

export interface toastResponseProps {
  text: string | null
  title: string | null
  type: string | null
}

export const navigateToCreatePassword = (
  urlParameters: string,
  document_subtype: string,
  applicationId?: string,
  toastResponse?: toastResponseProps
): void => {
  if (applicationId) {
    getAndSetDataLayer({ applicationId })
  }
  const paymentCookie = new PaymentCookie()
  paymentCookie.clear()

  const productCookie = new ProductCookie()

  void navigate('/create-password' + urlParameters, {
    state: {
      toast: toastResponse,
      applicationId: applicationId,
      document_type: productCookie.product ?? '',
      document_subtype: document_subtype
    }
  })
}
