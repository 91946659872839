import { graphql, useStaticQuery } from 'gatsby'

export const useQueryCreateDocumentPdf = (): Queries.STRAPI_CREATE_DOCUMENT_PAGE => {
  const { strapiCreateDocumentPage } = useStaticQuery(graphql`
    query {
      strapiCreateDocumentPage(title: { ne: "mock" }) {
        title
        text
        toastDocumentEditing {
          ...StrapiToast
        }
        toastDocumentCreated {
          ...StrapiToast
        }
        toastDocumentError {
          ...StrapiToast
        }
        toastDocumentNotBeenCreated {
          ...StrapiToast
        }
      }
    }
  `)

  return strapiCreateDocumentPage
}
