/* eslint-disable @typescript-eslint/no-explicit-any */

const formatStatesToForm = (items): any => {
  let statesData = []
  const itemsWithStates = items?.filter((item) => item?.states?.length > 0)
  if (itemsWithStates?.length > 0) {
    statesData = itemsWithStates.map((item) => {
      if (item.states.length > 0) {
        return {
          label: item?.states[0]?.name,
          value: item?.states[0]?.code,
          slug: item?.slugUrl
        }
      }
    })
  }
  return statesData
}

export { formatStatesToForm }
