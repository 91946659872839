import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { type Subscription } from '../domain'
import { SubscriptionRepositoryApi } from '../infra'

export async function getSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (subscriptions: Subscription) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  request: { subscriptionId: string }
}): Promise<void> {
  await SubscriptionRepositoryApi.get(request.subscriptionId)
    .then((subscription) => {
      logInfo('GetSubscriptionUseCase')
      successCallback(subscription)
    })
    .catch((error: ApiError) => {
      logError(`GetSubscriptionUseCase ${error.message}`, request)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
