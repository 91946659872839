import { type MessagesError } from '@legal/shared/data/types/MessagesError'
import { type Validator } from '../validator.types'
import { validatorString } from '../validatorString'

export const cardHolderNameValidator = (value: string, messagesError: MessagesError): Validator => {
  const validators = [
    {
      isValid: validatorString.isEmptyValue(value),
      message: messagesError.code01 ?? ''
    }
  ]

  return validators.find(({ isValid }) => !isValid) ?? { isValid: true, message: messagesError.success ?? '' }
}
