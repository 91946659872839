import React from 'react'

import { isVersionSupertest } from '../testAB/versionSupertest/versionSupertest'

export const useIstestAB8814 = ({ versionName, product }: { versionName?: string; product: string }): boolean[] => {
  const [isTestAB8814, setIstestAB8814] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    const isSuperTest = isVersionSupertest({
      dataVersion: versionName,
      product
    })
    setIstestAB8814(isSuperTest)
    setLoaded(true)
  }, [])

  return [isTestAB8814, loaded]
}
