import React from 'react'

export const IconWeb: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <defs>
        <path
          id='ep7tr2p7ra'
          d='M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g>
              <g transform='translate(-376.000000, -314.000000) translate(328.000000, 205.000000) translate(32.000000, 90.000000) translate(16.000000, 19.000000)'>
                <path d='M0 0H24V24H0z' />
                <mask id='7tvbvoimzb' fill='#fff'>
                  <use xlinkHref='#ep7tr2p7ra' />
                </mask>
                <g className='icon-web-24-p' fill='#000' mask='url(#7tvbvoimzb)'>
                  <path d='M0 0H24V24H0z' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
