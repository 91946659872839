import React from 'react'

import { BillingRefundProps } from './BillingRefundProps'
import { formatDate } from '@legal/shared/utils'
import getFormattedAmount from 'src/services/utils/getFormattedAmount'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import './BillingRefund.scss'

export const BillingRefund: React.FC<BillingRefundProps> = ({ amount, id, paidAt, subscriptionTypeName }) => {
  const date = formatDate({ date: paidAt.date, format: 'MMMM, YYYY' }).split(' ').reverse().join(' ')
  const { currrencySymbol } = useQueryCommonsText()
  const formattedAmount = getFormattedAmount({ amount, currrencySymbol })
  return (
    <div key={id} className='refund-content'>
      <span>
        {date} - {subscriptionTypeName}
      </span>
      <span>{formattedAmount}</span>
    </div>
  )
}
