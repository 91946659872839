const mockStepState = {
  name: 'state',
  label: 'State',
  sectionNav: 'General',
  slug: 'state',
  refStep: {
    name: null,
    value: null,
    nextStep: null
  },
  refSteps: [],
  groups: [
    {
      name: 'stateRLAGroup',
      label: '',
      faq: [],
      tooltip: '',
      required: false,
      errorMessage: '',
      fields: ['stateForm']
    }
  ]
}

const mockStateSelectMessi = {
  id: '5633488',
  component: 'SearchSelect',
  type: 'select',
  class: '',
  tooltip: '',
  name: 'stateForm',
  label: 'Please select your state',
  value: 'California',
  required: false,
  disabled: false,
  errorMessage: '',
  validate: true,
  isValidGroup: true,
  items: [{ label: 'California' }]
}

export { mockStepState, mockStateSelectMessi }
