/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'

import { useToastsCustom } from '../../../services/hooks/useToastsCustom'

export interface SnackbarLayoutInnerProps {
  snackbarData?: any
  removeAllSnackbars?: boolean
}

export const SnackbarLayoutInner = ({
  snackbarData,
  removeAllSnackbars = false
}: {
  snackbarData?: any
  removeAllSnackbars?: boolean
}): null => {
  const { addToastCustom, removeAllToasts } = useToastsCustom()

  React.useEffect(() => {
    if (snackbarData) {
      addToastCustom({
        title: snackbarData.title,
        type: snackbarData.type,
        text: snackbarData.text
      })
    }
  }, [snackbarData])

  React.useEffect(() => {
    removeAllToasts()
  }, [removeAllSnackbars])

  return null
}
