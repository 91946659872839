import React, { type FC } from 'react'

import { useQueryCreateDocument, useQueryNavbarItem } from '@legal/shared/data/graphql'
import { DocumentListMenu } from '../../organisms'
import { type NavbarItemsProps } from './NavbarItemsProps'
import { useAllFeaturedProducts } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'

export const NavbarItems: FC<NavbarItemsProps> = ({
  headerHasNoProducts,
  mobileHideResources,
  mobileHideDocumentsList,
  isTestAB8814,
  isMenuHelp
}) => {
  const { IS_JONSNOW, TARGET_ADDRESS, HAS_GRAV_SITEMAP } = useConfigs()
  const navbarItems = useAllFeaturedProducts()
  const dataCreateDocument = useQueryCreateDocument()
  const navItems = useQueryNavbarItem()
  const [
    { name: help } = {},
    { name: faq } = {},
    { name: contactUs } = {},
    { name: documents } = {},
    { name: articles } = {},
    { name: legalDictionary } = {},
    { name: resources } = {}
  ] = navItems

  return (
    <>
      {isTestAB8814 ?
        <div className={'navigation-pane__help'}>
          <p className={'navigation-pane__help__title'}>{help}</p>
          <ul className={'help__list'}>
            {!IS_JONSNOW && (
              <li className='menu-items-li' title='FAQ'>
                <a href={`${TARGET_ADDRESS}/faqs/`} className='menu-items-li__link'>
                  {faq}
                </a>
              </li>
            )}
            <li className='menu-items-li' title='Contact us'>
              <a href={`${TARGET_ADDRESS}/contact-us/`} className='menu-items-li__link'>
                {contactUs}
              </a>
            </li>
          </ul>
        </div>
      : <>
          {!headerHasNoProducts && !mobileHideDocumentsList && (
            <div className={'navigation-pane__products'}>
              <p className={'navigation-pane__products__title'}>{documents}</p>
              <div className={'products__list'}>
                <DocumentListMenu navbarItems={navbarItems} data={dataCreateDocument} />
              </div>
            </div>
          )}
          <div className={`navigation-pane__help ${mobileHideDocumentsList ? 'no-border' : ''}`}>
            <p className={'navigation-pane__help__title'}>{help}</p>
            <ul className={'help__list'}>
              {!IS_JONSNOW && (
                <li className='menu-items-li' title='FAQ'>
                  <a
                    target={isMenuHelp ? '_blank' : '_self'}
                    href={`${TARGET_ADDRESS}/faqs/`}
                    className='menu-items-li__link'
                    rel='noreferrer'
                  >
                    {faq}
                  </a>
                </li>
              )}
              <li className='menu-items-li' title='Contact us'>
                <a
                  target={isMenuHelp ? '_blank' : '_self'}
                  href={`${TARGET_ADDRESS}/contact-us/`}
                  className='menu-items-li__link'
                  rel='noreferrer'
                >
                  {contactUs}
                </a>
              </li>
              {!IS_JONSNOW && (
                <>
                  <li className='mobile-articles-item menu-items-li is-tablet' title='Articles'>
                    <a href={`${TARGET_ADDRESS}/articles`} className='menu-items-li__link'>
                      {articles}
                    </a>
                  </li>
                  <li className='mobile-legaldictionary-item menu-items-li is-tablet' title='Legal Dictionary'>
                    <a href={`${TARGET_ADDRESS}/legal-dictionary`} className='menu-items-li__link'>
                      {legalDictionary}
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
          {!IS_JONSNOW && !mobileHideResources && HAS_GRAV_SITEMAP && (
            <div className={'navigation-pane__resources'}>
              <p className={'navigation-pane__resources__title'}>{resources}</p>
              <ul className={'resources__list'}>
                <li className='menu-items-li' title='Articles'>
                  <a href={`${TARGET_ADDRESS}/articles`} className='menu-items-li__link'>
                    {articles}
                  </a>
                </li>
                <li className='menu-items-li' title='Legal Dictionary'>
                  <a href={`${TARGET_ADDRESS}/legal-dictionary`} className='menu-items-li__link'>
                    {legalDictionary}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </>
      }
    </>
  )
}
