interface ErrorsProviders {
  code: string
  message: string
}

interface ProvidersMessages {
  provider: string
  errors: ErrorsProviders[]
}

const getProvidersMessages = (
  providersMessages: ProvidersMessages[] | null,
  nameToSearch: string
): ProvidersMessages | null => {
  if (!providersMessages) {
    return null
  }
  return providersMessages.find(({ provider }) => nameToSearch === provider) ?? null
}

const getProvidersErrorMessage = (providersErrors: ErrorsProviders[], code: string): string | null => {
  if (providersErrors.length === 0) {
    return null
  }
  return providersErrors.find((error) => error.code === code)?.message ?? null
}

export { getProvidersMessages, getProvidersErrorMessage, type ErrorsProviders, type ProvidersMessages }
