import { useConfigs } from '@legal/core/configs'
import { useQueryAllFeaturedProductsFromStrapi } from '../data/graphql'

export const useAllFeaturedProducts = (): FeaturedProducts[] => {
  const allProducts = AllFeaturedProductsFromStrapi()
  return allProducts
}

function AllFeaturedProductsFromStrapi(): FeaturedProducts[] {
  const {
    STRAPI_CONFIG: { DOMAIN }
  } = useConfigs()
  const data = useQueryAllFeaturedProductsFromStrapi()
  const filteredByDomainData = data.filter(
    ({ node }: { node: Queries.STRAPI_PRODUCT_PAGE }) => node.domain?.name === DOMAIN
  )
  const products: FeaturedProducts[] = filteredByDomainData.map((item): FeaturedProducts => {
    return {
      categoryUrl: item.node.category?.url ?? undefined,
      isFeatured: item.node.isFeatured ?? false,
      popularOrder: item.node.popularOrder ?? 0,
      jumboTitle: item.node.jumbotron?.title ?? '',
      linkText: item.node.linkText ?? '',
      slug: item.node.slug ?? '',
      footerPopularDocument: item.node.footerPopularDocument ?? false,
      categoryProduct: {
        name: item.node.categoryProduct?.name ?? '',
        icon: item.node.categoryProduct?.icon?.url ?? ''
      }
    }
  })
  return products
}

export interface FeaturedProducts {
  categoryUrl?: string
  isFeatured?: boolean
  popularOrder: number
  jumboTitle: string
  linkText: string
  slug: string
  footerPopularDocument?: boolean
  logoImgBig?: string
  logoImgSmall?: string
  categoryProduct?: {
    name: string
    icon: string
  }
}
