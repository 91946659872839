import { CookiePolicyCookie } from '../storage/cookies/CookiePolicyCookie'
import { getConfigs } from '@legal/core/configs'

// Use this function if and only if navigating from app to web.
export const setCookiePolicyFunc = (url?: string, setCookiePolicyAccepted: boolean = true): void => {
  const { TARGET_ADDRESS } = getConfigs()
  if (setCookiePolicyAccepted) {
    CookiePolicyCookie.cookiePolicyAccepted = true
  }
  if (typeof window !== 'undefined') {
    let toLocation = TARGET_ADDRESS
    if (url && typeof url === 'string') {
      toLocation += `/${url}`
    }
    window.location = toLocation
  }
}

export const setCookiePolicyFuncNoRedirect = (setCookiePolicyAccepted = true): void => {
  if (setCookiePolicyAccepted) {
    CookiePolicyCookie.cookiePolicyAccepted = true
  }
}
