import { type CreditCard } from '../../domain'
import { type CreditCardData } from '../dataObjects/CreditCardData'

export function CreditCardDataToCreditCard(creditCardData: CreditCardData): CreditCard {
  return {
    id: creditCardData.id,
    brandLogo: creditCardData.brand.toLowerCase().split(' ').join('-'),
    numberShort: creditCardData.number.substring(12, 16),
    expirationMonth: creditCardData.expirationMonth,
    expirationYearShort: creditCardData.expirationYear.substring(2, 4),
    holder: creditCardData.holder,
    status: creditCardData.status.charAt(0).toUpperCase() + creditCardData.status.slice(1),
    isActive: creditCardData.status === 'active',
    isDefault: creditCardData.isDefault,
    expirationYear: creditCardData.expirationYear,
    actions: {
      delete: creditCardData.actions?.delete ?? false
    }
  }
}
