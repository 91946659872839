import { getParamUrlValue } from '../../../services/utils/paramsUrl'

export const getFormUrlParameters = ({
  formType,
  formStateName,
  formSubType
}: {
  formType: string
  formStateName?: string
  formSubType?: string
}): string => {
  let urlParameters = ''
  const productType = getParamUrlValue('product') ?? null
  urlParameters = formType && urlParameters + `product=${productType ?? formType}`
  if (formStateName && !formSubType) return urlParameters + `&type=${formStateName}`
  return !formStateName && formSubType ? urlParameters + `&type=${formSubType}` : urlParameters
}
