/* eslint-disable @typescript-eslint/no-explicit-any */

export const getValueFromForm = (keyedValue, form, label): any => {
  const formKey = keyedValue.substr(2).slice(0, -2)
  if (formKey.includes('||')) {
    const formKeyArray = formKey.split('||')
    if (formKey && form[formKeyArray[0]]) {
      const cleanValue = form[formKeyArray[0]]?.value ? form[formKeyArray[0]].value : formKeyArray[1]
      return label.replace(keyedValue, cleanValue)
    }
  } else {
    return keyedValue
  }
}
