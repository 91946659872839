import { type MessagesError } from '@legal/shared/data/types/MessagesError'
import { type Validator } from '../validator.types'
import valid from 'card-validator'
import { validatorString } from '../validatorString'

const MIN_CHARS = 12
const MAX_CHARS = 19

export function cardNumberValidator(value: string, messagesError: MessagesError): Validator {
  const validators = [
    {
      isValid: validatorString.isEmptyValue(value),
      message: messagesError.default ?? ''
    },
    {
      isValid: validatorString.isNumber(value),
      message: messagesError.code02 ?? ''
    },
    {
      isValid: validatorString.containNumberOfCharsOrMore(value, MIN_CHARS),
      message: `${messagesError.code03 ?? ''} ${MIN_CHARS}.`
    },
    {
      isValid: validatorString.containNumberOfCharsOrLess(value, MAX_CHARS),
      message: `${messagesError.code04 ?? ''} ${MAX_CHARS}.`
    },
    {
      isValid: valid.number(value).isValid,
      message: messagesError.code05 ?? ''
    }
  ].find(({ isValid }) => !isValid)

  return validators ?? { isValid: true, message: messagesError.success ?? '' }
}
