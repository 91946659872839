/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import { navigate } from 'gatsby'

import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { getApplicationStatusUseCase } from '@legal/application'
import noDocs from '../../../assets/images/png/ld-illust-empty-state-dash.png'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useQueryCreateDocumentPdf } from '@legal/shared/data/graphql/queries/useQueryCreateDocumentPdf'
import './LoadingPdfGeneration.scss'

export const LoadingPdfGeneration: React.FC = (props) => {
  const dataCreateDocumentPdf = useQueryCreateDocumentPdf()

  const { TARGET_ADDRESS } = useConfigs()
  const [attempts, setAttempts] = React.useState(0)
  const [applicationStatus, setApplicationStatus] = React.useState<string>()
  const maxAttempts = 15
  const {
    applicationCookie,
    clearApplicationCookie,
    setApplicationCookieIdToDownload,
    setApplicationCookieIsAutomaticallyDownload
  } = useApplicationCookie()
  const applicationId = applicationCookie?.id ?? ''
  const subscriptionCookie = new SubscriptionCookie()

  const getApplicationStatusError = (error: any): void => {
    if (error?.status === 401) {
      setCookiePolicyFunc()
      window.location = TARGET_ADDRESS
    }
    setApplicationStatus(undefined)
  }

  const getApplicationStatusSuccess = (status: string): void => {
    setApplicationStatus(status)
    if (applicationStatus !== 'pdf_generated' && applicationStatus !== 'downloaded') return
    clearApplicationCookie()
    subscriptionCookie.clear()
    setApplicationCookieIdToDownload(applicationId)
    setApplicationCookieIsAutomaticallyDownload(true)
    const navigateMessage =
      typeof window !== 'undefined' && window.localStorage.getItem('isEditing') === 'editing' ?
        {
          type: dataCreateDocumentPdf.toastDocumentEditing?.type,
          title: dataCreateDocumentPdf.toastDocumentEditing?.title,
          text: dataCreateDocumentPdf.toastDocumentEditing?.text
        }
      : {
          type: dataCreateDocumentPdf.toastDocumentCreated?.type,
          title: dataCreateDocumentPdf.toastDocumentCreated?.title,
          text: dataCreateDocumentPdf.toastDocumentCreated?.text
        }
    navigate('/my-account/my-documents/', {
      state: {
        toast: {
          type: navigateMessage.type,
          title: navigateMessage.title,
          text: navigateMessage.text
        }
      }
    })
  }

  const getApplicationStatus = async (): Promise<void> => {
    await getApplicationStatusUseCase({
      applicationId,
      successCallback: getApplicationStatusSuccess,
      errorCallback: getApplicationStatusError
    })
  }

  React.useEffect(() => {
    if (attempts < maxAttempts && applicationId) {
      setTimeout(() => {
        getApplicationStatus()
        setAttempts(attempts + 1)
      }, 2000)
    } else {
      const navigateMessage =
        typeof window !== 'undefined' && window.localStorage.getItem('isEditing') === 'editing' ?
          {
            type: dataCreateDocumentPdf.toastDocumentError?.type,
            title: dataCreateDocumentPdf.toastDocumentError?.title,
            text: dataCreateDocumentPdf.toastDocumentError?.text
          }
        : {
            type: dataCreateDocumentPdf.toastDocumentNotBeenCreated?.type,
            title: dataCreateDocumentPdf.toastDocumentNotBeenCreated?.title,
            text: dataCreateDocumentPdf.toastDocumentNotBeenCreated?.text
          }
      navigate('/my-account/my-documents/', {
        state: {
          toast: {
            type: navigateMessage.type,
            title: navigateMessage.title,
            text: navigateMessage.text
          }
        }
      })
    }
  }, [attempts, applicationStatus])

  return (
    <div className={'wait-active-pdf-container'}>
      <div className={'wait-active-pdf-card'}>
        <img src={noDocs} alt='' />
        <div className={'wait-active-pdf-card-text'}>
          <h3>{dataCreateDocumentPdf.title}</h3>
          <p>{dataCreateDocumentPdf.text}</p>
        </div>
        <Spinner big className={'spinner--primary'} />
      </div>
    </div>
  )
}
