import { graphql, useStaticQuery } from 'gatsby'

export const useQueryMobilePane = (): Queries.STRAPI_MOBILE_PANE => {
  const { strapiMobilePane } = useStaticQuery(graphql`
    query {
      strapiMobilePane {
        menu
        text
        close
        labelCreateFreeAccount
        labelGoToDashboard
        labelLogIn
      }
    }
  `)

  return strapiMobilePane
}
