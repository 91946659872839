/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { type Application } from '../domain'
import { ApplicationRepositoryApi } from '../infra'

export async function findApplicationByUuidUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (application: Application) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): Promise<void> {
  await ApplicationRepositoryApi.findById(applicationId)
    .then((application: Application) => {
      logInfo(`FindApplicationByUuidUseCase applicationId: ${applicationId}`)
      successCallback(application)
    })
    .catch((error: Error | undefined) => {
      logError(`FindApplicationByUuidUseCase applicationId: ${applicationId}`, undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
