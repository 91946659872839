/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import classNames from 'classnames'

import { type Feedback8814Props } from './Feedback8814Props'
import checkIcon from '../../../assets/images/svg/done_24px_outlined.svg'
import informationIcon from '../../../assets/images/svg/info-error_24px_outlined.svg'
import './Feedback8814.scss'

const themes = {
  error: {
    className: 'feedback8814--error',
    icon: informationIcon
  },
  success: {
    className: 'feedback8814--success',
    icon: checkIcon
  }
}

export const Feedback8814: React.FC<Feedback8814Props> = ({
  theme = 'success',
  title,
  text,
  subText,
  button,
  cancel,
  large,
  fluid,
  imgSrc,
  children
}) => {
  const currentTheme = themes[theme]

  if (Object.keys(currentTheme).length === 0 && !imgSrc) {
    return null
  }

  const renderImageOrIcon = (): React.JSX.Element => {
    if (imgSrc) {
      return (
        <div id='feedback8814__image' className='feedback8814__image'>
          <img className='feedback8814__image_img' src={imgSrc} alt='' />
        </div>
      )
    }
    return (
      <div id='feedback8814__icon' className='feedback8814__icon'>
        <img className='feedback8814__icon__img' src={currentTheme.icon} alt='' />
      </div>
    )
  }

  const renderButtons = (): React.JSX.Element | null => {
    const buttons = []
    if (button?.onClick) {
      buttons.push(<Button key='feedback8814-accept-button' {...button} />)
    }
    if (cancel?.onClick) {
      buttons.push(
        <a key='feedback8814-cancel-button' className='feedback8814__cancel__a' onClick={cancel.onClick}>
          {cancel.label ? cancel.label : 'Cancel'}
        </a>
      )
    }
    if (buttons.length === 0) {
      return null
    }
    return <div className='feedback8814__buttons'>{buttons}</div>
  }

  const classes = classNames('feedback8814', {
    'feedback8814--large': large,
    'feedback8814--fluid': fluid,
    [currentTheme.className]: !imgSrc
  })

  return (
    <div className={classes}>
      <div className='feedback8814__main'>
        {renderImageOrIcon()}
        {title && <p className='feedback8814__title sans-serif --big'>{title}</p>}
        {text && <p className={`feedback8814__text ${!imgSrc ? 'feedback8814__text--grey' : ''}`}>{text}</p>}
        {subText && <p className={'feedback8814__subText'}>{subText}</p>}
        {children}
      </div>
      {renderButtons()}
    </div>
  )
}
