/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import { InfoBox } from '@npm_leadtech/legal-lib-components/InfoBox'
import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import {
  cardCVValidator,
  cardHolderNameValidator,
  cardNumberValidator,
  cvvSplitMapper,
  expiraionDateValidator
} from '@legal/shared/utils/paymentFieldsValidators'
import { type MessagesError } from '@legal/shared/data/types/MessagesError'
import { Modal } from '../../layout'
import { TextInput } from '../../form'
import { type UpdateCardModalProps } from './UpdateCardModalProps'
import { type Validator } from '@legal/shared/utils/validator.types'
import { changeCreditCardUseCase } from '@legal/creditCard'
import { validatorString } from '@legal/shared/utils/validatorString'
import './UpdateCardModal.scss'

const insertAutoSlash = (value: string): string => {
  if (value && /^\d{3,}$/.test(value)) {
    const matches = value.match(/.{1,2}/g)
    if (matches?.length) {
      return matches.join('/')
    }
  }
  return value
}

export const UpdateCardModal: React.FC<UpdateCardModalProps> = ({
  customClass,
  closeFunction,
  onUpdateCard,
  dataTexts
}) => {
  const errorMessagesCardHolderName = dataTexts?.cardHolderName?.errorMessages?.internal.content
  const errorMessagesCardHolderNameJson: { default: string; code01: string } =
    errorMessagesCardHolderName && JSON.parse(errorMessagesCardHolderName)

  const errorMessagesCardNumber = dataTexts?.cardNumber?.errorMessages?.internal.content
  const errorMessagesCardNumberJson: {
    default: string
    code01: string
    code02: string
    code03: string
    code04: string
    code05: string
  } = errorMessagesCardNumber && JSON.parse(errorMessagesCardNumber)

  const errorMessagesExpirationDate = dataTexts?.expirationDate?.errorMessages?.internal.content
  const errorMessagesExpirationDateJson: {
    default: string
    code01: string
    code02: string
    code03: string
  } = errorMessagesExpirationDate && JSON.parse(errorMessagesExpirationDate)

  const errorMessagesCvv = dataTexts?.cvv?.errorMessages?.internal.content
  const errorMessagesCvvJson: { default: string; code01: string } = errorMessagesCvv && JSON.parse(errorMessagesCvv)

  const [loadingUpdateCard, setLoadingUpdateCard] = React.useState<boolean>(false)
  const [modalState, setModalState] = React.useState<'form' | 'error'>('form')
  const [form, setForm] = React.useState({
    config: {
      validate: true,
      name: 'config',
      error: false
    },
    card_holder: {
      value: '',
      validate: true,
      name: 'card_holder',
      error: errorMessagesCardHolderNameJson.code01 ?? ''
    },
    card_number: {
      value: '',
      validate: true,
      name: 'card_number',
      error: errorMessagesCardNumberJson.code01 ?? ''
    },
    card_expiration: {
      value: '',
      validate: true,
      name: 'card_expiration',
      error: errorMessagesExpirationDateJson.code02 ?? ''
    },
    card_expiration_month: {
      value: '',
      validate: true,
      name: 'card_expiration_month',
      error: errorMessagesExpirationDateJson.code03 ?? ''
    },
    card_expiration_year: {
      value: '',
      validate: true,
      name: 'card_expiration_year',
      error: errorMessagesExpirationDateJson.code03 ?? ''
    },
    card_cvv: {
      value: '',
      validate: true,
      name: 'card_cvv',
      error: errorMessagesCvvJson.code01 ?? ''
    },
    application_priority: {
      value: '0',
      component: 'Check',
      validate: true,
      checked: 0,
      name: 'application_priority',
      error: ''
    }
  })

  const updateField = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formData = { ...form }
    if (event.target.type === 'checkbox') {
      formData[event.target.name].value = event.target.checked
    } else {
      const value = event.target.id !== 'card_cvv' && event.target.value
      formData[event.target.name].value = value
    }
    setForm(formData)

    if (!form.config.validate) {
      formValidator()
    }
  }

  const updateCVV = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formData = { ...form }
    const value = event.target.value
    if (validatorString.containNumberOfCharsOrLess(value, 4)) {
      formData[event.target.name].value = cvvSplitMapper(event.target.value)
      setForm(formData)
      if (!form.config.validate) {
        formValidator()
      }
    }
  }

  const updateExpirationDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formData = { ...form }
    const value = event.target.value
    if (validatorString.containNumberOfCharsOrLess(value, 5)) {
      formData[event.target.name].value = insertAutoSlash(value)
      setForm(formData)
      if (!form.config.validate) {
        formValidator()
      }
    }
  }

  const updateCardNumber = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formData = { ...form }
    const value = event.target.value
    if (validatorString.containNumberOfCharsOrLess(value, 19)) {
      formData[event.target.name].value = value
      setForm(formData)
      if (!form.config.validate) {
        formValidator()
      }
    }
  }

  const formValidator = (): boolean => {
    const formData = { ...form }
    formData.config.validate = true
    let currentError = ''
    const expirationDate = formData.card_expiration.value.split('/')
    formData.card_expiration_month.value = expirationDate[0]
    formData.card_expiration_year.value = expirationDate[1]
    Object.keys(formData).forEach(function (objectKey) {
      const eventMock = {
        target: {
          name: objectKey,
          value: formData[objectKey].value
        }
      }
      if (!fieldValidator(eventMock)) {
        formData.config.validate = false
        if (currentError === '') {
          currentError = objectKey
        }
      }
    })

    setForm(formData)

    return formData.config.validate
  }

  const fieldValidator = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
    const objectKey = event.target.name
    const value = event.target.value
    const formData = { ...form }

    const errorMessagesCardHolderNameData = errorMessagesCardHolderNameJson as unknown as MessagesError
    const errorMessagesCardNumberData = errorMessagesCardNumberJson as unknown as MessagesError
    const errorMessagesCVVData = errorMessagesCvvJson as unknown as MessagesError
    const errorMessagesExpirationDateData = errorMessagesExpirationDateJson as unknown as MessagesError

    const objectKeys: Record<string, Validator> = {
      card_number: cardNumberValidator(value, errorMessagesCardNumberData),
      card_cvv: cardCVValidator(value, formData.card_number.value, errorMessagesCVVData),
      card_expiration: expiraionDateValidator(value, errorMessagesExpirationDateData),
      card_holder: cardHolderNameValidator(value, errorMessagesCardHolderNameData)
    }
    if (objectKeys[objectKey].isValid) {
      formData[objectKey].validate = objectKeys[objectKey].isValid
    }
    if (objectKeys[objectKey] && !objectKeys[objectKey].isValid) {
      formData[objectKey].validate = objectKeys[objectKey].isValid
      formData[objectKey].error = objectKeys[objectKey].message
    }
    setForm(formData)

    return formData[objectKey].validate
  }

  const updateCard = async (): Promise<null | undefined> => {
    const validate = formValidator()
    if (!validate) return null

    const expirationYearFullFormat = new Date()
      .getFullYear()
      .toString()
      .slice(0, 2)
      .concat(form.card_expiration_year.value)

    const expirationMonth = parseInt(form.card_expiration_month.value, 10).toString()
    setLoadingUpdateCard(true)

    await changeCreditCardUseCase({
      request: {
        holder: form.card_holder.value,
        number: form.card_number.value,
        expirationMonth,
        expirationYear: expirationYearFullFormat,
        cvc: form.card_cvv.value
      },
      successCallback: () => {
        closeFunction()
        onUpdateCard()
      },
      errorCallback: () => {
        setModalState('error')
      },
      finallyCallback: () => {
        setLoadingUpdateCard(false)
      }
    })
  }

  const body = (
    <div>
      <div>
        <div className='card-holder-label-div'>
          <label className='card-holder-label'>{dataTexts?.cardHolderName?.label ?? ''}</label>
        </div>
        <TextInput
          title={dataTexts?.cardHolderName?.label ?? ''}
          name='card_holder'
          givenClass='input-full'
          onChange={updateField}
          onBlur={fieldValidator}
          placeholder={dataTexts?.cardHolderName?.placeholder ?? ''}
          value={form.card_holder.value}
          isValidGroup={true}
          errorMessage={form.card_holder.error}
          validate={form.card_holder.validate}
        />
        <TextInput
          title={dataTexts?.cardNumber?.label ?? ''}
          name='card_number'
          maxlength='16'
          label={dataTexts?.cardNumber?.label ?? ''}
          placeholder={dataTexts?.cardNumber?.placeholder ?? ''}
          format='################'
          givenClass='input-full'
          onChange={updateCardNumber}
          onBlur={fieldValidator}
          errorMessage={form.card_number.error}
          value={form.card_number.value}
          validate={form.card_number.validate}
        />
        <div className='m-paymentForm--row'>
          <div className='m-paymentForm--input m-paymentForm--input__expirationDate'>
            <TextInput
              title={dataTexts?.expirationDate?.label ?? ''}
              name='card_expiration'
              label={dataTexts?.expirationDate?.label ?? ''}
              placeholder={dataTexts?.expirationDate?.placeholder ?? ''}
              givenClass='input-full'
              onBlur={fieldValidator}
              onChange={updateExpirationDate}
              errorMessage={form.card_expiration.error}
              value={form.card_expiration.value}
              validate={form.card_expiration.validate}
            />
          </div>

          <div className='m-paymentForm--input m-paymentForm--input__ccv'>
            <TextInput
              title={dataTexts?.cvv?.label ?? ''}
              name='card_cvv'
              label={dataTexts?.cvv?.label ?? ''}
              givenClass='input-full'
              format='####'
              placeholder={dataTexts?.cvv?.placeholder ?? ''}
              onBlur={fieldValidator}
              onChange={updateCVV}
              errorMessage={form.card_cvv.error}
              validate={form.card_cvv.validate}
              value={form.card_cvv.value}
            />
          </div>
        </div>
      </div>
      <InfoBox type='warning' noIcon>
        <p>{dataTexts?.messageWarning ?? ''}</p>
      </InfoBox>
      <div className='modal--update__actions'>
        <Button
          givenClass='modal--update__button'
          color='secondary'
          label={dataTexts?.ctaCancel ?? ''}
          onClick={closeFunction}
          noLink
        />
        <Button disabled={loadingUpdateCard} givenClass='modal--update__button' onClick={updateCard} noLink>
          {loadingUpdateCard ?
            <Spinner className={'spinner--primary'} />
          : (dataTexts?.ctaUpdateCard ?? '')}
        </Button>
      </div>
    </div>
  )

  const errorFeedback = {
    theme: 'error',
    title: dataTexts?.feedBackErrorCardTitle ?? '',
    text: dataTexts?.feedBackErrorCardText ?? '',
    button: {
      label: dataTexts?.feedBackErrorCardCta ?? '',
      onClick: () => {
        setModalState('form')
      },
      noLink: true
    },
    cancel: {
      label: dataTexts?.ctaCancel ?? '',
      onClick: closeFunction
    }
  }

  const FeedbackComponent = <Feedback {...errorFeedback} />

  const formStates = {
    form: body,
    error: FeedbackComponent
  }

  return (
    <Modal
      title={dataTexts?.title ?? ''}
      size='S'
      type='default'
      closeFunction={closeFunction}
      customClass={`update-card-modal ${customClass ?? ''}`}
    >
      {formStates[modalState]}
    </Modal>
  )
}
