/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { PricingCard } from '@npm_leadtech/legal-lib-components/PricingCard'
import React from 'react'

import { type PricingCardsListProps } from './PricingCardsListProps'
import classNames from 'classnames'
import iconCloud from '../../../assets/images/png/icon-cloud@2x.png'
import iconEditing from '../../../assets/images/png/editing@2x.png'
import iconSatisfaction from '../../../assets/images/png/satisfaction@2x.png'
import { isMobileDevice } from '../../../services/utils/isMobileDevice'
import './PricingCardsList.scss'

export const PricingCardsList: React.FC<PricingCardsListProps> = ({
  attributes: { data, content },
  subscriptionSelected,
  selectRadioButton,
  submitForm,
  message
}) => {
  const splitPriceAndCurrency = (displayPrice: string): any[] => {
    let [priceValue, priceCurrency] = displayPrice.split(' ')
    priceCurrency = `USD${priceCurrency ? `/${priceCurrency}` : ''}`
    return [priceValue, priceCurrency]
  }

  const dataToMap = data
  const pricingCardArray = dataToMap.map((subscriptionType) => {
    const cardDescription = subscriptionType.type.config.pricingPage.content
    const cardDisclaimer = subscriptionType.type.config.pricingPage.disclaimer

    const subscriptionPrice = subscriptionType.type.config.pricingPage.displayPrice.value
    const [priceNumber, priceCurrency] = splitPriceAndCurrency(subscriptionPrice)

    return (
      <PricingCard
        key={subscriptionType.id}
        subscriptionId={subscriptionType.id}
        onChangeRadio={selectRadioButton}
        isChecked={subscriptionType.id === subscriptionSelected}
        planName={subscriptionType.type.name}
        price={priceNumber}
        currency={priceCurrency}
        underPricingText={subscriptionType.underPricingText}
        featuresList={cardDescription}
        disclaimer={cardDisclaimer}
      />
    )
  })

  const allBenefitsContent = (
    <ul>
      <li>
        <img src={iconSatisfaction} alt='' />
        <p>
          <strong>Regularly updated</strong> templates verified
        </p>
      </li>
      <li>
        <img src={iconCloud} alt='' />
        <p>
          <strong>Secure cloud storage</strong> lets you edit and print anywhere
        </p>
      </li>
      <li>
        <img src={iconEditing} alt='' />
        <p>
          <strong>Easily create</strong> legal documents in minutes{' '}
        </p>
      </li>
    </ul>
  )

  const submitFormButton = (
    <Button label='Continue' noLink onClick={submitForm} givenClass='pricing-card__button' dataQa={'Continue'} />
  )
  pricingCardArray.splice(
    1,
    0,
    <PricingCard
      key='all-benefits'
      order={isMobileDevice() ? 1 : 0}
      planName='All Benefits'
      message={message}
      cardButton={submitFormButton}
    >
      {allBenefitsContent}
    </PricingCard>
  )

  const classPrincingCardsSection = classNames({
    wrapper: true,
    'wrapper--responsive': true,
    'pricing-cards-section': true
  })

  return (
    <section className={classPrincingCardsSection}>
      <ul className='pricing-cards-list grid'>{pricingCardArray}</ul>
      <div className='pricing-cards-content'>{content}</div>
    </section>
  )
}
