import { type DocumentListMenuSectionProps } from '../../../components/molecules/DocumentListMenuSection'
import { type ProductListMenuProps } from '../../../components/molecules/ProductListMenu'

import { type FeaturedProducts } from '../../../modules/shared/hooks/useAllFeaturedProducts'

export const menuMapper = (menuItems: FeaturedProducts[]): DocumentListMenuSectionProps[] => {
  const categories = Array.from(
    new Set(
      menuItems

        .filter((item) => item.categoryProduct && item.categoryProduct.name && item.categoryProduct.icon)
        .map((item) => item.categoryProduct?.name)
    )
  ).sort((a, b) => {
    if (a && b) {
      return a.localeCompare(b)
    }
    return 0
  })

  const finalMenu: DocumentListMenuSectionProps[] = []

  categories.forEach((categoryName) => {
    const subMenu: ProductListMenuProps[] = []
    let categoryIcon = ''

    menuItems.forEach((item) => {
      if (item.categoryProduct?.name === categoryName) {
        subMenu.push(item)
        categoryIcon = item.categoryProduct?.icon ?? ''
      }
    })

    if (subMenu.length > 0) {
      finalMenu.push({ type: categoryName, icon: categoryIcon, products: subMenu })
    }
  })

  return finalMenu
}
