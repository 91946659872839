/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useDispatchDataAccordionItem, useStateDataAccordionItem } from '@npm_leadtech/legal-lib-components/Accordion'
import { CountDownMessage } from '@npm_leadtech/legal-lib-components/CountDownMessage'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'
import classNames from 'classnames'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, amplitudeSubmitPayment } from '@legal/shared/amplitude'
import { CreateCustomerWithSubscriptionUseCase, GetCustomerUseCase } from '@legal/customer'
import { Loading, LoadingPaymentProcessing, TimeoutModalContent } from '../../molecules'
import {
  type Transaction,
  adyenExecuteTransactionUseCase,
  createTransactionExecuteUseCase,
  getTransactionStatusUseCase
} from '@legal/transaction'
import {
  getAndSetDataLayer,
  pushPaymentFailedFreeTrialToDataLayer,
  pushSubscriptionNameToDataLayer,
  pushTryAgainPaymentToDataLayer
} from '@legal/shared/utils'
import { type PSPGatewayProps } from './PSPGatewayProps'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { PaymentProcessing } from '../PaymentProcessing'
import { type ProvidersCustomMessages } from '../../template'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { navigateToPaymentDetails } from '../../../services/utils/navigateToPaymentDetails'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useCountDown } from '../../../services/hooks/useCountDown'
import { useModal } from '../../../services/hooks/useModal'
import { useTestAB } from 'src/services/hooks/useTestAB'
import './PSPGateway.scss'

import {
  PRICING_DEAL_PLAN_NAMES_TYPE,
  useQueryCommonsText
} from '@legal/shared/data/graphql/queries/useQueryCommonsText'

import { navigateToCreatePassword, toastResponseProps } from 'src/services/utils/navigateToCreatePassword'
import { pricingPlanNamesTransformToJson } from 'src/modules/price'

export const PSPGateway: React.FC<PSPGatewayProps> = ({
  applicationId,
  paymentGatewayData,
  paymentProvider,
  providersCustomMessages,
  pspGatewayData,
  subscriptionTypeId,
  subscriptionTypeName,
  timeoutModal,
  validatorsI18n
}) => {
  const {
    API_CONFIG,
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const strapiCommonsText = useQueryCommonsText()
  const pricingPlanNames = pricingPlanNamesTransformToJson(strapiCommonsText.pricingDealPlanNames)

  const [isPricingTest19858] = useTestAB({ id: '19858', inUrlParam: true, inCookie: true })
  const { state } = useStateDataAccordionItem(0)
  const { dispatch } = useDispatchDataAccordionItem()
  const [paymentForm, setPaymentForm] = React.useState(<Loading />)
  const [isLoading, setIsLoading] = React.useState(false)
  const [transactionStatus, setTransactionStatus] = React.useState('')
  const [paddleContIframe, setPaddleContIframe] = React.useState(0)
  const paymentFormRef = React.useRef<HTMLInputElement>(null)
  const iframeRef = React.useRef<HTMLIFrameElement>(null)
  const paymentCookie = new PaymentCookie()
  const userCookie = new UserCookie()
  const { applicationCookie } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const { closeModal, changeModalState } = useModal()
  const time = useCountDown()

  const [isTestAB19978] = useTestAB({ id: '19978', inUrlParam: true, inCookie: true })

  const getSubscriptionInformation = (subscriptionTypeIdArg: string): void => {
    if (!subscriptionTypeIdArg) {
      location.reload()
    }
  }

  React.useEffect(() => {
    const isMonthlyPlan =
      subscriptionTypeName === pricingPlanNames[PRICING_DEAL_PLAN_NAMES_TYPE.monthly].text && !isPricingTest19858
    if (state.handler === 'success' && !isMonthlyPlan && paymentProvider) {
      getPaymentFormData()
      getSubscriptionInformation(subscriptionTypeId)
    }
    if (isMonthlyPlan && paymentProvider) {
      getPaymentFormData()
      getSubscriptionInformation(subscriptionTypeId)
      dispatch({ type: 'unlocked', accordionItem: 0 })
      dispatch({ type: 'handler', accordionItem: 0, handlerMessage: 'success' })
    }
  }, [state.handler, paymentProvider])

  const getPaymentFormData = (): void => {
    let paymentIframeClass
    switch (paymentProvider?.gateway) {
      case 'worldpay':
        paymentIframeClass = classNames({
          'payment-iframe': true,
          'payment-iframe--userActive': userCookie.token
        })
        break
      case 'paddle':
        paymentIframeClass = classNames({
          'payment-iframe-paddle': true,
          'payment-iframe-paddle--userActive': userCookie.token
        })
        break
      case 'adyen':
        paymentIframeClass = classNames({
          'payment-iframe-adyen': true,
          'payment-iframe-adyen--userActive': userCookie.token
        })
        break
      case 'macropay':
        paymentIframeClass = classNames({
          'payment-iframe-macropay': true,
          'payment-iframe-macropay--userActive': userCookie.token
        })
        break
      default:
        paymentIframeClass = classNames({
          'payment-iframe': true,
          'payment-iframe--userActive': userCookie.token
        })
        break
    }
    const urlToUse = paymentProvider?.forwardUrl?.replace('inFrame=0', 'inFrame=false')
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    setPaymentForm(<iframe ref={iframeRef} className={paymentIframeClass} src={urlToUse} />)
    if (typeof window !== 'undefined') {
      paymentCookie.transactionId = paymentProvider?.transactionId
    }
  }

  React.useEffect(() => {
    if (transactionStatus === 'timeout') {
      paymentCookie.paymentStatus = 'paying'
      changeModalState()
      setPaymentForm(FailedComponent)
    }
  }, [transactionStatus])

  React.useEffect(() => {
    window.addEventListener('message', receiveMessageFromIframe)
    return () => {
      window.removeEventListener('message', receiveMessageFromIframe)
    }
  })

  const handleMessageError = (event): void => {
    setIsLoading(false)
    setErrorComponent(event)
  }

  const scrollToMessageError = (event): void => {
    setIsLoading(false)
  }

  const handleMessage = (event): void => {
    // eslint-disable-next-line no-prototype-builtins
    if (event.data?.hasOwnProperty('height')) {
      // message that was passed from iframe page for paddle
      const message = event.data
      const IFrameRefElement = iframeRef.current as HTMLElement
      IFrameRefElement.style.height = message.height + 'px'
    } else {
      startPayment(event)
    }
  }

  const isStatusError = (data): boolean | string => {
    return data?.status >= 400 || data?.error?.code || false
  }

  const actionsIframes = (gateway, event): void => {
    switch (gateway) {
      case 'paddle':
        paddleActionsIframe(event)
        break
      case 'macropay':
        macropayActionsIframe(event)
        break
      default:
        break
    }
  }

  const paymentActions = (gateway, event): void => {
    if (API_CONFIG.API_PREFIX.startsWith(event.origin)) {
      handleMessage(event)
    }
    actionsIframes(gateway, event)
  }

  const paymentActionsError = (statusError, event): void => {
    if (statusError) {
      handleMessageError(event)
    } else {
      scrollToMessageError(event)
    }
  }

  const paymentCustomMessageError = (customErrorMessage: string): void => {
    setIsLoading(false)
    pushPaymentFailedFreeTrialToDataLayer()
    setErrorMessagePaymentForm(customErrorMessage)
  }

  const createUserToPay = ({ email, eventIframe }): void => {
    if (!applicationCookie?.id || !subscriptionCookie.subscriptionId) return
    CreateCustomerWithSubscriptionUseCase({
      request: {
        email,
        subscriptionId: subscriptionCookie.subscriptionId,
        applicationId: applicationCookie.id
      },
      successCallback: (token) => {
        userCookie.token = token
        userCookie.email = email
        eventIframe.source.postMessage(
          {
            action: 'sendPayment'
          },
          eventIframe.origin
        )
      },
      errorCallback: (error) => {
        setIsLoading(false)
        setErrorComponent(error)
      }
    })
  }

  const getProvidersMessages = (): ProvidersCustomMessages[] => {
    return providersCustomMessages.filter(({ provider }) => paymentProvider?.gateway === provider)
  }

  const getCustomErrorMessage = (
    providersCustomMessage: ProvidersCustomMessages[],
    code: string
  ): string | undefined => {
    if (providersCustomMessage.length === 0) {
      return ''
    }
    const [providerMessages] = providersCustomMessage
    return providerMessages.errors.find((error) => error.code === code)?.message
  }

  const receiveMessageFromIframe = (event): void => {
    if (event.origin.includes('freshchat')) {
      return
    }
    const providersMessages = paymentProvider && getProvidersMessages()

    const customErrorMessage = providersMessages && getCustomErrorMessage(providersMessages, event.data?.error?.code)
    const statusError = isStatusError(event.data)
    const isError = typeof event.data !== 'object'

    if (event.data?.redirect) {
      location.reload()
      return
    }

    if (event.data?.createUser && event.data?.email) {
      setIsLoading(true)
      createUserToPay({ email: event.data.email, eventIframe: event })
    } else if (event.data?.action?.url && event.data?.action?.method) {
      checkStatus()
    } else if (event?.data?.loadingPayment) {
      setIsLoading(true)
    } else if (isError || statusError) {
      customErrorMessage ? paymentCustomMessageError(customErrorMessage) : paymentActionsError(statusError, event)
    } else if (!isError && paymentProvider) {
      paymentActions(paymentProvider.gateway, event)
    }
  }

  const checkStatus = (): void => {
    const intervalID = setInterval(async () => {
      if (paymentCookie.transactionId) {
        await getTransactionStatusUseCase({
          transactionId: paymentCookie.transactionId,
          successCallback: (status) => {
            if (status === 'success') {
              clearInterval(intervalID)
              setIsLoading(false)
              successNavigateToDownload()
            }

            if (status === 'error' || status === 'failed') {
              clearInterval(intervalID)
              setPaymentForm(
                <span>
                  <Feedback
                    button={{
                      label: paymentGatewayData?.feedBackError.btnLabel ?? '',
                      onClick: resetPaymentForm,
                      noLink: true
                    }}
                    title={paymentGatewayData?.feedBackError.title ?? ''}
                    theme='error'
                    text={paymentGatewayData?.feedBackError.text ?? ''}
                  />
                </span>
              )
            }
          }
        })
      }
    }, 3000)
  }

  const paddleActionsIframe = (event): null | undefined => {
    if (event?.data?.hello === 'true') {
      return null
    }
    if (event?.data?.payload?.event !== 'operations') {
      setPaddleContIframe((number) => number + 1)
      if (paddleContIframe === 2 && applicationCookie?.form?.name) {
        amplitudeSubmitPayment({
          formProduct: applicationCookie.form.name,
          subscriptionTypeName,
          version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
        })
      }
    }
  }

  const macropayActionsIframe = (event): void => {
    if (event?.data?.payload?.event !== 'operations' && applicationCookie?.form?.name) {
      amplitudeSubmitPayment({
        formProduct: applicationCookie.form.name,
        subscriptionTypeName,
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      })
    }
  }

  const startPayment = (event): void => {
    setIsLoading(false)
    setPaymentForm(
      <div className='payment-iframe'>
        <span>
          <LoadingPaymentProcessing text={pspGatewayData?.loadingPaymentMessage} />
        </span>
      </div>
    )

    const dataToSend = event.data
    dataToSend.transactionId = paymentCookie.transactionId

    if (dataToSend.transactionStatus && dataToSend.transactionStatus === 'success') {
      setPaymentForm(
        <div className='payment-iframe'>
          <PaymentProcessing
            successNavigateToDownload={successNavigateToDownload}
            paymentError={resetPaymentForm}
            changeStatusToTimeout={changeStatusToTimeout}
            status={dataToSend.transactionStatus}
            paymentGatewayData={paymentGatewayData}
          />
        </div>
      )
    } else if (dataToSend.transactionStatus && dataToSend.transactionStatus !== 'success') {
      setPaymentForm(
        <div className='payment-iframe'>
          <PaymentProcessing
            successNavigateToDownload={successNavigateToDownload}
            paymentError={resetPaymentForm}
            changeStatusToTimeout={changeStatusToTimeout}
            paymentGatewayData={paymentGatewayData}
          />
        </div>
      )
    } else {
      executeTransaction(dataToSend)
    }
  }

  const executeTransaction = async (dataToSend): Promise<void> => {
    if (applicationCookie?.form?.name)
      amplitudeSubmitPayment({
        formProduct: applicationCookie.form.name,
        subscriptionTypeName,
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      })
    const transaction: Transaction = {
      id: dataToSend.transactionId,
      email: dataToSend.email,
      creditCard: {
        holder: dataToSend.card_holder,
        number: dataToSend.card_number,
        expirationMonth: dataToSend.exp_month,
        expirationYear: dataToSend.exp_year,
        cvc: dataToSend.cvc
      }
    }
    if (dataToSend.adyen) {
      await adyenExecuteTransactionUseCase({
        transaction,
        successCallback: createTransactionExecuteSuccessCallback,
        errorCallback: setErrorComponent
      })
      delete dataToSend.adyen
    } else if (!userCookie.token) void executeTransactionNewUser(transaction)
    else
      await createTransactionExecuteUseCase({
        transaction,
        successCallback: createTransactionExecuteSuccessCallback,
        errorCallback: setErrorComponent
      })
  }

  const createCustomerWithSubscriptionSuccess = async (token: string, transaction: Transaction): Promise<void> => {
    userCookie.token = token
    userCookie.email = transaction.email
    await createTransactionExecuteUseCase({
      transaction
    })
    setPaymentForm(
      <div className='payment-iframe'>
        <PaymentProcessing
          successNavigateToDownload={successNavigateToDownload}
          paymentError={resetPaymentForm}
          changeStatusToTimeout={changeStatusToTimeout}
          typePayment={'worldpay'}
          paymentGatewayData={paymentGatewayData}
        />
      </div>
    )
  }

  const executeTransactionNewUser = async (transaction: Transaction): Promise<void> => {
    if (!applicationCookie?.id || !subscriptionCookie.subscriptionId) return
    CreateCustomerWithSubscriptionUseCase({
      request: {
        email: transaction.email,
        subscriptionId: subscriptionCookie.subscriptionId,
        applicationId: applicationCookie.id
      },
      successCallback: (token) => {
        createCustomerWithSubscriptionSuccess(token, transaction)
      },
      errorCallback: (error): void => {
        const response = {
          status: error?._status,
          request: { response: { message: error?._result?.message } }
        }
        setErrorComponent(response)
      }
    })
  }

  const createTransactionExecuteSuccessCallback = (): void => {
    setPaymentForm(
      <div className='payment-iframe'>
        <PaymentProcessing
          successNavigateToDownload={successNavigateToDownload}
          paymentError={resetPaymentForm}
          changeStatusToTimeout={changeStatusToTimeout}
          paymentGatewayData={paymentGatewayData}
          loadingPaymentMessage={pspGatewayData?.loadingPaymentMessage}
        />
      </div>
    )
  }

  const setErrorComponent = (error): void => {
    let errorMessage = validatorsI18n.checkDetailsAndTryAgain
    if (error?.status === 412) {
      if (error?.request?.response?.message?.includes('already exists')) {
        errorMessage = replaceValues(validatorsI18n.canotBeUsedOnSiteName, { SITE_NAME }) ?? ''
      } else {
        errorMessage = validatorsI18n.cardCannotBeUsed
      }
      pushPaymentFailedFreeTrialToDataLayer()
    }

    setErrorMessagePaymentForm(errorMessage)
  }

  const setErrorMessagePaymentForm = (errorMessage: string): void => {
    setPaymentForm(
      <span className='error-container'>
        <Feedback
          button={{
            label: pspGatewayData?.feedBackError.btnLabel ?? '',
            onClick: resetPaymentForm,
            primary: true,
            noLink: true
          }}
          title={pspGatewayData?.feedBackError.title ?? ''}
          theme='error'
          text={errorMessage}
        />
      </span>
    )
  }

  const successNavigateToDownload = (): void => {
    pushSubscriptionNameToDataLayer(subscriptionTypeName)
    paymentCookie.clear()
    userCookie.paymentGateway = paymentProvider?.gateway

    const stateToastSuccess = {
      type: 'success',
      title: pspGatewayData?.toastSuccess.title ?? '',
      text: pspGatewayData?.toastSuccess.text ?? ''
    }

    const stateToastSuccessPayment = {
      type: 'success',
      title: pspGatewayData?.successPaymentToast.title ?? '',
      text: pspGatewayData?.successPaymentToast.text ?? ''
    }

    if (applicationId) {
      getAndSetDataLayer({ applicationId })
      if (isTestAB19978) {
        GetCustomerUseCase({
          successCallback: (customerResponse) => {
            if (customerResponse.isAutoGenerated) {
              navigateToCreatePassword(
                applicationCookie?.form?.urlParams ?? '',
                applicationCookie?.form?.subType ?? '',
                applicationCookie?.id,
                stateToastSuccessPayment
              )
            } else {
              navigateToMyDocuments(stateToastSuccess)
            }
          }
        })
      } else {
        navigateToPaymentDetails(applicationCookie?.form?.urlParams ?? '', applicationCookie?.id, stateToastSuccess)
      }
    } else {
      navigateToMyDocuments(stateToastSuccess)
    }
  }

  const navigateToMyDocuments = (stateToastSuccess: toastResponseProps): void => {
    navigate('/my-account/my-documents/', {
      state: {
        toast: stateToastSuccess
      }
    })
  }

  const resetPaymentForm = (): void => {
    paymentCookie.clear()
    pushTryAgainPaymentToDataLayer()
    location.reload()
  }

  const customCloseModal = (): void => {
    closeModal()
    navigate(window.location.href)
  }

  const FailedComponent = (
    <TimeoutModalContent
      closeFunction={customCloseModal}
      title={timeoutModal.title}
      btnLabel={timeoutModal.btnLabel}
      subtitle={timeoutModal.subtitle}
    />
  )

  const changeStatusToTimeout = (): void => {
    setTransactionStatus('timeout')
  }

  const paymentFormContainer = classNames({
    'm-paymentForm-container': true,
    'left-container': true,
    'm-paymentForm-container--loadingPayment': isLoading
  })

  return (
    <>
      <div className='top-flex-container'>
        <div ref={paymentFormRef} className={paymentFormContainer}>
          {!isLoading && <CountDownMessage text={pspGatewayData?.countDownMessage} time={time} centered={true} />}
          <hr></hr>
          {paymentForm}
          {isLoading && <LoadingPaymentProcessing text={pspGatewayData?.loadingPaymentMessage} />}
        </div>
      </div>
    </>
  )
}
