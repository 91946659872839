import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { formatPrice } from '@legal/shared/utils'

export function SubscriptionDataToSubscription(subscriptionData: SubscriptionData): Subscription {
  return {
    id: subscriptionData.subscriptionId ?? subscriptionData.id,
    code: subscriptionData.code || subscriptionData.price?.code,
    displayPrice: subscriptionData.displayPrice || subscriptionData.price?.displayPrice,
    displayPriceFormated: formatPrice(subscriptionData.displayPrice),
    initialAmount: subscriptionData.initialAmount,
    initialAmountFormated: formatPrice(subscriptionData.initialAmount),
    currency: subscriptionData.currency,
    underPricingText: subscriptionData.underPricingText,
    isSevenDayFreeTrial: subscriptionData.name === '7-Day Free Trial',
    isSevenDayAccess: subscriptionData.type.name === '7-Day Access',
    isBestValue: subscriptionData.name === 'Best Value' || subscriptionData.name === '1 Year Pro Subscription',
    priceValue: subscriptionData.priceValue,
    price: {
      id: subscriptionData.price?.id,
      initialAmount: subscriptionData.price?.initialAmount
    },
    type: {
      name: subscriptionData.type.name,
      isBestValue:
        subscriptionData.type.name === 'Best Value' || subscriptionData.type.name === '1 Year Pro Subscription',
      isSevenDayAccess: subscriptionData.type.name === '7-Day Access',
      config: {
        pricingPage: {
          content: subscriptionData.type.config.pricingPage.content,
          disclaimer: subscriptionData.type.config.pricingPage.disclaimer,
          displayPrice: {
            value: subscriptionData.type.config.pricingPage.displayPrice.value,
            annotation: subscriptionData.type.config.pricingPage.displayPrice.annotation
          }
        },
        paymentPage: {
          displayPrice: {
            value: subscriptionData.type.config.paymentPage.displayPrice.value
          }
        }
      }
    },
    token: subscriptionData.token,
    isUnsubscribed: subscriptionData.status === 'unsubscribed'
  }
}
