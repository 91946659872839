import { type CustomerAuthGoogleDataRequest } from '../dataObject'
import { type CustomerAuthGoogleResponse } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function CustomerAuthGoogle({
  state,
  code,
  applicationId,
  subscriptionId
}: CustomerAuthGoogleDataRequest): Promise<CustomerAuthGoogleResponse> {
  const body = state && code ? { state, code, applicationId, subscriptionId } : undefined
  if (body && !applicationId) delete body.applicationId
  if (body && !subscriptionId) delete body.subscriptionId
  return await post<CustomerAuthGoogleResponse>(ENDPOINTS.CUSTOMER_AUTH_GOOGLE, body)
}
