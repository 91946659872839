/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'

export async function deleteApplicationUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): Promise<void> {
  await ApplicationRepositoryApi.delete(applicationId)
    .then(() => {
      logInfo(`DeleteApplicationUseCase applicationId: ${applicationId}`)
      successCallback()
    })
    .catch((error: Error | undefined) => {
      logError(`DeleteApplicationUseCase applicationId: ${applicationId}`, undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
