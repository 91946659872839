import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import { Link } from 'gatsby'
import React from 'react'

import { ForgotPassword } from '../../molecules'
import { type ForgotPasswordModalProps } from './ForgotPasswordModalProps'
import { replaceValues } from 'src/services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'
import { useQueryForgotPassword } from '../ForgotPasswordTemplate'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'

import './ForgotPasswordModal.scss'

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  togglePasswordModal,
  closePasswordModal,
  noBottomSection = false,
  closeModal
}) => {
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const data = useQuerySharedComponentsFromPaymentPage()
  const forgotPasswordData = useQueryForgotPassword()
  const forgotPasswordModal = React.useRef<HTMLDivElement | null>(null)

  const Form = (
    <ForgotPassword
      forgotPasswordData={forgotPasswordData}
      togglePasswordModal={togglePasswordModal}
      isForgotPasswordModal
    />
  )

  const boxFooter = {
    text: replaceValues(data.forgotPasswordModal?.footerText, { SITE_NAME }) ?? `New to ${SITE_NAME}?`,
    button: {
      label: data.forgotPasswordModal?.footerCta ?? 'Try it now!',
      link: '/register',
      LinkComponent: Link
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event): void => {
    const clickOutOfSelect = forgotPasswordModal.current && !forgotPasswordModal.current.contains(event.target)

    if (clickOutOfSelect) {
      closePasswordModal()
      closeModal()
    }
  }

  const closeModalAndReloadPage = (): void => {
    closePasswordModal()
    closeModal()
  }

  return (
    <div className='forgot-password'>
      <div ref={forgotPasswordModal} className='main-container'>
        <div className='box-form-container'>
          <BoxForm
            title={data.forgotPasswordModal?.title ?? 'Reset your Password'}
            body={Form}
            footer={!noBottomSection ? boxFooter : undefined}
            closeInsideModal
            closeModalAndReloadPage={closeModalAndReloadPage}
          />
        </div>
      </div>
    </div>
  )
}
