import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import './LoadingPaymentProcessing.scss'

export interface LoadingPaymentProcessingProps {
  text?: string
}

export const LoadingPaymentProcessing: React.FC<LoadingPaymentProcessingProps> = ({
  text = 'Payment Processing...'
}) => (
  <div className='page--status'>
    <Spinner className='page--status__spinner spinner--primary' />
    <p>{text}</p>
  </div>
)
