import { TestABCookie } from '../storage/cookies/TestABCookie'
import { getParamUrlValue } from './paramsUrl'

const setTestABCookie = (testABName: string, getValue = getParamUrlValue): void => {
  const testABCookie = new TestABCookie(testABName)
  const testABValue = getValue(testABName)
  if (testABValue) testABCookie.value = testABValue
}

const enableTestABCookie = (testABName: string): void => {
  const testABCookie = new TestABCookie(testABName)
  testABCookie.value = 'true'
}

export { setTestABCookie, enableTestABCookie }
