/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

export const isMobileDevice = (): boolean | undefined => {
  try {
    const addTimeToDate = (msec: number): Date => {
      const exdate = new Date()
      exdate.setTime(exdate.getTime() + msec)
      return exdate
    }

    const getQueryValue = (param: string): string | undefined => {
      if (!param) return

      const querystring = document.location.search
      const queryStringArray = querystring.substring(1).split('&')
      let i = 0
      const length = queryStringArray.length

      for (; i < length; i++) {
        const token = queryStringArray[i]
        const firstPart = token.substring(0, token.indexOf('='))

        if (firstPart === param) {
          return token.substring(token.indexOf('=') + 1, token.length)
        }
      }
    }

    const agent = navigator.userAgent.toLowerCase()

    // configuration object
    const config = {}
    // parameter to pass in the URL to avoid the redirection
    const redirection_param: string = config.redirection_param || 'mobile_redirect'
    // prefix appended to the hostname
    const mobile_prefix = config.mobile_prefix || 'm'
    // new url for the mobile site domain
    const mobile_url = config.mobile_url
    const host = document.location.host
    // value for the parameter passed in the URL to avoid the redirection
    const queryValue = getQueryValue(redirection_param)
    // Compose the mobile hostname considering "mobile_url" or "mobile_prefix" + hostname
    const mobile_host = mobile_url || mobile_prefix + '.' + (/^www\./i.exec(host) ? host.substring(4) : host)
    // Expiry hours for cookie
    const cookie_hours = config.cookie_hours || 1
    // Parameters to determine if the pathname and the querystring need to be kept
    const keep_path = config.keep_path || false
    const keep_query = config.keep_query || false
    // append referrer
    const append_referrer = config.append_referrer || false
    const append_referrer_key = config.append_referrer_key || 'original_referrer'
    let isUAMobile = false
    let isUATablet = false

    // Check if the UA is a mobile one (regexp from http://detectmobilebrowsers.com/ (WURFL))
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        agent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        agent.substr(0, 4)
      )
    ) {
      isUAMobile = true
    }

    // Check if the referrer was a mobile page (probably the user clicked "Go to full site") or in the
    // querystring there is a parameter to avoid the redirection such as "?noredireciton=true"
    // (in that case we need to set a variable in the sessionStorage or in the cookie)

    if (document.referrer.includes(mobile_host) || queryValue === 'false') {
      if (window.sessionStorage) window.sessionStorage.setItem(redirection_param, 'false')
      else {
        document.cookie =
          redirection_param + '=false;expires=' + addTimeToDate(3600 * 1000 * cookie_hours).toUTCString()
      }
    }

    // Check if the sessionStorage contain the parameter
    const isSessionStorage =
      window.sessionStorage ? window.sessionStorage.getItem(redirection_param) === 'false' : false
    // Check if the Cookie has been set up
    const isCookieSet = document.cookie ? document.cookie.includes(redirection_param) : false

    // Check if the device is a Tablet such as iPad, Samsung Tab, Motorola Xoom or Amazon Kindle
    if (/(iPad|SCH-I|xoom|NOOK|silk|kindle|GT-P|touchpad|kindle|sch-t|viewpad|bolt|playbook|Nexus 7)/i.exec(agent)) {
      // Check if the redirection needs to happen for tablets
      isUATablet = !!(config.tablet_redirection === 'true' || !!config.tablet_host)
      isUAMobile = false
    }

    // Check that User Agent is mobile, cookie is not set or value in the sessionStorage not present
    if ((isUATablet || isUAMobile) && !(isCookieSet || isSessionStorage)) {
      // Callback call
      if (config.beforeredirection_callback) {
        if (!config.beforeredirection_callback.call(this)) return
      }

      let path_query = ''

      if (keep_path) path_query += document.location.pathname

      if (keep_query) path_query += document.location.search

      if (append_referrer && document.referrer) {
        if (!path_query.includes('?')) path_query += '?'
        else path_query += '&'

        path_query += append_referrer_key + '=' + encodeURIComponent(document.referrer)
      }

      if (isUATablet || isUAMobile) return true
      return true
    }
    return false
  } catch (e) {
    // console.log(e);
  }
}
