/* eslint-disable no-console */
import React from 'react'

import { GetCustomerBillingByToken } from '@legal/customer'
import { UserCookie } from '../storage/cookies/UserCookie'

export const useBilling = (): {
  loadingBilling: boolean
  hasSubscriptionActive: boolean
} => {
  const [hasSubscriptionActive, setHasSubscriptionActive] = React.useState<boolean>(false)
  const [loadingBilling, setLoadingBilling] = React.useState<boolean>(true)

  React.useEffect(() => {
    const userCookie = new UserCookie()
    const userToken = userCookie.token ?? ''

    if (userToken !== '') {
      GetCustomerBillingByToken.Execute({ userToken })
        .then((response) => {
          const { subscriptions } = response.result
          if (subscriptions.length > 0) {
            const hasSubscriptionActive = subscriptions.some(({ status }) => status === 'active')

            setHasSubscriptionActive(hasSubscriptionActive)
          }
          setLoadingBilling(false)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  return {
    loadingBilling,
    hasSubscriptionActive
  }
}
