import React from 'react'
import sanitizeHtml from 'sanitize-html'

import { type ModalPreviewProps } from './ModalPreviewProps'
import { sanitizeOptions } from '../../../services/constants/formToTemplateConstants'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import './ModalPreview.scss'

export const ModalPreview: React.FC<ModalPreviewProps> = ({
  documentStateOrSubType,
  documentType,
  htmlPreview,
  data
}) => {
  const [templateFontSize, setTemplateFontSize] = React.useState('medium')

  const refPreviewDoc = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (refPreviewDoc.current) {
      refPreviewDoc.current.addEventListener('scroll', scrollPreviewCheck)
    }

    return () => {
      if (refPreviewDoc.current) {
        refPreviewDoc.current.removeEventListener('scroll', scrollPreviewCheck)
      }
    }
  }, [])

  const scrollPreviewCheck = (): void => {
    refPreviewDoc.current?.removeEventListener('scroll', scrollPreviewCheck)

    const eventProps = {
      document_type: documentType,
      document_subtype: documentStateOrSubType
    }
    sendAmplitudeData('scroll_preview_actions', eventProps)
  }

  return (
    <div className={`template-container copying-not-allowed ${templateFontSize} preview`}>
      <div className='template__header'>
        <div className={'sans-serif --extra-small'}>
          {data?.strapiMyDocumentsPage?.documentsSection?.documentPreviewModal?.title}
        </div>
        <div className='template__header__right'>
          <button
            data-qa={'changeSizeBig'}
            className={templateFontSize === 'big' ? 'isActive' : ''}
            onClick={() => {
              setTemplateFontSize('big')
            }}
          >
            <span>A</span>
          </button>
          <button
            data-qa={'changeSizeRegular'}
            className={templateFontSize === 'medium' ? 'isActive' : ''}
            onClick={() => {
              setTemplateFontSize('medium')
            }}
          >
            <span>A</span>
          </button>
          <button
            data-qa={'changeSizeSmall'}
            className={templateFontSize === 'small' ? 'isActive' : ''}
            onClick={() => {
              setTemplateFontSize('small')
            }}
          >
            <span>A</span>
          </button>
        </div>
      </div>
      <div
        ref={refPreviewDoc}
        className={'template serif'}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(htmlPreview, sanitizeOptions)
        }}
      ></div>
    </div>
  )
}
