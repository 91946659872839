import { PricingDealPlanNames } from '@legal/shared/data/graphql/queries/useQueryCommonsText'

interface pricingDealPlanNamesJson {
  [key: string]: PricingDealPlanNames
}

export const pricingPlanNamesTransformToJson = (
  pricingDealPlanNames: PricingDealPlanNames[]
): pricingDealPlanNamesJson => {
  const pricingDealPlanNamesJson = pricingDealPlanNames.reduce((acc: { [key: string]: PricingDealPlanNames }, item) => {
    acc[item.type] = item
    return acc
  }, {})

  return pricingDealPlanNamesJson
}
