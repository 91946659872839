/* eslint-disable no-useless-escape */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import Card from '../../../assets/images/componentsSvg/card.svg'
import { type CardInfoProps } from './CardInfoProps'
import './CardInfo.scss'

export const CardInfo: React.FC<CardInfoProps> = ({
  error,
  cardType,
  cardNumber,
  expirationDate,
  onUpdateCardClick,
  hideUpdateCardButton = false,
  expiresText,
  ctaUpdateCard
}) => {
  const formattedCardNumber = cardNumber.replace(/([\*\d]{4})/g, '$1 ').trim()

  return (
    <div className='card-info'>
      <div className='card-info-container'>
        <div className='card-info__svg-text'>
          <Card />
          <p className='card-info__brand'>{cardType}</p>
        </div>
        <div className='card-info__number-date'>
          <p className='card-info__detail sans-serif'>{formattedCardNumber}</p>
          {
            <p className='card-info__detail sans-serif'>
              {expiresText ?? ''} {expirationDate}
            </p>
          }
        </div>
      </div>
      {!hideUpdateCardButton && (
        <div className='card_info__button-container'>
          <Button
            givenClass='card_info__button-container__button'
            error={error}
            color='secondary'
            label={ctaUpdateCard ?? ''}
            onClick={onUpdateCardClick}
            noLink
            dataQa={'update-card-button'}
          />
        </div>
      )}
    </div>
  )
}
