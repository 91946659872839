import { Document } from '../Document'

export function updateOneDocumentNameByApplicationId(
  uploadedDocuments: Document[],
  applicationId: string,
  name: string
) {
  const updatedDocuments = [...uploadedDocuments]
  const documentIndex = updatedDocuments.findIndex((document) => document.id === applicationId)
  if (documentIndex != -1) updatedDocuments[documentIndex].name = name
  return updatedDocuments
}
