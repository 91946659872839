import { DocumentBlob } from '../../domain'
import { DocumentBlobData } from '../dataObjects/DocumentBlobData'
import { base64ToBlobFile } from '@legal/shared/utils'

const OCTET_STREAM = 'application/octet-stream'

export function documentBlobDataToDocumentBlob(data: DocumentBlobData): DocumentBlob {
  const fileExt = data.name.split('.').pop()?.toLowerCase()
  let mimeType = fileExt === 'pdf' ? 'application/pdf' : data.mime_type
  if (fileExt === 'doc' || fileExt === 'docx') {
    mimeType = 'application/msword'
  }
  const { blob, blobUrl } = base64ToBlobFile(data.content, mimeType)
  const { blob: androidBlob } = base64ToBlobFile(data.content, OCTET_STREAM)
  return {
    fileName: data.name,
    size: data.size,
    mimeType,
    blob,
    blobUrl,
    androidBlob
  }
}
