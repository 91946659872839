/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UpgradeSubscriptionRequest, UpgradeSubscriptionResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { SubscriptionRepositoryApi } from '../infra'

export async function upgradeSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: UpgradeSubscriptionResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: UpgradeSubscriptionRequest
}): Promise<void> {
  await SubscriptionRepositoryApi.upgrade(request)
    .then((response) => {
      logInfo('UpgradeSubscriptionUseCase')
      successCallback(response)
    })
    .catch((error: ApiError) => {
      logError(`UpgradeSubscriptionUseCase ${error.message}`, request)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
