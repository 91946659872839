import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQueryAllDisclaimer = (): Queries.STRAPI_DISCLAIMER[] => {
  const { STRAPI_CONFIG } = useConfigs()
  const { allStrapiDisclaimer } = useStaticQuery(graphql`
    query {
      allStrapiDisclaimer {
        nodes {
          copyrightText {
            data {
              childMarkdownRemark {
                htmlAst
                html
              }
            }
          }
          legalAdviceText {
            data {
              childMarkdownRemark {
                htmlAst
                html
              }
            }
          }
          reference
          text
          domain {
            name
          }
          icon {
            url
          }
          ctaText
          ctaCloseText
        }
      }
    }
  `)

  return allStrapiDisclaimer.nodes?.filter(
    (disclaimer: Queries.STRAPI_DISCLAIMER) => disclaimer.domain?.name === STRAPI_CONFIG.DOMAIN
  )
}
