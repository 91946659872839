import { graphql, useStaticQuery } from 'gatsby'

export const useQueryDocumentActionsPage = (): Queries.STRAPI_DOCUMENT_ACTIONS_PAGE => {
  const { strapiDocumentActionsPage } = useStaticQuery(graphql`
    query {
      strapiDocumentActionsPage {
        backToEditBtnLabel
        downloadBtnLabel
        finishBtnLabel
        newBtnLabel
        printBtnLabel
      }
    }
  `)
  return strapiDocumentActionsPage as Queries.STRAPI_DOCUMENT_ACTIONS_PAGE
}
