import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'
import { MenuItems } from '@npm_leadtech/legal-lib-components/MenuItems'
import React from 'react'

import { type DocumentActionsNewMenuProps } from './DocumentActionsNewMenuProps'
import NewDoc from '../../../assets/images/componentsSvg/newdoc-24-p.svg'
import Upload from '../../../assets/images/componentsSvg/upload-doc.svg'
import { useConfigs } from '@legal/core/configs'
import '../DocumentActionsMenu/DocumentActionsMenu.scss'

export const DocumentActionsNewMenu: React.FC<DocumentActionsNewMenuProps> = ({
  newApplication,
  uploadDocument,
  openMenu,
  setOpenMenu,
  sideMenuData
}) => {
  const { PA_UPLOAD_DOCUMENT_ENABLED } = useConfigs()

  const options = [
    {
      content: sideMenuData.documentsSection.newDocumentButtonText,
      image: <NewDoc />,
      onClick: newApplication
    }
  ]

  if (PA_UPLOAD_DOCUMENT_ENABLED) {
    options.push({
      content: sideMenuData.uploadButtonText,
      image: <Upload />,
      onClick: uploadDocument
    })
  }

  const bottomOverlayBody = <MenuItems className={'overlay-list-my-docs'} list={options} />

  return (
    <>
      {openMenu && (
        <BottomOverlay
          open={openMenu}
          body={bottomOverlayBody}
          onClose={() => {
            setOpenMenu(false)
          }}
        ></BottomOverlay>
      )}
    </>
  )
}
