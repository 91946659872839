import React from 'react'

import { Accordion, AccordionItem } from '../../atoms'
import { type ProductFaqsItem8814Props } from './ProductFaqsItem8814Props'
import './ProductFaqsItem8814.scss'

export const ProductFaqsItem8814: React.FC<ProductFaqsItem8814Props> = ({ faqs, changeActiveItem }) => {
  return (
    <div className='productFaqsItem8814'>
      <Accordion className='faqsAccordion' changeActiveItem={changeActiveItem}>
        {faqs.map((faq, key) => (
          <AccordionItem key={key} title={faq.title} className='faqsAccordion__item'>
            {faq.content}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
