import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { SubscriptionRepositoryApi } from '../infra'
import { type SubscriptionTypeOffer } from '../domain'

export async function getSubscriptionTypeOffersUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: SubscriptionTypeOffer[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
}): Promise<void> {
  await SubscriptionRepositoryApi.getTypeOffers()
    .then((response) => {
      logInfo('GetSubscriptionTypeOffersUseCase')
      successCallback(response)
    })
    .catch((error: ApiError) => {
      logError(`GetSubscriptionTypeOffersUseCase ${error.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
