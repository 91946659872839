import * as REQUESTS from './apiRequests'
import { SubscriptionRepository } from '../domain'

export const SubscriptionRepositoryApi: SubscriptionRepository = {
  async cancel(subscriptionId: string, unsubscribeReason?: string) {
    return await REQUESTS.cancelSubscription(subscriptionId, unsubscribeReason)
  },
  async createForCustomer(subscriptionPriceId: string, applicationId: string) {
    return await REQUESTS.createCustomerSubscription(subscriptionPriceId, applicationId)
  },
  async createNoAuth(request) {
    return await REQUESTS.createSubscriptionNoAuth(request)
  },
  async createPayment(request) {
    return await REQUESTS.createSubscriptionPayment(request)
  },
  async findTypeByCode(tokenRequired: boolean) {
    return await REQUESTS.findSubscriptionTypeByCode(tokenRequired)
  },
  async get(id: string) {
    return await REQUESTS.getSubscription(id)
  },
  async getTypeOffers() {
    return await REQUESTS.getSubscriptionTypeOffers()
  },
  async getTypeUpgrade() {
    return await REQUESTS.getSubscriptionTypeUpgrade()
  },
  async updatePay(subscriptionPriceId: string) {
    return await REQUESTS.subscriptionUpdatePay(subscriptionPriceId)
  },
  async upgrade(request) {
    return await REQUESTS.upgradeSubscription(request)
  }
}
