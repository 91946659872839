import { useQueryAllDisclaimer } from '../data/graphql'

const REFERENCE_ALTERNATIVE = 'disclaimer_alternative'

export const useAllDisclaimer = (
  isActiveDocument?: boolean,
  downloadedDocsButtonActive?: boolean
): Queries.STRAPI_DISCLAIMER | undefined => {
  const disclaimers = useQueryAllDisclaimer()
  const disclaimerFiltered = disclaimers.filter((disclaimer) => {
    const isItemTitle = disclaimer.reference === REFERENCE_ALTERNATIVE
    return !isActiveDocument && downloadedDocsButtonActive ? isItemTitle : !isItemTitle
  })
  if (!disclaimerFiltered.length || !disclaimerFiltered[0].text) return
  return disclaimerFiltered[0]
}
