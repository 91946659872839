import { type CreateSubscriptionPaymentRequest } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { type SubscriptionPaymentInformation } from '../../domain'
import { type SubscriptionPaymentInformationData } from '../dataObjects'
import { post } from '@legal/shared/HttpClient'

export async function createSubscriptionPayment(
  request: CreateSubscriptionPaymentRequest
): Promise<SubscriptionPaymentInformation> {
  const result = await post<SubscriptionPaymentInformationData>(
    ENDPOINTS.SUBSCRIPTION_PAYMENT(request.subscriptionId),
    request,
    true
  )
  return {
    forwardUrl: result.forward_url,
    gateway: result.gateway,
    transactionId: result.transaction_id
  }
}
