import { get } from '@legal/shared/HttpClient'

import { Document } from '../../domain'
import { DocumentsData } from '../dataObjects/DocumentsData'
import { ENDPOINTS } from './Endpoints'
import { GetDocumentsRequest } from '../../application'
import { documentsDataToDocuments } from '../adapters/documentsDataToDocuments'

const LIMIT_FETCH = 500
const OFFSET_FETCH = 0

export async function getDocuments({ orderBy, orderDir }: GetDocumentsRequest): Promise<Document[]> {
  const url = `${ENDPOINTS.DOCUMENTS}?limit=${LIMIT_FETCH}&offset=${OFFSET_FETCH}&orderBy=${orderBy}&orderDir=${orderDir}`
  const result = await get<DocumentsData>(url, true)
  return documentsDataToDocuments(result)
}
