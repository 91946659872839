export const formatUSANumber = (number, addMandatoryDecimals = false): string => {
  if (number.includes('.')) {
    number = number.split('.')
    number =
      number[0].replace(/[^0-9$.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      number[1].replace(/[^0-9$.]/g, '').substr(0, 2)
  } else {
    number = number.replace(/[^0-9$.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (addMandatoryDecimals) number = number + '.00'
  }
  return number
}
