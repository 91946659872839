/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */

import { MULTIVALUES_SEPARATOR } from '../constants/formConstants'
import { newLastStep } from './normalizeFormJson'
import { stepFromField } from './GetStep'

export const newStepStructure = (form, structure, ogForm, noRenderFields = [], testAB8814 = false): any => {
  let tempStructure = { ...structure }
  if (testAB8814 && tempStructure?.steps?.length > 0) {
    tempStructure = activePrintAndDownloadStepRefs(tempStructure, form)
  }

  const allStepNameArray = []
  const newStepsArray = []

  if (tempStructure.steps) newStepsArray.push(tempStructure.steps[0]) // The first step is always pushed in, it does not have dependecies
  let counterSteps = 0

  const checkIfnextStepEmpty = (): any => {
    if (form[newStepsArray[newStepsArray.length - 1].refStep.name] && newStepsArray[newStepsArray.length - 1]) {
      return (
        newStepsArray[newStepsArray.length - 1].refStep.name &&
        form[newStepsArray[newStepsArray.length - 1].refStep.name].value ===
          newStepsArray[newStepsArray.length - 1].refStep.value &&
        !newStepsArray[newStepsArray.length - 1].refStep.nextStep
      )
    }
  }

  if (newStepsArray[newStepsArray.length - 1]) {
    while (newStepsArray[newStepsArray.length - 1].defaultNextStep && counterSteps < 150 && !checkIfnextStepEmpty()) {
      counterSteps++

      const currentStep = newStepsArray[newStepsArray.length - 1]
      let tempStep = {}

      let stepSet = false

      if (currentStep.refSteps.length > 0) {
        // If the current step has references, we enter here
        currentStep.refSteps.forEach((reference) => {
          // We parse all the references for the current step
          let pushChecker = true // By default we assume the reference is true
          for (let i = 1; i <= 4; i++) {
            // Each reference can have up to 4 dependencies values, so we check them
            if (reference[`name${i}`]) {
              // If the dependecie exist then we check it's value
              // Before checking the value, we shall check if the step that contains the reference is rendered at all (if not, we shall check the value of the original form)
              if (isStepRendered(stepFromField(reference[`name${i}`], structure), newStepsArray)) {
                // We check the value in DATO with the value in the FORM
                if (!referenceChecker(reference[`name${i}`], reference[`value${i}`], noRenderFields, form)) {
                  pushChecker = false // Oh my my, if the value doesn't match, we set the pusher a false
                  // What means to push the checker a false? That we should not push the referenced step
                }
              } else if (ogForm && !ogForm[reference[`name${i}`]]?.value === reference[`value${i}`]) {
                pushChecker = false
              } else if (!reference.nextStep) {
                stepSet = true
              }
            }
          }
          if (pushChecker) {
            tempStep = tempStructure.steps.find((step) => reference.nextStep === step.name)
            if (tempStep && !stepSet) {
              newStepsArray.push(tempStep)
              stepSet = true
            }
          }
        })
      }

      // Basically here we check that if a step has been set by the reference loop.
      // Imagine you have a step with a reference, but the condition is not met,
      // You should push de DEFAULT step
      if (!stepSet) {
        tempStep = tempStructure.steps.find((step) => currentStep.defaultNextStep === step.name)
        if (tempStep) newStepsArray.push(tempStep) // That said, if we don't push a referenced step, we shall ALWAYS push the default
      }

      if (newStepsArray[counterSteps]) allStepNameArray.push(newStepsArray[counterSteps].name)
    }
  }

  const stepsDifference = tempStructure.steps ? tempStructure.steps.filter((x) => !newStepsArray.includes(x)) : []

  const newStepStructureObj = {
    steps: newStepsArray
  }

  return {
    newStepStructure: newStepStructureObj,
    allStepNameArray,
    stepsDifference
  }
}

const referenceChecker = (referenceName, referenceValue, noRenderFields, form): any => {
  if (Object.entries(form).length === 0) {
    return false
  }
  const formValue =
    (
      form?.[referenceName]?.component &&
      form[referenceName].component === 'SearchSelect' &&
      form[referenceName].value?.value
    ) ?
      form[referenceName].value.value
    : form[referenceName].value

  const isReferencedFieldRenderedInForm = referenceName && noRenderFields && !noRenderFields.includes(referenceName)
  const isFormValueInReferenceValue = checkReferenceValueAgainstFormValue(referenceValue, formValue)
  return isReferencedFieldRenderedInForm && isFormValueInReferenceValue
}

// Check OR logic in reference value.
export const checkReferenceValueAgainstFormValue = (referenceValue, formValue): boolean => {
  const orValuesArray = referenceValue.split(MULTIVALUES_SEPARATOR)
  let isFormValueInReferenceValue = false
  orValuesArray.forEach((value) => {
    if (value === formValue) isFormValueInReferenceValue = true
  })
  return isFormValueInReferenceValue
}

const isStepRendered = (step, stepArray): any => {
  const simplifiedStepArray = stepArray.map((step) => step.name)
  return simplifiedStepArray.includes(step.name)
}

const activePrintAndDownloadStepRefs = (structure, form): any => {
  const newStructure = { ...structure }

  newStructure.steps.forEach((step, index) => {
    if (form[step.refStep.name]?.value === step.refStep?.value && step.refStep.nextStep === newLastStep.name) {
      if (step.defaultNextStep !== newLastStep.name) {
        step.auxNextStep = step.defaultNextStep
      }
      step.defaultNextStep = newLastStep.name
    } else if (step?.auxNextStep && step.defaultNextStep === newLastStep.name) {
      step.defaultNextStep = step.auxNextStep
    }
  })

  return newStructure
}
