import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Message } from '@npm_leadtech/legal-lib-components/Message'
import { Radio } from '@npm_leadtech/legal-lib-components/Radio'
import React from 'react'

import { type CancelPlanModalProps } from './CancelPlanModalProps'
import { Modal } from '../../layout'
import './CancelPlanModal.scss'

export const CancelPlanModal: React.FC<CancelPlanModalProps> = ({
  closeFunction,
  onSubmit,
  customClass = '',
  texts
}) => {
  const currentModalState = 'initial'
  const [reason, updateReason] = React.useState(texts.reasonsCancelSubscription?.[0]?.value ?? 'unneeded_service')
  const [message, showMessage] = React.useState(false)

  const reasons = texts.reasonsCancelSubscription ?? []

  const onSubscriptionReasonChange = ({ target: { value } }): void => {
    updateReason(value)
    showMessage(false)
  }

  const onContinue = (): void => {
    const isValid = validateReasonForm()

    if (isValid) {
      onSubmit(reason)
    }
  }

  const validateReasonForm = (): boolean => {
    if (!reason) {
      showMessage(true)
      return false
    }

    return true
  }

  const initialState = (
    <React.Fragment key='cancelModal'>
      <div className='modal--cancel__container'>
        <Radio
          name='test'
          value={reason}
          class='--regular'
          items={reasons}
          isValidGroup={true}
          validate={true}
          onChange={onSubscriptionReasonChange}
          regularBare
        />
        {message && <Message type='error' message={texts.messageError ?? ''} />}
      </div>
      <div className='modal--cancel__actions'>
        <Button givenClass='modal--cancel-plan__button' label={texts.ctaContinue ?? ''} onClick={onContinue} noLink />
      </div>
    </React.Fragment>
  )

  const modalStates = {
    initial: {
      title: texts.title ?? '',
      body: initialState
    }
  }

  return (
    <Modal
      title={modalStates[currentModalState].title}
      size='L'
      type='default'
      closeFunction={closeFunction}
      customClass={customClass}
    >
      {modalStates[currentModalState].body}
    </Modal>
  )
}
