import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'

import DocumentWithStain from '../../../assets/images/png/document-with-stain.png'
import { useConfigs } from '@legal/core/configs'
import './NotFoundBody.scss'

export const NotFoundBody: React.FC = ({ data }) => {
  const { TARGET_ADDRESS } = useConfigs()

  return (
    <div className='not-found__body'>
      <img className='not-found__image' src={DocumentWithStain} alt='' />
      <div>
        <h1 className='not-found__title serif --super-hero'>{data.strapiWeb404Page?.title}</h1>
        <p className='not-found__text sans-serif --medium'>
          <RichTextStrapi html={data.strapiWeb404Page?.description?.data?.childMarkdownRemark?.html} />
        </p>
        <Button label='Go to Homepage' givenClass='not-found__button' isExternal link={TARGET_ADDRESS} />
      </div>
    </div>
  )
}
