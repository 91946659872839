/* eslint-disable @typescript-eslint/no-explicit-any */

import { type RemoveAllToasts, useToasts } from 'react-toast-notifications'

export function useToastsCustom(): {
  addToastCustom: (toastObj: any) => void
  removeAllToasts: RemoveAllToasts
} {
  const { addToast, removeAllToasts, toastStack } = useToasts()

  const addToastCustom = (toastObj): void => {
    const toastAlreadyShowing = toastStack.find((toast) => toast.title === toastObj.title)
    if (!toastAlreadyShowing) {
      addToast(null, { ...toastObj })
    }
  }

  return { addToastCustom, removeAllToasts }
}
