import { getValueFromForm } from './getValueFromForm'

const parseLabels = ({ labels, form }): unknown => {
  return labels.map((item) => {
    const label = parseLabel({ label: item.label, form })
    return { ...item, label }
  })
}

const parseLabel = ({ label, form }): unknown => {
  if (label && typeof label === 'string' && label.includes('{{')) {
    const regexpWithCurlyBrackets = /\{{.*?\}}/g
    const resWithCurlyBrackets = label.match(regexpWithCurlyBrackets)

    if (resWithCurlyBrackets) {
      resWithCurlyBrackets.forEach((result) => {
        label = getValueFromForm(result, form, label)
      })
    }
  }
  return label || ''
}

export { parseLabel, parseLabels }
