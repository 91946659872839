/* eslint-disable @typescript-eslint/no-explicit-any */
import * as amplitude from '@amplitude/analytics-browser'

import { getDeviceWindowSize } from '@legal/shared/utils/getDeviceWindowSize'

const STRAPI_LOCALE = process.env.STRAPI_LOCALE ?? ''
const AMPLITUDE_KEY = process.env.AMPLITUDE_KEY ?? ''
let identifyEvent: amplitude.Identify | null = null

export const initAmplitude = (): void => {
  if (AMPLITUDE_KEY === '') return
  const options: amplitude.Types.BrowserOptions = {
    serverZone: 'EU',
    defaultTracking: true
  }
  amplitude.init(AMPLITUDE_KEY, options)
  identifyEvent = new amplitude.Identify()
  const dataDevice = getDeviceWindowSize()
  if (dataDevice) {
    identifyEvent.set('viewportWidth', dataDevice.viewportWidth)
    identifyEvent.set('viewportHeight', dataDevice.viewportHeight)
    identifyEvent.set('viewportDimensions', dataDevice.viewportDimensions)
    identifyEvent.set('deviceType', dataDevice.deviceType)
  }
  amplitude.identify(identifyEvent)
}

export const sendAmplitudeData = (eventType: string, eventProperties: Record<string, any>): void => {
  if (AMPLITUDE_KEY === '') return
  eventProperties = { ...eventProperties, domain: STRAPI_LOCALE }
  amplitude.track(eventType, eventProperties)
}

export const setAmplitudeIncrement = (propName: string, count: number): void => {
  if (AMPLITUDE_KEY === '' || identifyEvent === null) return
  identifyEvent.add(propName, count)
  amplitude.identify(identifyEvent)
}

export const setAmplitudeUserId = (userId: string): void => {
  if (AMPLITUDE_KEY === '') return
  amplitude.setUserId(userId)
}

export const setAmplitudeUserProperties = (propName: string, value: amplitude.Types.ValidPropertyType): void => {
  if (AMPLITUDE_KEY === '' || identifyEvent === null) return
  identifyEvent.set(propName, value)
  amplitude.identify(identifyEvent)
}
