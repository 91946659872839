/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Logo } from '@npm_leadtech/legal-lib-components/Logo'
import React from 'react'

import { type MicroFooterProps } from './MicroFooterProps'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useConfigs } from '@legal/core/configs'
import { useIstestAB8814 } from '../../../services/hooks/useIstestAB8814'
import './MicroFooter.scss'

export const MicroFooter: React.FC<MicroFooterProps> = ({ toggleLogin, toggleRegister, changeUrlRedirect }) => {
  const { TARGET_ADDRESS, STRAPI_CONFIG } = useConfigs()
  const [token, setToken] = React.useState()
  const userCookie = new UserCookie()

  // testAB8814
  const [isTestAB8814] = useIstestAB8814({
    product: getParamUrlValue('product')
  })

  React.useEffect(() => {
    const tokenCookie = userCookie ? userCookie.token : ''
    setToken(tokenCookie)
  })
  //

  const handleClickLogo = (event, url: string): void => {
    // testAB8814
    if (isTestAB8814 && !token) {
      changeUrlRedirect(url)
      toggleRegister()
      event.preventDefault()
    }
  }

  const handleClickItemsMenu = (event): void => {
    // testAB8814
    if (isTestAB8814 && !token) {
      event.preventDefault()

      changeUrlRedirect(event.target.href)
      toggleRegister()
    } else {
      setCookiePolicyFunc()
    }
  }

  return (
    <>
      <footer className={'microfooter'}>
        <section className={'footer__wrapper'}>
          <div className={'footer__company'}>
            <div className='footer__help__mobile'>
              <p className={'sans-serif --small footer-links__title'}>Help</p>
              <ul>
                <li className={'sans-serif --extra-small'}>
                  <a onClick={handleClickItemsMenu} href={`${TARGET_ADDRESS}/faqs/`}>
                    FAQs
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a onClick={handleClickItemsMenu} href={`${TARGET_ADDRESS}/contact-us/`}>
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className='findUs__display'>
                <div className={'footer__wrapper__logo'}>
                  <a
                    className='header__logo__link'
                    onClick={(event) => {
                      handleClickLogo(event, TARGET_ADDRESS)
                    }}
                    href={TARGET_ADDRESS}
                  >
                    <Logo
                      siteName={STRAPI_CONFIG.DOMAIN}
                      className='footer__lawdistrict'
                      width='138'
                      height='24'
                      type='white'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {!token && (
            <div className='footer__help__resources'>
              <ul>
                <li className={'sans-serif --extra-small'}>
                  <a className='login-button' title='Sign Up' onClick={toggleLogin} rel='nofollow'>
                    Sign Up
                  </a>
                </li>
                <li className={'sans-serif --extra-small'}>
                  <a className='login-button' title='Sign In' onClick={toggleRegister} rel='nofollow'>
                    Sign In
                  </a>
                </li>
              </ul>
            </div>
          )}
        </section>
      </footer>
    </>
  )
}
