import React from 'react'

import { IconWeb } from '../../../assets/images/componentsSvg/IconWeb'
import { useConfigs } from '@legal/core/configs'

export const TopBarContent: React.FC = () => {
  const {
    SITE_METADATA: { SITE_PHONE, SITE_SCHEDULE }
  } = useConfigs()
  return (
    <p>
      Contact us whenever you need it! &emsp;
      <strong>
        <IconWeb />
        {SITE_PHONE}
        &emsp;
      </strong>
      {SITE_SCHEDULE}
    </p>
  )
}
