import { navigate } from 'gatsby'

import { PaymentCookie } from '../storage/cookies/PaymentCookie'
import { getAndSetDataLayer } from '@legal/shared/utils'
import { type toastResponseProps } from './navigateToPaymentDetails'

export const navigateToDownload = (
  urlParameters: string,
  applicationId: string,
  toastResponse?: toastResponseProps
): void => {
  getAndSetDataLayer({ applicationId })
  const paymentCookie = new PaymentCookie()
  paymentCookie.clear()

  navigate('/my-account/my-documents/create-document-pdf-paid?' + urlParameters, {
    state: {
      toast: toastResponse,
      automaticallyDownload: true
    }
  })
}
