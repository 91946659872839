import { InfoBox } from '@npm_leadtech/legal-lib-components/InfoBox'
import React from 'react'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'

import { type Card, isCardExpired } from '../../../services/utils/billingHelperFunctions'
import { type PaymentErrorBoxProps } from './PaymentErrorBoxProps'

export const PaymentErrorBox: React.FC<PaymentErrorBoxProps> = ({ card, lastPaymentMessage, text }) => {
  const isCardNotExpiredAndLastPaymentFailed = (card: Card, lastPaymentMessage: string): boolean => {
    return !isCardExpired(card) && lastPaymentMessage === 'change_payment_card'
  }

  if (!isCardNotExpiredAndLastPaymentFailed(card, lastPaymentMessage)) {
    return null
  }

  return (
    <InfoBox type='error'>
      <RichTextStrapi html={text} />
    </InfoBox>
  )
}
