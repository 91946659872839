import { graphql, useStaticQuery } from 'gatsby'

import { useConfigs } from '@legal/core/configs'

export const useQueryAllFeaturedProductsFromStrapi = (): Queries.STRAPI_PRODUCT_PAGEEdge[] => {
  const { STRAPI_CONFIG } = useConfigs()
  const data: Queries.AllStrapiProductFilterIsFeaturedQuery = useStaticQuery(graphql`
    query AllStrapiProductFilterIsFeatured {
      allStrapiProductPage(sort: { linkText: ASC }, filter: { isFeatured: { eq: true } }) {
        edges {
          node {
            category {
              url
            }
            categoryProduct {
              name
              icon {
                url
              }
            }
            isFeatured
            popularOrder
            jumbotron {
              title
            }
            linkText
            slug
            footerPopularDocument
            domain {
              name
            }
          }
        }
      }
    }
  `)

  return data.allStrapiProductPage.edges.filter(
    ({ node }) => node.domain?.name === STRAPI_CONFIG.DOMAIN
  ) as Queries.STRAPI_PRODUCT_PAGEEdge[]
}
