import { type MessagesError } from '@legal/shared/data/types/MessagesError'
import { type Validator } from '../validator.types'
import valid from 'card-validator'
import { validatorString } from '../validatorString'

export const expiraionDateValidator = (value: string, messagesError: MessagesError): Validator => {
  const validators = [
    {
      isValid: validatorString.isEmptyValue(value),
      message: messagesError.default ?? ''
    },
    {
      isValid: valid.expirationDate(value).isValid,
      message: messagesError.code01 ?? ''
    }
  ]

  return validators.find(({ isValid }) => !isValid) ?? { isValid: true, message: messagesError.success ?? '' }
}
