/* eslint-disable @typescript-eslint/no-require-imports */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { CardStatus } from '../../atoms'
import { type PaymentMethodProps } from './PaymentMethodProps'
import './PaymentMethod.scss'

export const PaymentMethod: React.FC<PaymentMethodProps> = ({ creditCard, onDeleteCard, dataTexts }) => {
  const [brandType, setBrandType] = React.useState<string>('')

  React.useEffect(() => {
    try {
      if (creditCard.brandLogo && require(`../../../assets/images/svg/${creditCard.brandLogo}.svg`)) {
        setBrandType(require(`../../../assets/images/svg/${creditCard.brandLogo}.svg`).default)
      }
    } catch (e) {
      if (e) {
        setBrandType(require('../../../assets/images/svg/icon-payment-24-p.svg').default)
      }
    }
  }, [])

  return (
    <div className='payment-container'>
      <div className='payment-card-info'>
        <img src={brandType} alt='' />
        <div className='payment-texts'>
          <p>
            {dataTexts?.endingIn ?? ''} <strong>{creditCard.numberShort}</strong>
          </p>
          <p>
            {dataTexts?.expires ?? ''} {creditCard.expirationMonth}/{creditCard.expirationYearShort}
          </p>
          <p>{creditCard.holder}</p>
        </div>
      </div>
      <div className='payment-card-buttons'>
        <CardStatus status={creditCard.status} isActive={creditCard.isActive} />
        <div className='buttons-container'>
          {creditCard.isDefault && <p className='default'>{dataTexts?.default ?? ''}</p>}
          {creditCard.actions.delete && (
            <a
              className='delete-card-button'
              onClick={() => {
                onDeleteCard(creditCard.id)
              }}
            >
              {dataTexts?.deleteCard ?? ''}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
