import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class CookiePolicyCookie extends AbstractCookie {
  constructor() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    const date5yearsFromNow = new Date(year + 5, month, day)
    super({ cookieName: setEnvironmentPrefixToCookie('cookiePolicy'), expirationDate: date5yearsFromNow })
  }

  get cookiePolicyAccepted(): unknown {
    return this.read('cookiePolicyAccepted')
  }

  set cookiePolicyAccepted(value) {
    this.write({ key: 'cookiePolicyAccepted', data: value })
  }
}
