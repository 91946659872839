import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { logError, logInfo } from '@legal/shared/logger'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UpdateEmailCustomer {
  public static async Execute({
    userToken,
    email
  }: {
    userToken: string
    email: string
  }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    })

    const body = { email }

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .put('customer/update-email-on-checkout', body)
        .then((response) => {
          logInfo('UpdateEmailCustomer')
          resolve(response)
        })
        .catch((error: object | undefined) => {
          logError('UpdateEmailCustomer', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
