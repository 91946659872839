import ReactDOMServer from 'react-dom/server'
import { navigate } from 'gatsby'

import { UserCookie } from '../../storage/cookies/UserCookie'
import { getParamUrlValue } from '../../utils/paramsUrl'
import { updateApplicationDataUseCase } from '@legal/application'

export const transformAndSendHtml = async (
  reactObject: React.ReactNode,
  lastStepData,
  formType,
  formSubType,
  formStateName,
  applicationId?: string
): Promise<void> => {
  const userCookie = new UserCookie()

  const productType = getParamUrlValue('product') ?? null

  const updateApplicationSuccess = (): void => {
    let urlParameters = ''
    urlParameters =
      formType ? urlParameters + `product=${productType ? productType.trim() : formType}` : urlParameters + ''
    if (formStateName && !formSubType) {
      urlParameters = urlParameters + `&type=${formStateName}`
    } else if (!formStateName && formSubType) {
      urlParameters = urlParameters + `&type=${formSubType}`
    }

    const navigateUrl = userCookie.token ? `/pricing/?${urlParameters}` : `/register/?${urlParameters}`
    navigate(navigateUrl)
  }

  const updateApplicationError = (): void => {
    if (userCookie.token) {
      navigate('/my-account/my-documents/', {
        state: {
          toast: {
            type: 'error',
            title: 'Oh no!',
            text: 'Something went wrong and we couldn’t save your changes, try again'
          }
        }
      })
    }
  }

  if (!applicationId) return

  await updateApplicationDataUseCase({
    applicationId,
    request: {
      step: lastStepData.step.slug,
      lastStep: true,
      data: lastStepData.data,
      html: ReactDOMServer.renderToStaticMarkup(reactObject)
    },
    successCallback: updateApplicationSuccess,
    errorCallback: updateApplicationError
  })
}
