import { type SubscriptionPaymentInformation, SubscriptionPaymentMethod } from '../domain'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { type CreateSubscriptionPaymentRequest } from './interfaces'
import { SubscriptionRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { UtmCookie } from '../../../services/storage/cookies/UtmCookie'
import { redirectOnTransactionPaid } from '../../../services/utils/handleErrors'

export async function createSubscriptionPaymentUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: SubscriptionPaymentInformation) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  request: CreateSubscriptionPaymentRequest
}): Promise<void> {
  const userCookie = new UserCookie()
  if (!userCookie.token) return
  let newRequest = addScreenSize(request)
  newRequest = addUtms(newRequest)
  newRequest = addDefaultMethod(newRequest)
  await SubscriptionRepositoryApi.createPayment(newRequest)
    .then((response) => {
      logInfo('CreateSubscriptionPaymentUseCase', newRequest)
      successCallback(response)
    })
    .catch((error: ApiError) => {
      logError(`CreateSubscriptionPaymentUseCase: ${error.message}`, newRequest)
      redirectOnTransactionPaid({
        referrer: newRequest.referrer,
        response: error,
        subscriptionId: newRequest.subscriptionId,
        token: userCookie.token
      })
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}

const addScreenSize = (request: CreateSubscriptionPaymentRequest): CreateSubscriptionPaymentRequest => {
  if (typeof window === 'undefined') return request
  return { ...request, screenWidth: window.screen.width, screenHeight: window.screen.height }
}

const addUtms = (request: CreateSubscriptionPaymentRequest): CreateSubscriptionPaymentRequest => {
  const utmCookies = new UtmCookie()
  return {
    ...request,
    gclid: utmCookies.gclid,
    utm_source: utmCookies.utm_source,
    utm_medium: utmCookies.utm_medium,
    utm_campaign: utmCookies.utm_campaign,
    utm_campaigntype: utmCookies.utm_campaigntype,
    utm_content: utmCookies.utm_content,
    utm_term: utmCookies.utm_term,
    utm_adgroup: utmCookies.utm_adgroup,
    utm_network: utmCookies.utm_network,
    utm_device: utmCookies.utm_device,
    utm_devicemodel: utmCookies.utm_devicemodel,
    utm_matchtype: utmCookies.utm_matchtype,
    utm_loc_physical_ms: utmCookies.utm_loc_physical_ms
  }
}

const addDefaultMethod = (request: CreateSubscriptionPaymentRequest): CreateSubscriptionPaymentRequest => {
  return request.method ? request : { ...request, method: SubscriptionPaymentMethod.CREDIT_CARD }
}
