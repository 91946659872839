export const TypeSelectorValidate = (field): boolean => {
  const fielMinLeght = field.minlength ? field.value.length >= field.minlength : true
  const fieldValue = field.value !== ''
  const fieldValidation = fielMinLeght ? fielMinLeght === fieldValue : false

  switch (field.component) {
    case 'Check':
      return !(field.required && field.value === '0')
    case 'Select':
      return !!field.value
    case 'TextInput':
      return field.value.length > 0
    default:
      return fieldValidation
  }
}
