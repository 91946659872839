/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'

import { useQueryFormPage, useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import CollapseIcon from '../../../assets/images/componentsSvg/expand-documents-24px.svg'
import { type PriceContentProps } from './PriceContentProps'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import { Subscription } from '@legal/subscription'
import { SubscriptionCookie } from 'src/services/storage/cookies/SubscriptionCookie'
import { useApplicationCookie } from '@legal/shared/hooks'
import './PriceContent.scss'

export const PriceContent: React.FC<PriceContentProps> = ({
  formName,
  buttonCta = 'Download',
  callbackBottomOverlay,
  handleContinue,
  currrencySymbol,
  currencyCode,
  subscription,
  setSubscription,
  subscriptionsType,
  subscriptionTypeName,
  multipleSubscriptions,
  loadingDataToPaymentPage,
  hideMobileButtons
}) => {
  const [mobileToggle, setMobileToggle] = React.useState(false)
  const { subscriptions, bestOptionBadge, moreAccessText, lessAccessText } = useQuerySharedComponentsFromPaymentPage()
  const { applicationCookie } = useApplicationCookie()
  const { priceContent } = useQueryFormPage()
  const initialAmount = parseFloat(subscription?.initialAmount).toFixed(2)
  const [integer, decimal] = initialAmount.split('.')
  const lastStepButton =
    applicationCookie?.id && !loadingDataToPaymentPage ?
      <Button noLink disabled={loadingDataToPaymentPage} onClick={handleContinue} dataQa={'completeApplication'}>
        {priceContent?.item}
      </Button>
    : <Button noLink disabled={loadingDataToPaymentPage} onClick={() => {}}>
        <Spinner className={'spinner--primary'} />
      </Button>

  const sortedSubscriptions = subscriptionsType?.sort((a, b) => (a.displayPrice != a.initialAmount ? 1 : -1))

  React.useEffect(() => {
    callbackBottomOverlay?.(lastStepButton)
    sortedSubscriptions && setSubscriptionInfo(sortedSubscriptions[0])
  }, [])

  /* ToDo encontrar una solución limpia y borrar este arreglo */
  React.useEffect(() => {
    const elementToMove = document.querySelector('.price-content__multiple-subscriptions')
    const targetContainer = document.getElementById('bottom-fixed-container')

    if (elementToMove && targetContainer) {
      targetContainer.prepend(elementToMove)
    }
  }, [])

  const setSubscriptionInfo = (subscriptionType: Subscription) => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionTypeId = subscriptionType?.id
    subscriptionCookie.subscriptionTypeName = subscriptionType?.type?.name
    setSubscription(subscriptionType)
  }

  return (
    <div className={`price-content ${hideMobileButtons ? 'hide-mobile-buttons' : ''}`}>
      {multipleSubscriptions ?
        <div className={`price-content__multiple-subscriptions ${mobileToggle ? 'mobile-open' : ''}`}>
          {sortedSubscriptions.map((subscriptionType, index) => (
            <>
              <div
                className={`price-content__multiple-subscriptions__item ${subscription.type.name === subscriptionType.type.name ? 'selected' : ''}`}
                onClick={() => setSubscriptionInfo(subscriptionType)}
                key={subscriptionType}
              >
                {index === 0 && (
                  <div className='price-content__multiple-subscriptions__item__badge'>
                    <img src={bestOptionBadge?.icon?.url} alt=''></img>
                    <RichTextStrapi html={bestOptionBadge?.content?.data?.childMarkdownRemark?.html} />
                  </div>
                )}
                <div className='price-content__multiple-subscriptions__item__name'>
                  <p className='price-content__multiple-subscriptions__item__name__text'>
                    {subscriptionType.displayPrice != subscriptionType.initialAmount ?
                      subscriptionType.type.name
                    : formName}
                  </p>
                  <p className='price-content__multiple-subscriptions__item__name__price'>
                    {currrencySymbol}
                    {parseFloat(subscriptionType.displayPrice).toFixed(2)}
                    <span className='price-content__multiple-subscriptions__item__name__currency'>
                      {currencyCode}
                      {subscriptionType.displayPrice != subscriptionType.initialAmount ? '/mo' : ''}
                    </span>
                  </p>
                </div>
                <div className='price-content__multiple-subscriptions__item__description'>
                  {subscriptions?.find((sub) => sub.name == subscriptionType.type.name)?.description}
                </div>
              </div>
              {index === 0 && (
                <div
                  className='price-content__multiple-subscriptions__toggle'
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  {mobileToggle ? lessAccessText : moreAccessText}
                  <CollapseIcon className='price-content__multiple-subscriptions__toggle_icon' />
                </div>
              )}
            </>
          ))}
        </div>
      : <div className='price-content__price-and-type'>
          <p className='price-content__price-and-type__price'>
            {currrencySymbol}
            {decimal === '00' ? integer : initialAmount}{' '}
            <span className='price-content__price-and-type__price__currency'>{currencyCode}</span>
          </p>
          <p className='price-content__price-and-type__type'>{subscriptionTypeName}</p>
        </div>
      }
      <div className='price-content__buttons'>{lastStepButton}</div>
    </div>
  )
}
