import React, { type FC } from 'react'
import { Button } from '@npm_leadtech/legal-lib-components/Button'

import { type NotSavedCardProps } from './NotSavedCardProps'
import './NotSavedCard.scss'

export const NotSavedCard: FC<NotSavedCardProps> = ({ hideAddCardButton = true, text }) => {
  return (
    <div className='not-saved-card'>
      <div className='not-saved-card__container'>
        <div className='not-saved-card__container__text-container'>
          <p className='not-saved-card__container__text-container__text sans-serif'>{text}</p>
        </div>
        {!hideAddCardButton && (
          <div className='not-saved-card__container__button-container'>
            <Button
              givenClass='not-saved-card__container__button-container__button'
              label='Add card'
              noLink
              dataQa={'update-card-button'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
