/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import CollapseIcon from '../../../assets/images/componentsSvg/expand-documents-24px.svg'
import { DOCS_PER_PAGE } from 'src/modules/document/domain/constants/uploadConstants'
import { type DocumentsGroupProps } from './DocumentsGroupProps'
import { useIsMobile } from '@legal/shared/hooks'
import './DocumentsGroup.scss'

export const DocumentsGroup: React.FC<DocumentsGroupProps> = ({
  title,
  link,
  page,
  total,
  prevPage,
  nextPage,
  children
}) => {
  const [open, setOpen] = React.useState(!location.hash ? true : !!(location.hash && location.hash === `#${link}`))
  const isMobile = useIsMobile(0)

  return (
    <div className='documents-group' id={link ?? null}>
      <div className={`documents-group__title-container`}>
        <div
          className='documents-group__title-collapse'
          onClick={() => {
            setOpen(!open)
          }}
        >
          <p className={`documents-group__title`}>{title}</p>
          <CollapseIcon className={`documents-group__title-icon ${open ? 'open' : 'closed'}`} />
        </div>
        {!isMobile && total > DOCS_PER_PAGE && (
          <div className='documents-group__pagination'>
            <p className='documents-group__pagination-text sans-serif --small'>
              {(page - 1) * DOCS_PER_PAGE + 1}-{page * DOCS_PER_PAGE > total ? total : page * DOCS_PER_PAGE} of {total}
            </p>
            <CollapseIcon
              className='documents-group__pagination-prev'
              onClick={() => {
                prevPage()
              }}
            />
            <CollapseIcon
              className='documents-group__pagination-next'
              onClick={() => {
                nextPage()
              }}
            />
          </div>
        )}
      </div>
      {open && <div className='documents-group__body'>{children}</div>}
    </div>
  )
}
