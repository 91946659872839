export const initState = {
  form: {},
  structure: {},
  currentStructure: {},
  template: {},
  noRenderFields: [],
  currentStep: 1,
  loadedData: false,
  loading: false,
  toggleGroupNames: false
}
