/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'

import { type ProductCategoriesProps } from './ProductCategoriesProps'
import { useConfigs } from '@legal/core/configs'

export const ProductCategories: React.FC<ProductCategoriesProps> = ({ productsCategories, setCookiePolicyFunc }) => {
  const [productCategoryToOpen, setProductCategoryToOpen] = useState<string | null>(null)
  const { TARGET_ADDRESS } = useConfigs()
  const toggleActiveProductCategory = (categoryProduct: string): void => {
    setProductCategoryToOpen(categoryProduct === productCategoryToOpen ? null : categoryProduct)
  }

  return productsCategories.map(({ products, type }) => (
    <div
      key={type}
      className={'sans-serif --extra-small product-categories'}
      onClick={() => {
        toggleActiveProductCategory(type)
      }}
    >
      {type}
      <div className={`icon-arrow expand-icon ${type === productCategoryToOpen ? 'open' : ''}`}></div>
      {type === productCategoryToOpen && (
        <ul className='list-box'>
          {products
            .filter((product) => product.categoryProduct.name === type)
            .map((product) => {
              const productLink = product.categoryUrl ? `${product.categoryUrl}/${product.slug}` : product.slug
              return (
                <li className='list-products' key={product.linkText}>
                  <a title={product.linkText} onClick={setCookiePolicyFunc} href={`${TARGET_ADDRESS}/${productLink}/`}>
                    {product.linkText}
                  </a>
                </li>
              )
            })}
        </ul>
      )}
    </div>
  ))
}
