import React from 'react'

import { type DocumentActionsHeaderProps } from './DocumentActionsHeaderProps'
import './DocumentActionsHeader.scss'

export const DocumentActionsHeader: React.FC<DocumentActionsHeaderProps> = ({ buttons, title }) => {
  return (
    <>
      <div className='actions-header is-mobile'>{title}</div>
      <div className='actions-header not-mobile'>{buttons}</div>
    </>
  )
}
