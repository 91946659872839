/* eslint-disable react-compiler/react-compiler */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'

import { parseLabel, parseLabels } from '../../../services/form/parse'
import { DATE_FORMAT_BY_COUNTRY } from '@legal/shared/data'
import { DialogMenuIcon } from '../../molecules'
import FormComponents from '../FormComponents'
import { FormContext } from '../../../services/utils/contexts'
import IconLocation from '../../../assets/images/svg/icon-location.svg'
import error from '../../../assets/images/svg/info-error_24px_outlined.svg'
import { referenceHandler } from '../../../services/form/referenceHandler'
import { useConfigs } from '@legal/core/configs'

const excludedProducts = ['Eviction Notice', 'Power of Attorney', 'Rental Application', 'Residential Lease Agreement']
const isExcludedProduct = (formTypeId: string): boolean => excludedProducts.some((product) => product === formTypeId)
const isSearchSelectAndIncludesState = (field): boolean =>
  field.component === 'SearchSelect' && field.name.toLowerCase().includes('state')

const getState = (field, formContext, isJonSnow: boolean): void => {
  const IPCountry = localStorage.getItem('IPCountry')
  const country = Array.isArray(field?.items) && field.items?.find(({ value }) => value === IPCountry)
  if (
    isJonSnow ||
    isExcludedProduct(formContext.formTypeId) ||
    !isSearchSelectAndIncludesState(field) ||
    !country ||
    field.value
  )
    return
  field.defaultValue = country
  field.value = country
  formContext.handleChange({ target: { name: field.name, value: country } })
}

const getOtherProps = (field, handleChange, form): any => {
  const otherProps = {}
  switch (field.component) {
    case 'RemoveButton':
      otherProps.onChange = () => {
        const reversEvent = {
          target: {
            value: 'no',
            name: field.items.removeButton.reference
          }
        }
        handleChange(reversEvent)
      }
      break
    case 'DatePicker':
      if (field.relatedDateFormName && form[field.relatedDateFormName]?.value) {
        otherProps.relatedDate = form[field.relatedDateFormName].value
      }
      otherProps.dateFormat = DATE_FORMAT_BY_COUNTRY
      break
    case 'Radio':
      if (field.items) {
        field.items = parseLabels({ labels: field.items, form })
      }
      break
    case 'SearchSelect':
      if (field.value && typeof field.value === 'object') {
        field.value.label = parseLabel({ label: field.value.label, form })
        field.defaultValue = { ...field.value, label: field.value.label }
      } else if (field.value) {
        field.value = parseLabel({ label: field.value, form })
        field.defaultValue = { label: field.value }
      }
      field.items = parseLabels({ labels: field.items, form })
      break
  }
  otherProps.label = parseLabel({ label: field.label, form })
  return otherProps
}

export const Group = ({ group, hashKey }): React.JSX.Element | undefined => {
  const formContext = React.useContext(FormContext)
  const { IS_JONSNOW } = useConfigs()
  const [form] = React.useState(formContext.form)
  let once = false

  const fieldInGroupHasError = group.fields.map((item) => {
    if (!form[item].validate && form[item].required) {
      form[item].validate = false
      return (
        <p className={'form-group__errorMessage sans-serif --small'}>
          <img src={error} alt={form[item].errorMessage} />
          {form[item].errorMessage}
        </p>
      )
    }
    if (!form[item].isValidGroup && !once) {
      once = true
      return (
        <p className={'form-group__errorMessage sans-serif --small'}>
          <img src={error} alt={form[item].errorMessage} />
          {form[item].errorMessage}
        </p>
      )
    }
  })
  let fieldLabel = group.label
  fieldLabel = parseLabel({ label: fieldLabel, form })

  if (referenceHandler(group, form, formContext.structure)) {
    let tooltipString = group.tooltip?.replace(/<[^>]*>?/gm, '')
    tooltipString = parseLabel({ label: tooltipString, form })
    return (
      <div key={group.name} className={`form-group ${group.refField ? '--isReferenced' : ''}`}>
        {fieldLabel && (
          <div className='form-group__label__container'>
            <h4 className={'form-group__label sans-serif --medium dialog-menu-label'}>{fieldLabel}</h4>
            {tooltipString && (
              <DialogMenuIcon
                dialogMenuTip={tooltipString}
                title={fieldLabel}
                onOpenFunc={() => {
                  formContext.setTooltipModalContent({ title: fieldLabel, text: tooltipString })
                }}
                onCloseFunc={() => {
                  formContext.closeTooltipModal()
                }}
                currentStep={hashKey}
                formType={formContext.formType}
                formSubtype={formContext.formSubType}
                isTestAB8814={true}
                formContext={formContext}
              ></DialogMenuIcon>
            )}
          </div>
        )}
        {formContext.toggleGroupNames && <h3>{group.name}</h3>}
        {group.fields.map((item) => {
          const field = form[item]
          const FieldComponent = FormComponents[field.component]
          const otherProps = getOtherProps(field, formContext.handleChange, form)
          getState(field, formContext, IS_JONSNOW)
          return (
            <FieldComponent
              key={field.id}
              onChange={formContext.handleChange}
              onBlur={(e) => formContext.validation(e.target.name)}
              form={form}
              {...field}
              {...otherProps}
            />
          )
        })}
        {fieldInGroupHasError}
        {group.name === 'stateRLAGroup' && (
          <div className='form-group__info'>
            <div className='form-group__info__icon'>
              <img src={IconLocation} alt=''></img>
            </div>
            <div className='form-group__info__test sans-serif --extra-small'>
              {`Your ${formContext.formTypeId} will be customized according to the rules and regulations of your state.`}
            </div>
          </div>
        )}
      </div>
    )
  }
}
