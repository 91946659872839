/* eslint-disable @typescript-eslint/no-unused-vars */

import { saveAs } from 'file-saver'

export const downloadPdfHelper = ({
  base64Pdf,
  applicationName,
  applicationReference,
  tab,
  action,
  isAndroidDevice = false,
  finalFileName = null
}: {
  base64Pdf: string
  applicationName: string
  applicationReference: string
  tab: Window
  action: string
  isAndroidDevice: boolean
}): void => {
  const binary = atob(base64Pdf.replace(/\s/g, ''))
  const applicationNameLowercase = applicationName.toLowerCase()
  const applicationNameSplited = applicationNameLowercase.split(' ')
  const applicationNameFormatted = applicationNameSplited.join('_')
  const pdfTitle = finalFileName ?? applicationNameFormatted + '_' + applicationReference.toLowerCase()
  const format = pdfTitle.split('.')[pdfTitle.split('.').length - 1].toUpperCase()
  const buffer = new ArrayBuffer(binary.length)

  const uint8View = new Uint8Array(buffer)
  for (let i = 0; i < binary.length; i++) {
    uint8View[i] = binary.charCodeAt(i)
  }

  let blob = null

  if (finalFileName) {
    blob = new Blob(
      [uint8View],
      { type: format === 'PDF' ? 'application/pdf' : 'application/msword' },
      `${finalFileName}`
    )
  } else {
    blob = new Blob([uint8View], { type: 'application/pdf' }, `${pdfTitle}.pdf`)
  }

  const downloadDocumentAndroid = (): void => {
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL)
    const a = document.createElement('a')
    a.href = fileURL
    a.target = '_blank'
    a.download = `${pdfTitle}.pdf`
    document.body.appendChild(a)
    a.click()
  }

  try {
    tab.location.href = window.URL.createObjectURL(blob)
    if (isAndroidDevice) {
      downloadDocumentAndroid()
      saveAs(new Blob([uint8View], { type: 'application/octet-stream' }), finalFileName ?? `${pdfTitle}.pdf`)
      return
    }
    if (action === 'print') {
      tab.location.href = window.URL.createObjectURL(blob)
    }
    if (action === 'download') {
      saveAs(new Blob([uint8View], { type: 'application/pdf' }), finalFileName ?? `${pdfTitle}.pdf`)
    }
  } catch (_) {
    saveAs(new Blob([uint8View], { type: 'application/octet-stream' }), finalFileName ?? `${pdfTitle}.pdf`)
  }
}
