import React from 'react'
import { useWindowWidth } from './useWindowWidth'

export const useIsMobile = (timeoutInterval = 150): boolean => {
  const width = useWindowWidth(timeoutInterval)

  const [isMobile, setIsMobile] = React.useState<boolean>((width ?? 0) < 720)

  React.useEffect(() => {
    setIsMobile((width ?? 0) < 720)
  }, [width])

  return isMobile
}
