import React from 'react'

import { type RegisterJumbotronProps } from './RegisterJumbotronProps'
import { useApplicationCookie } from '@legal/shared/hooks'
import './RegisterJumbotron.scss'

export const RegisterJumbotron: React.FC<RegisterJumbotronProps> = ({
  title,
  subtitle,
  alternativeTitle,
  children
}) => {
  const { applicationCookie } = useApplicationCookie()

  return (
    <div className='register-jumbotron'>
      <h1 className='register-jumbotron__title serif --hero'>{applicationCookie?.id ? alternativeTitle : title}</h1>
      <h2 className='register-jumbotron__subtitle sans-serif --big'>{subtitle}</h2>
      {children}
    </div>
  )
}
