import { AbstractCookie } from './AbstractCookie'

export class GoogleRedirectCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: 'googleRedirect' })
  }

  get googleRedirect(): string | undefined {
    return this.read('googleRedirect')
  }

  set googleRedirect(path) {
    this.write({ key: 'googleRedirect', data: path })
  }
}
