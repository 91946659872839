import { isActiveParamUrlOrCookie } from '../isActiveParamUrlOrCookie/isActiveParamUrlOrCookie'

import { VersionTestCookie } from '../../storage/cookies/VersionTestCookie'
import { enableVersionTestWithProductInCookie } from '../enableVersionTestCookie/enableVersionTestWithProductInCookie/enableVersionTestWithProductInCookie'
import { getVersionTestEnableWithProduct } from '../getVersionTestEnable/getVersionTestEnableWithProduct/getVersionTestEnableWithProduct'

const versions = {
  default: 'default',
  messi: 'messi',
  shakira: 'shakira',
  shakira2: 'shakira2'
}

const paramsUrlTestAB = [
  { id: 12415, name: versions.shakira },
  { id: 124152, name: versions.shakira2 }
]

const versionSupertest = ({ dataVersion, product, enableInCookie = true }): string => {
  let versionEnable = versions.default

  const versionId = getVersionTestEnableWithProduct({ dataVersion, product })

  if (versionId) {
    versionEnable = versions[versionId] || versions.default

    if (versionEnable !== versions.default) {
      paramsUrlTestAB.forEach((idTestAB) => {
        if (isActiveParamUrlOrCookie({ testId: idTestAB.id })) {
          versionEnable = versions[idTestAB.name] || versionEnable
        }
      })
    }
    if (enableInCookie) {
      enableVersionTestWithProductInCookie({ product, version: versionEnable, ServiceCookie: VersionTestCookie })
    }
  }

  return versionEnable
}

const isVersionSupertest = ({ dataVersion, product, enableInCookie = true }): boolean => {
  const version = versionSupertest({ dataVersion, product, enableInCookie })

  if (version === versions.default) {
    return false
  } else {
    return true
  }
}
export { versionSupertest, isVersionSupertest }
