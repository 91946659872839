import { logError, logInfo } from '@legal/shared/logger'
import { Document } from '../domain'
import { DocumentRepositoryApi } from '../infra'
import { GetDocumentsRequest } from './interfaces'

export async function getDocumentsUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: GetDocumentsRequest
  successCallback: (documents: Document[]) => void
  errorCallback?: () => void
  finallyCallback?: () => void
}): Promise<void> {
  await DocumentRepositoryApi.getAll(request)
    .then((documents: Document[]) => {
      logInfo('getDocumentsUseCase success', request)
      successCallback(documents)
    })
    .catch((error: unknown) => {
      logError(`getDocumentsUseCase error`, undefined, error as Error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
