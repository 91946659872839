/* eslint-disable @typescript-eslint/no-explicit-any */

export const groupFromField = (field, formStructure): any => {
  let groupObject = {}
  formStructure.steps.forEach((step) => {
    step.groups.forEach((group) => {
      group.fields.forEach((currentField) => {
        if (currentField === field) groupObject = group
      })
    })
  })
  return groupObject
}
