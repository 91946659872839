import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class PaymentCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('payment') })
  }

  get paymentStatus(): unknown {
    return this.read('paymentStatus')
  }

  set paymentStatus(name) {
    this.write({ key: 'paymentStatus', data: name })
  }

  get transactionId(): string | undefined {
    return this.read('transactionId')
  }

  set transactionId(id) {
    this.write({ key: 'transactionId', data: id })
  }

  get paymentStatusPaypal(): unknown {
    return this.read('paymentStatusPaypal')
  }

  set paymentStatusPaypal(name) {
    this.write({ key: 'paymentStatusPaypal', data: name })
  }
}
