/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'

import { type SectionProps } from './SectionProps'
import './Section.scss'

export const Section: React.FC<SectionProps> = ({ title, subtitle, className, optionalComponent, children }) => (
  <div className={`section ${className}`}>
    <div className='section__header'>
      <div>
        <h2 className='section__header__title sans-serif --big'>{title}</h2>
        {subtitle && <h3 className='section__header__subtitle sans-serif --tiny'>{subtitle}</h3>}
      </div>
      {optionalComponent && <div className='section__header__optional-component'>{optionalComponent}</div>}
    </div>
    {children}
  </div>
)
