// testAB8814
import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class ProductCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('product') })
  }

  get product(): unknown {
    return this.read('product')
  }

  set product(name) {
    this.write({ key: 'product', data: name })
  }
}
// fin testAB8814
