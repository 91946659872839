/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Link } from 'gatsby'
import React from 'react'

import { type DesktopPaneProps } from './DesktopPaneProps'
import { LoginContext } from '../../../services/utils/contexts'
import { NavbarItems } from '../NavbarItems'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import phoneIcon from '../../../assets/images/svg/icon-24-px-pohne.svg'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'
import { useQueryDesktopPane } from '@legal/shared/data/graphql'
import './DesktopPane.scss'

export const DesktopPane: React.FC<DesktopPaneProps> = ({
  loginModalOpen = false,
  registerModalOpen = false,
  toggleRegister,
  toggleLogin,
  headerHasNoProducts = false,
  isURLForm = false,
  baseLinkForTestAB,
  tokenActive,
  isTestAB8814 = false,
  changeUrlRedirect,
  hideDesktopNavbar = false,
  hideDashboardButton = false,
  showModalOnLinks = false
}) => {
  const {
    IS_JONSNOW,
    SITE_METADATA: { SITE_PHONE }
  } = useConfigs()
  const [userActions, setUserActions] = React.useState(null)
  const { text, labelCreateFreeAccount, labelGoToDashboard, labelLogIn } = useQueryDesktopPane()
  React.useEffect(() => {
    const userCookie = new UserCookie()
    const tokenCookie = userCookie ? userCookie.token : ''
    const userEmail = userCookie ? userCookie.email?.split('@')[0] : ''

    let tempUserActions
    if (isTestAB8814) {
      tempUserActions =
        tokenCookie ?
          <div className={'navigation-pane__user-menu'}>
            <p className={'navigation-pane__user-name longer-text'}>{replaceValues(text, { USER_EMAIL: userEmail })}</p>
            {!hideDashboardButton && (
              <Button
                color='secondary'
                givenClass='go-to-dashboard-button'
                link={'/my-account/my-documents'}
                LinkComponent={Link}
                label={labelGoToDashboard}
              />
            )}
          </div>
        : <div className={'navigation-pane__user-menu'}>
            <Button givenClass='login-button' color='tertiary' label={labelLogIn} onClick={toggleLogin} noLink />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={labelCreateFreeAccount}
              onClick={toggleRegister}
              noLink
            />
          </div>
    } else {
      tempUserActions =
        tokenCookie ?
          <div className={'navigation-pane__user-menu'}>
            <p className={'navigation-pane__user-name'}>{replaceValues(text, { USER_EMAIL: userEmail })}</p>
            {!hideDashboardButton && (
              <Button
                color='secondary'
                givenClass='go-to-dashboard-button'
                link={'/my-account/my-documents'}
                LinkComponent={Link}
                label={labelGoToDashboard}
              />
            )}
          </div>
        : registerModalOpen === undefined && loginModalOpen === undefined ?
          <div className={'navigation-pane__user-menu'}>
            <Button
              givenClass='login-button'
              color='tertiary'
              label={labelLogIn}
              link={'/login'}
              LinkComponent={Link}
            />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={labelCreateFreeAccount}
              link={'/register'}
              LinkComponent={Link}
            />
          </div>
        : <div className={'navigation-pane__user-menu'}>
            <Button givenClass='login-button' color='tertiary' label={labelLogIn} onClick={toggleLogin} noLink />
            <Button
              givenClass='go-to-free-account-button'
              color='secondary'
              label={labelCreateFreeAccount}
              onClick={toggleRegister}
              noLink
            />
          </div>
    }
    setUserActions(tempUserActions)
  }, [isTestAB8814])

  const buttonConditional = React.useContext(LoginContext)

  return (
    <nav className={`navigation-pane --is-desktop ${headerHasNoProducts ? '--no-products' : ''}`}>
      {!IS_JONSNOW && isTestAB8814 && (
        <div className='header-phone'>
          <img src={phoneIcon} alt='' />
          <p className='header-phone__text'>
            <strong>{SITE_PHONE}</strong>
          </p>
        </div>
      )}
      {!hideDesktopNavbar && (
        <NavbarItems
          headerHasNoProducts={headerHasNoProducts}
          isURLForm={isURLForm}
          baseLinkForTestAB={baseLinkForTestAB}
          tokenActive={tokenActive}
          toggleRegister={toggleRegister}
          changeUrlRedirect={changeUrlRedirect}
          isTestAB8814={isTestAB8814}
          showModalOnLinks={showModalOnLinks}
        />
      )}
      {!hideDesktopNavbar && <>{!buttonConditional && userActions}</>}
    </nav>
  )
}
