import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { type Subscription } from '../domain'
import { SubscriptionRepositoryApi } from '../infra'

export async function getSubscriptionTypeUpgradeUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: Subscription[]) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
}): Promise<void> {
  await SubscriptionRepositoryApi.getTypeUpgrade()
    .then((response) => {
      logInfo('GetSubscriptionTypeUpgradeUseCase')
      successCallback(response)
    })
    .catch((error: unknown) => {
      if ((error as ApiError).message) {
        logError(`GetSubscriptionTypeUpgradeUseCase ${(error as ApiError).message}`)
        errorCallback?.(error as ApiError)
        return
      }
      logError(`GetSubscriptionTypeUpgradeUseCase unknown error`)
      errorCallback?.({ code: 'unknown', message: 'Unknown error' })
    })
    .finally(() => {
      finallyCallback?.()
    })
}
