import { type Validator, type ValidatorsI18n } from '../validator.types'
import { validatorString } from '../validatorString'

const regexEmail = /^([\w][\w+&.-]+)@([\w-]+\.)+([\w-]{2,4})$/
const regexSpecialCharacters = /[!#$%^&*(),?":{}|<>]/

export const validatorEmail = (value: string, validatorsI18n: ValidatorsI18n): Validator => {
  const validators = [
    {
      isValid: validatorString.isEmptyValue(value),
      message: validatorsI18n.enterEmail ?? 'Please enter the email.'
    },
    {
      isValid: !regexSpecialCharacters.test(String(value).toLowerCase()),
      message:
        validatorsI18n.specialCharactersEmail ??
        'This email you entered contains special characters and cannot be used.'
    },
    {
      isValid: regexEmail.test(String(value).toLowerCase()),
      message: validatorsI18n.incorrectFormatEmail ?? 'This email address isn’t right.'
    }
  ]

  return (
    validators.find(({ isValid }) => !isValid) ?? {
      isValid: true,
      message: validatorsI18n.correctEmail ?? 'Correct Email.'
    }
  )
}
