import { graphql, useStaticQuery } from 'gatsby'

export interface NewDocumentModalProps {
  title: string
  text: {
    data: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
  primaryActionText: string
  secondaryActionText: string
  documentActionsTitle: string
  documentActionsTitleMobile: string
}

export const useQueryCreateDocument = (): NewDocumentModalProps => {
  const { strapiMyDocumentsPage } = useStaticQuery(graphql`
    query {
      strapiMyDocumentsPage {
        documentsSection {
          newDocumentModal {
            title
            text {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            primaryActionText
            secondaryActionText
            documentActionsTitle
            documentActionsTitleMobile
          }
        }
      }
    }
  `)

  const newDocumentData = strapiMyDocumentsPage.documentsSection.newDocumentModal

  return newDocumentData
}
