/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { Logo } from '@npm_leadtech/legal-lib-components/Logo'
import React from 'react'
import { TopBar } from '@npm_leadtech/legal-lib-components/TopBar'
import { TopBarMobile } from '@npm_leadtech/legal-lib-components/TopBarMobile'
import classNames from 'classnames'
import { useIntersectionObserver } from '@npm_leadtech/legal-lib-components/useIntersectionObserver'

import { type HeaderProps } from './HeaderProps'
import { NavigationPane } from '../../molecules'
import { TopBarContent } from '../TopBarContent'
import { TopBarMobileContent } from '../TopBarMobileContent'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { useConfigs } from '@legal/core/configs'
import { useIsMobile } from '@legal/shared/hooks'
import '../../../assets/styles/basic/base.scss'
import './Header.scss'

export const Header: React.FC<HeaderProps> = ({
  hideMobileHeader = false,
  headerHasNoProducts = false,
  headerFullWidth = false,
  isScrolling = false,
  headerBackground,
  hideNavbar = false,
  hideDesktopNavbar = false,
  hideDashboardButton = false,
  toggleRegister,
  toggleLogin,
  registerModalOpen = false,
  loginModalOpen = false,
  showContactBar = false,
  changeUrlRedirect,
  isTestAB8814 = false,
  currentStepName,
  logoShowModal = false,
  showModalOnLinks = false,
  mobileHideGoToDashboardButton = false,
  mobileHideDocumentsList = false,
  mobileHideResources = false,
  logoClick,
  showContactBarMobile = false
}) => {
  const { IS_JONSNOW, TARGET_ADDRESS, STRAPI_CONFIG } = useConfigs()
  const [ref, isIntersectingWithElement] = useIntersectionObserver({
    options: {
      threshold: 0.1
    },
    defaultIntersecting: true
  })
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const menuOpen = (value: boolean): void => {
    setIsMenuOpen(value)
  }
  const isMobile = useIsMobile(0)

  const classnames = classNames({
    '--is-hidden': hideMobileHeader,
    '--is-fixed': isScrolling,
    'product-header-background': headerBackground === 'product',
    'transparent-header-background': headerBackground === 'transparent',
    '--is-open': isMenuOpen,
    'form-header-background': headerBackground === 'white',
    'create-password-header-background': headerBackground === 'create-password',
    'payment-details-header-background': headerBackground === 'payment-details',
    // superTest
    'payment-header-background-8814': headerBackground === 'payment8814'
    //
  })

  const [token, setToken] = React.useState('')

  React.useEffect(() => {
    const userCookie = new UserCookie()
    const tokenCookie = userCookie.token ?? ''
    setToken(tokenCookie)
  }, [])

  const [isURLForm, setIsURLForm] = React.useState(false)
  React.useEffect(() => {
    const url = window.location.href
    const urlContainForm = url.includes('form')
    setIsURLForm(urlContainForm)
  }, [])

  const handleClickLogo = (event, url: string): void => {
    // testAB8814
    if (logoClick) {
      logoClick(event)
    } else if (logoShowModal && !token) {
      event.preventDefault()
      changeUrlRedirect?.(url)
      toggleRegister?.()

      if (currentStepName) {
        const eventProps = {
          step_form: currentStepName
        }
        sendAmplitudeData('show_modal', eventProps)
      }
    } else {
      setCookiePolicyFunc()
    }
  }

  const showTopBar = isMobile ? showContactBarMobile : showContactBar

  return (
    <header className={`header ${classnames}`} ref={ref}>
      {!IS_JONSNOW && showTopBar && (
        <TopBar hide={isScrolling}>
          {isMobile ?
            <TopBarMobileContent />
          : <TopBarContent />}
        </TopBar>
      )}
      {!IS_JONSNOW && isMobile && (
        <TopBarMobile isVisible={!isIntersectingWithElement}>
          <TopBarMobileContent />
        </TopBarMobile>
      )}
      <span
        itemScope
        itemType='https://schema.org/Brand'
        className={`${headerFullWidth ? '--no-grid' : 'wrapper'} flex space-between header__inner`}
      >
        <a
          className='header__logo'
          onClick={(event) => {
            handleClickLogo(event, TARGET_ADDRESS)
          }}
          href={TARGET_ADDRESS}
        >
          <Logo siteName={STRAPI_CONFIG.DOMAIN} width='188' height='32' />
        </a>

        {!hideNavbar && (
          <NavigationPane
            loginModalOpen={loginModalOpen}
            registerModalOpen={registerModalOpen}
            toggleRegister={toggleRegister}
            toggleLogin={toggleLogin}
            headerHasNoProducts={headerHasNoProducts}
            isOpen={menuOpen}
            isURLForm={isURLForm}
            tokenActive={token}
            // testAB8814
            isTestAB8814={isTestAB8814}
            changeUrlRedirect={changeUrlRedirect}
            hideDesktopNavbar={hideDesktopNavbar}
            hideDashboardButton={hideDashboardButton}
            showModalOnLinks={showModalOnLinks}
            mobileHideGoToDashboardButton={mobileHideGoToDashboardButton}
            mobileHideDocumentsList={mobileHideDocumentsList}
            mobileHideResources={mobileHideResources}
            //
          />
        )}
      </span>
    </header>
  )
}
