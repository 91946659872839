import React from 'react'

import { type FunctionalityCardsSectionProps } from '@npm_leadtech/legal-lib-components/FunctionalityCardsSection'
import { IconSvgURLWithThemeColor } from '@npm_leadtech/legal-lib-components/IconSvgURLWithThemeColor'

export const cardFunctionalityFromStrapi = ({
  data,
  handlerCreateDocument = () => {}
}: {
  data?: Queries.StrapiCardFunctionalityDataFragment[] | null
  handlerCreateDocument?: () => void
}): FunctionalityCardsSectionProps => {
  if (!data) return { cards: [] }

  const actions = new Map<string, () => void>([
    ['CREATE_DOCUMENT', handlerCreateDocument],
    ['DEFAULT', () => {}]
  ])

  return {
    cards: data.map((card) => ({
      cta: {
        dataQa: `button-data-qa-${card.id}`,
        label: card.ctaLabel ?? '',
        noLink: true,
        onClick: actions.get(card.action ?? 'DEFAULT')
      },
      icon: <IconSvgURLWithThemeColor url={card.icon?.url ?? ''} width='20' height='20' />,
      tags:
        card.tags?.map((tag) => ({
          icon: <IconSvgURLWithThemeColor url={tag?.icon?.url ?? ''} width='16' height='16' />,
          id: tag?.id ?? '',
          text: tag?.text ?? ''
        })) ?? [],
      text: card.text ?? '',
      title: card.title ?? '',
      id: card.id
    }))
  }
}
