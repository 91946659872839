import React from 'react'

export const useWindowWidth = (timeoutInterval = 150): number | undefined => {
  const getWidth = (): number | undefined => {
    if (typeof window !== 'undefined') return window.innerWidth
    return typeof window !== 'undefined' || typeof document !== 'undefined' ?
        (document.documentElement.clientWidth ?? document.body.clientWidth)
      : undefined
  }

  const [width, setWidth] = React.useState(getWidth())

  React.useEffect(() => {
    let timeoutId = null
    const resizeListener = (): void => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setWidth(getWidth())
      }, timeoutInterval)
    }
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}
