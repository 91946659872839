import { DocumentActions, DocumentErrors, uploadMaxFilesize, uploadMimeTypes } from '../domain'
import { logError, logInfo } from '@legal/shared/logger'
import { DocumentRepositoryApi } from '../infra'
import { sendAmplitudeData } from '@legal/shared/amplitude'

export async function uploadDocumentUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: File
  successCallback: () => void
  errorCallback?: (error?: string) => void
  finallyCallback?: () => void
}): Promise<void> {
  if (request.size > uploadMaxFilesize) {
    const eventProps = {
      format_document: request.type,
      weight: request.size
    }
    sendAmplitudeData(DocumentErrors.MAX_WEIGHT, eventProps)
    return errorCallback?.(DocumentErrors.MAX_WEIGHT)
  }

  if (!uploadMimeTypes.includes(request.type)) {
    const eventProps = {
      format_document: request.type
    }
    sendAmplitudeData(DocumentErrors.ERROR_FORMAT, eventProps)
    return errorCallback?.(DocumentErrors.ERROR_FORMAT)
  }

  const formData = new FormData()
  formData.append('file', request as Blob, request.name as string)
  formData.append('name', request.name as string)

  await DocumentRepositoryApi.upload(formData)
    .then(() => {
      logInfo(`uploadDocumentUseCase success ${request.name}`)
      const eventProps = {
        format_document: request.type
      }
      sendAmplitudeData(DocumentActions.UPLOAD, eventProps)
      successCallback()
    })
    .catch((error: unknown) => {
      logError(`uploadDocumentUseCase error ${request.name}`, undefined, error as Error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
