import React from 'react'

import { DesktopPane } from '../DesktopPane'
import { MobilePane } from '../MobilePane'
import { type NavigationPaneProps } from './NavigationPaneProps'

export const NavigationPane: React.FC<NavigationPaneProps> = ({
  loginModalOpen = false,
  registerModalOpen = false,
  toggleRegister,
  toggleLogin,
  headerHasNoProducts = false,
  isOpen,
  isURLForm = false,
  baseLinkForTestAB,
  tokenActive,
  isTestAB8814 = false,
  changeUrlRedirect,
  hideDesktopNavbar = false,
  hideDashboardButton = false,
  showModalOnLinks = false,
  mobileHideGoToDashboardButton = false,
  mobileHideDocumentsList = false,
  mobileHideResources = false
}) => {
  return (
    <>
      <DesktopPane
        loginModalOpen={loginModalOpen}
        registerModalOpen={registerModalOpen}
        toggleRegister={toggleRegister}
        toggleLogin={toggleLogin}
        headerHasNoProducts={headerHasNoProducts}
        isURLForm={isURLForm}
        baseLinkForTestAB={baseLinkForTestAB}
        tokenActive={tokenActive}
        isTestAB8814={isTestAB8814}
        changeUrlRedirect={changeUrlRedirect}
        hideDesktopNavbar={hideDesktopNavbar}
        hideDashboardButton={hideDashboardButton}
        showModalOnLinks={showModalOnLinks}
        //
      />
      {!hideDesktopNavbar && (
        <MobilePane
          loginModalOpen={loginModalOpen}
          registerModalOpen={registerModalOpen}
          toggleRegister={toggleRegister}
          toggleLogin={toggleLogin}
          isOpen={isOpen}
          headerHasNoProducts={headerHasNoProducts}
          isURLForm={isURLForm}
          baseLinkForTestAB={baseLinkForTestAB}
          tokenActive={tokenActive}
          isTestAB8814={isTestAB8814}
          changeUrlRedirect={changeUrlRedirect}
          mobileHideGoToDashboardButton={mobileHideGoToDashboardButton}
          mobileHideDocumentsList={mobileHideDocumentsList}
          mobileHideResources={mobileHideResources}
          //
        />
      )}
    </>
  )
}
