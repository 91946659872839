import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'
import { type UpdateApplicationDataRequest } from './interfaces'

export async function updateApplicationDataUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId,
  request
}: {
  successCallback?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
  request: UpdateApplicationDataRequest
}): Promise<void> {
  await ApplicationRepositoryApi.updateData(applicationId, request)
    .then(() => {
      logInfo(`UpdateApplicationDataUseCase applicationId: ${applicationId}`, request)
      successCallback?.()
    })
    .catch((error: Error | undefined) => {
      logError(`UpdateApplicationDataUseCase applicationId: ${applicationId}`, request, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
