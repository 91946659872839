/* eslint-disable @typescript-eslint/no-explicit-any */

const checkFieldRef = (field, form): boolean => {
  let validation = true
  const ref = form[field].ref
  if (ref) {
    validation = form[ref.name].value === ref.value
    if (typeof ref.value === 'boolean') {
      validation = form[ref.name].value !== ''
    }
  }
  let emptyField = false
  if (!form[field].required) {
    emptyField = form[field].value === ''
  }
  return validation ? validation !== emptyField : false
}

export const getUsedFields = (step, form): any[] => {
  const fields = []
  const data = form
  let checkGroup = true
  step.groups.forEach((group) => {
    if (group.ref) {
      const ref = group.ref
      checkGroup = data[ref.name].value === ref.value
    }
    if (checkGroup) {
      group.fields.forEach((field) => {
        const index = !fields.includes(field)
        const checkField = checkFieldRef(field, form)
        if (index && checkField) {
          fields.push(field)
        }
      })
    } else {
      checkGroup = true
    }
  })
  return fields
}
