/* eslint-disable react-compiler/react-compiler */

/* eslint-disable no-console */
import React from 'react'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { JumbotronSimplified, PricingCardsList } from '../../organisms'
import {
  type Subscription,
  createCustomerSubscriptionUseCase,
  createSubscriptionNoAuthUseCase,
  getSubscriptionTypeByCodeUseCase
} from '@legal/subscription'
import { type ApiError } from '@legal/shared/HttpClient'
import { Loading } from '../../molecules'
import { type PricingComponentProps } from './PricingComponentProps'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { stringSlugify } from 'src/services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'

export const PricingComponent: React.FC<PricingComponentProps> = ({ jumbotronProps }) => {
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const [subscriptionsType, setSubscriptionsType] = React.useState<Subscription[]>([])
  const [loading, updateLoading] = React.useState<boolean>(true)
  const [subscriptionSelected, setSubscriptionSelected] = React.useState('')
  const [message, updateMessage] = React.useState({
    type: '',
    message: ''
  })
  const { applicationCookie, clearApplicationCookie } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const referrerCookie = new ReferrerCookie()
  const userCookie = new UserCookie()

  React.useEffect(() => {
    if (!applicationCookie?.id) {
      clearApplicationCookie()
      subscriptionCookie.clear()
      userCookie.clear()
      window.location = '/'
    }
  }, [])

  const cardsList = {
    data: subscriptionsType,
    content: undefined
  }

  const selectRadioButton = (data): void => {
    if (data?.target.value) {
      setSubscriptionSelected(data.target.value)
    }
  }

  const getUrlParams = (subscriptionSelectedIdParam): string | undefined => {
    const formProduct = getParamUrlValue('product')
    const formType = getParamUrlValue('type')
    let urlParametersTracking = ''

    urlParametersTracking = formProduct ? urlParametersTracking + `product=${formProduct}` : urlParametersTracking + ''

    urlParametersTracking = formType ? urlParametersTracking + `&type=${formType}` : urlParametersTracking + ''
    if (subscriptionSelectedIdParam && subscriptionsType.length > 0) {
      const selectedSubscriptionPlanName = subscriptionsType
        .find((subscription) => subscription.id === subscriptionSelectedIdParam)
        .type.name.toLowerCase()
        .split(' ')
        .join('-')
      return urlParametersTracking + `&subs-type=${selectedSubscriptionPlanName}`
    }
  }

  const createCustomerSubscription = async ({
    subscriptionPriceId,
    applicationId,
    tempmessage,
    planSelected
  }: {
    subscriptionPriceId: string
    applicationId?: string
    tempmessage: { type: string; message: string }
    planSelected: string
  }): Promise<void> => {
    if (!applicationId) return
    await createCustomerSubscriptionUseCase({
      request: {
        subscriptionPriceId,
        applicationId
      },
      successCallback: (subscriptionId) => {
        responseSuccessCreateSubscription({
          subscriptionId,
          subscriptionPriceId,
          tempmessage,
          planSelected,
          subscriptionSelected
        })
      },
      errorCallback: (error) => {
        responseFailedCreateSubscription(error, tempmessage)
      }
    })
  }

  const submitForm = async (planSelected): Promise<void> => {
    const data = {
      subscriptionPriceId: planSelected ?? subscriptionSelected,
      applicationId: applicationCookie?.id
    }

    subscriptionCookie.subscriptionTypeId = data.subscriptionPriceId
    const tempmessage = { ...message }

    const nameOfSubscriptionSelected = subscriptionsType.filter((type) => {
      return type.id === data.subscriptionPriceId
    })
    const initialDocumentType = applicationCookie?.form?.name
    const eventProps = {
      pricing_plan: nameOfSubscriptionSelected[0]?.type.name ?? null,
      initial_document_type: stringSlugify(initialDocumentType) ?? null,
      source: referrerCookie.getReferrer(),
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('select_pricing', eventProps)

    if (userCookie.token) {
      await createCustomerSubscription({
        subscriptionPriceId: data.subscriptionPriceId,
        applicationId: data.applicationId,
        tempmessage,
        planSelected
      })
    } else {
      if (!data.applicationId) return
      await createSubscriptionNoAuthUseCase({
        request: {
          applicationId: data.applicationId,
          subscriptionPriceId: data.subscriptionPriceId,
          lastCreatedSubscriptionId: subscriptionCookie.subscriptionId ?? undefined
        },
        successCallback: (response) => {
          responseSuccessCreateSubscription({
            subscriptionId: response.subscriptionId,
            token: response.token,
            subscriptionPriceId: data.subscriptionPriceId,
            tempmessage,
            planSelected,
            subscriptionSelected
          })
        },
        errorCallback: (error) => {
          responseFailedCreateSubscription(error.result as ApiError, tempmessage)
        }
      })
    }
  }

  const responseSuccessCreateSubscription = ({
    subscriptionId,
    token,
    subscriptionPriceId,
    tempmessage,
    planSelected,
    subscriptionSelected
  }: {
    subscriptionId: string
    token?: string
    subscriptionPriceId: string
    tempmessage: { type: string; message: string }
    planSelected: string
    subscriptionSelected: string
  }): void => {
    if (subscriptionId) subscriptionCookie.subscriptionId = subscriptionId
    if (token) userCookie.token = token
    tempmessage.type = 'success'
    tempmessage.message = 'Redirecting to the payment page...'

    updateMessage(tempmessage)
    const urlParametersTracking = `/payment?${getUrlParams(planSelected ?? subscriptionSelected)}`

    navigate(urlParametersTracking, {
      state: {
        applicationId: applicationCookie?.id,
        subscriptionId: subscriptionCookie.subscriptionId,
        subscriptionTypeId: subscriptionPriceId,
        formName: applicationCookie?.form?.name
      }
    })
  }

  const responseFailedCreateSubscription = (error: ApiError, tempmessage): void => {
    const errorMessage = error.message && 'there was an error with your request'
    tempmessage.type = 'error'
    tempmessage.message = errorMessage

    updateMessage(tempmessage)
    console.log(error)
  }

  for (const subscription in subscriptionsType) {
    if (subscriptionsType[subscription].isSevenDayFreeTrial) {
      cardsList.content = (
        <p>
          Try our free trial today for 7 days of unlimited access to the entirety of our document library.
          Alternatively, start an annual subscription with {SITE_NAME} now{' '}
          <strong>for as little as $7.99 USD per month</strong> for all your legal form and contract necessities.
        </p>
      )
    }
  }

  const setPreselectedSubscription = (subscriptions: Subscription[]): void => {
    const subscriptionInCookie = subscriptions.find(
      (subscription) => subscriptionCookie.subscriptionTypeId === subscription.id
    )
    if (subscriptionInCookie) {
      setSubscriptionSelected(subscriptionInCookie.id)
      userCookie.subscriptionCodeId = subscriptionInCookie.code
    } else {
      setSubscriptionSelected(subscriptions[0].id)
      userCookie.subscriptionCodeId = subscriptions[0].code
    }
  }

  React.useEffect(() => {
    async function getSubscriptionTypeByCode(): Promise<void> {
      await getSubscriptionTypeByCodeUseCase({
        filterNotSingleDocument: true,
        successCallback: (subscriptions) => {
          setSubscriptionsType(subscriptions)
          setPreselectedSubscription(subscriptions)
        },
        finallyCallback: () => {
          updateLoading(false)
        },
        tokenRequired: true
      })
    }
    getSubscriptionTypeByCode()
  }, [])

  return (
    <>
      {loading || cardsList.data.length === 0 ?
        <Loading />
      : <>
          <JumbotronSimplified {...jumbotronProps} />
          <PricingCardsList
            attributes={cardsList}
            selectRadioButton={selectRadioButton}
            subscriptionSelected={subscriptionSelected}
            submitForm={submitForm}
            message={message}
          />
        </>
      }
    </>
  )
}
