/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames'

import { type ModalProps } from './ModalProps'
import './Modal.scss'

export const Modal: React.FC<ModalProps> = ({
  closeFunction,
  children,
  title,
  type = 'document',
  size = 'L',
  closeButton = true,
  customClass = '',
  disallowCopying = false,
  spinnerModal = false
}) => {
  const modal = React.useRef<HTMLDivElement | null>(null)
  const modalBody = React.useRef<HTMLDivElement | null>(null)

  const sizes = {
    XS: 'modal__content--xs',
    S: 'modal__content--sm',
    M: 'modal__content--md',
    L: 'modal__content--lg',
    XL: 'modal__content--xl'
  }

  const spinnerClass = classNames({
    'spinner-modal': spinnerModal
  })

  const typeClass = type === 'document' ? 'modal--document' : ''
  const sizeClass = sizes[size]
  const bodyPosition = (e): void => {
    if (e.target.scrollTop) {
      e.target.classList.add('scrollDown')
    } else {
      e.target.classList.remove('scrollDown')
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    modalBody.current?.addEventListener('scroll', bodyPosition)
  }, [])

  const handleClickOutside = (event): void => {
    const clickOutOfSelect = modal.current && !modal.current.contains(event.target)

    if (clickOutOfSelect) {
      if (closeFunction) {
        closeFunction()
      }
    }
  }

  return (
    <div id='myModal' className={`modal ${typeClass} ${customClass} ${disallowCopying ? 'copying-not-allowed' : ''}`}>
      <div ref={modal} className={`modal__content ${sizeClass}  ${closeButton ? 'width-close-button' : ''}`}>
        <div className='modal__header-row'>
          {title && <h3 className='modal__title serif --large'>{title}</h3>}
          {closeButton && (
            <div className='modal__close' onClick={closeFunction}>
              &times;
            </div>
          )}
        </div>
        <div ref={modalBody} className={`modal__body ${spinnerClass}`}>
          {children}
        </div>
      </div>
    </div>
  )
}
