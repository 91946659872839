import { type MessagesError } from '@legal/shared/data/types/MessagesError'
import { type Validator } from '../validator.types'
import valid from 'card-validator'
import { validatorString } from '../validatorString'

const MAX_CHARS = 4

export const cvvSplitMapper = (value: string): string => value.split(' ').join('')

export const cardCVValidator = (value: string, cardNumber: string, messagesError: MessagesError): Validator => {
  const _cardNumber = cardNumber.split('-').join('')
  const amex = /^3[47][0-9]{13}$/
  const validators = [
    {
      isValid: validatorString.isEmptyValue(value),
      message: messagesError.default ?? ''
    },
    {
      isValid: valid.cvv(cvvSplitMapper(value)).isValid,
      message: messagesError.code01 ?? ''
    }
  ]

  if (amex.test(_cardNumber)) {
    validators[1] = {
      isValid: valid.cvv(value, MAX_CHARS).isValid,
      message: messagesError.code01 ?? ''
    }
  }

  return validators.find(({ isValid }) => !isValid) ?? { isValid: true, message: messagesError.success ?? '' }
}
