import React from 'react'

import { COOKIE_NAME, CookieContext, getCookie } from '@legal/shared/cookies'
import { type Application } from '../../../application/domain'

type ApplicationCookie = Partial<Application>

export const useApplicationCookie = (): {
  applicationCookie: ApplicationCookie | undefined
  clearApplicationCookie: () => void
  replaceApplicationCookie: (application: ApplicationCookie) => void
  setApplicationCookieLastStep: (lastStep?: number) => void
  setApplicationCookieFormUrl: (formUrl?: string) => void
  setApplicationCookieFormName: (formName?: string) => void
  setApplicationCookieFormDriveId: (formDriveId?: string) => void
  setApplicationCookieIdToDownload: (applicationId?: string) => void
  setApplicationCookieIsAutomaticallyDownload: (isAutomaticallyDownload?: boolean) => void
  setApplicationCookieUrlParams: (urlParams?: string) => void
  setApplicationCookieFromEdit: (fromEdit?: boolean) => void
  setApplicationCookieContinueFromHome: (continueFromHome?: boolean) => void
  setApplicationCookieId: (id: string) => void
} => {
  const { application, setApplication } = React.useContext(CookieContext)

  const clearApplicationCookie = (): void => {
    setApplication(undefined)
  }

  const replaceApplicationCookie = (applicationR: ApplicationCookie): void => {
    setApplication(applicationR)
  }

  const setApplicationCookieLastStep = (lastStep?: number): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (lastStep === oldApplicationInCookieData?.lastStep) return
    replaceApplicationCookie({ ...oldApplicationInCookieData, lastStep })
  }

  const setApplicationCookieFormUrl = (formUrl?: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (formUrl === oldApplicationInCookieData?.form?.url) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      form: { ...oldApplicationInCookieData?.form, url: formUrl }
    })
  }

  const setApplicationCookieFormName = (formName?: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (formName === oldApplicationInCookieData?.form?.name) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      form: { ...oldApplicationInCookieData?.form, name: formName }
    })
  }

  const setApplicationCookieIdToDownload = (applicationId?: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (applicationId === oldApplicationInCookieData?.idToDownload) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      idToDownload: applicationId
    })
  }

  const setApplicationCookieIsAutomaticallyDownload = (isAutomaticallyDownload?: boolean): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (isAutomaticallyDownload === oldApplicationInCookieData?.isAutomaticallyDownload) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      isAutomaticallyDownload
    })
  }

  const setApplicationCookieUrlParams = (urlParams?: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (urlParams === oldApplicationInCookieData?.form?.urlParams) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      form: { ...oldApplicationInCookieData?.form, urlParams }
    })
  }

  const setApplicationCookieFromEdit = (fromEdit?: boolean): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (fromEdit === oldApplicationInCookieData?.fromEdit) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      fromEdit
    })
  }

  const setApplicationCookieFormDriveId = (formDriveId?: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (formDriveId === oldApplicationInCookieData?.form?.driveId) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      form: { ...oldApplicationInCookieData?.form, driveId: formDriveId }
    })
  }

  const setApplicationCookieContinueFromHome = (continueFromHome?: boolean): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (continueFromHome === oldApplicationInCookieData?.continueFromHome) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      continueFromHome
    })
  }

  const setApplicationCookieId = (id: string): void => {
    const applicationInCookie = getCookie(COOKIE_NAME.APPLICATION)
    const oldApplicationInCookieData = applicationInCookie as ApplicationCookie | undefined
    if (id === oldApplicationInCookieData?.id) return
    replaceApplicationCookie({
      ...oldApplicationInCookieData,
      id
    })
  }

  return {
    applicationCookie: application,
    clearApplicationCookie,
    replaceApplicationCookie,
    setApplicationCookieLastStep,
    setApplicationCookieFormUrl,
    setApplicationCookieFormName,
    setApplicationCookieIdToDownload,
    setApplicationCookieIsAutomaticallyDownload,
    setApplicationCookieUrlParams,
    setApplicationCookieFromEdit,
    setApplicationCookieFormDriveId,
    setApplicationCookieContinueFromHome,
    setApplicationCookieId
  }
}
