import { Document } from '../../domain'
import { DocumentsData } from '../dataObjects/DocumentsData'

export function documentsDataToDocuments(data: DocumentsData): Document[] {
  return data.documents.items.map((document) => ({
    id: document.id,
    name: document.name,
    createdAt: document.createdAt.date
  }))
}
