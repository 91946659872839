import { CardPane, type CardPaneInfoProps } from '@npm_leadtech/legal-lib-components/CardPane'
import React from 'react'

import { type BillSubscriptionCardProps } from './BillSubscriptionCardProps'
import { PricingTest } from '../../../services/constants/pricingTest'
import { formatDate } from '@legal/shared/utils'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import { useSubscriptionDataStrapi } from '@legal/shared/hooks/useSubscriptionDataStrapi'
import { useTestAB } from '../../../services/hooks/useTestAB'
import './BillSubscriptionCard.scss'

export const BillSubscriptionCard: React.FC<BillSubscriptionCardProps> = ({
  name,
  displayPriceValue,
  unsubscribeRequestedAt,
  unsubscribeAt,
  isActive = false,
  subscription,
  currentSubscriptionData,
  ctaCancelSubscription,
  handleClickCancelSubscription
}) => {
  let cancellationData: CardPaneInfoProps[] = []
  let subscriptionDataItems: CardPaneInfoProps[] = []

  const { subscriptionData } = useSubscriptionDataStrapi({ nameSubscription: name ?? '' })
  const strapiCommonsText = useQueryCommonsText()
  const [isPricingTest19858] = useTestAB({ id: PricingTest.TEST_CODE, inUrlParam: true, inCookie: true })

  let nameSubscription = name
  if (isPricingTest19858 && name === 'Monthly Plan') {
    nameSubscription = subscription?.firstDocumentName
  }
  let timeDuration = subscriptionData?.timeDuration ?? ''

  if (isPricingTest19858) {
    if (subscription?.price?.displayPrice != subscription?.price?.initialAmount) {
      timeDuration = '/year'
    } else {
      timeDuration = ''
    }
  }

  if (name) {
    const displayPriceValueOnlyPrice = `${strapiCommonsText.currrencySymbol}${displayPriceValue} ${strapiCommonsText.currencyCode}`

    subscriptionDataItems = [
      {
        id: 'subscription-name',
        description: nameSubscription ?? '',
        title: currentSubscriptionData?.planTitle ?? ''
      },
      {
        id: 'subscription-price',
        description: displayPriceValueOnlyPrice ?? '',
        subdescription: timeDuration,
        title: currentSubscriptionData?.priceTitle ?? ''
      },
      {
        id: 'subscription-status',
        description:
          isActive ?
            (currentSubscriptionData?.subscriptionStateActive ?? '')
          : (currentSubscriptionData?.subscriptionStateInactive ?? ''),
        title: currentSubscriptionData?.subscriptionStateTitle ?? ''
      },
      {
        id: 'subscription-cancellation',
        title: ctaCancelSubscription ?? '',
        description: '',
        handleClick: handleClickCancelSubscription
      }
    ]
  }

  if (unsubscribeAt && unsubscribeRequestedAt && isActive) {
    cancellationData = [
      {
        id: 'cancellation-request-date',
        title: currentSubscriptionData?.cancellationRequestDateTitle ?? '',
        description: formatDate({ date: unsubscribeRequestedAt })
      },
      {
        id: 'cancellation-execution-date',
        title: currentSubscriptionData?.cancellationExecutionDateTitle ?? '',
        description: formatDate({ date: unsubscribeAt })
      }
    ]
  }

  if (unsubscribeAt && unsubscribeRequestedAt && !isActive) {
    cancellationData = [
      {
        id: 'cancellation-execution-date',
        title: currentSubscriptionData?.cancellationExecutionDateTitle ?? '',
        description: formatDate({ date: unsubscribeAt })
      }
    ]
  }

  return (
    <div className='subscription-card-billing'>
      <CardPane data={subscriptionDataItems} />
      <CardPane data={cancellationData} />
    </div>
  )
}
