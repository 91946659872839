import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import { type TooltipModalProps } from './TooltipModalProps'
import shape from '../../../assets/images/svg/shape.svg'
import './TooltipModal.scss'

export const TooltipModal: React.FC<TooltipModalProps> = ({ title, text, modalOpen, closeFunction }) => {
  const tooltipHeader = (
    <div className='tooltip-modal-header-container'>
      <div className='tooltip-modal-header'>
        <img className='tooltip-modal-header__image' src={shape} alt=''></img>
        <h3>{title}</h3>
      </div>
      <hr />
    </div>
  )
  const tooltipBody = <div className='tooltip-modal-text'>{text}</div>
  const footer = <Button givenClass='tooltip-modal-button' noLink label='Close' onClick={closeFunction} />

  return (
    <BottomOverlay
      givenClass={'tooltip-modal'}
      header={tooltipHeader}
      body={tooltipBody}
      open={modalOpen}
      onClose={closeFunction}
      footer={footer}
    ></BottomOverlay>
  )
}
