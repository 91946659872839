import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import FreeWeekIcon from '../../../assets/images/svg/free-week-offer.svg'
import MonthlyIcon from '../../../assets/images/svg/monthly-offer.svg'
import MonthlyStarIcon from '../../../assets/images/svg/best-value.svg'
import { type PricingDealCardProps } from './PricingDealCardProps'
import YearlyIcon from '../../../assets/images/svg/yearly-offer.svg'
import { highlightSubstrings } from '../../../services/utils/highlightSubstrings'
import './PricingDealCard.scss'

import {
  PRICING_DEAL_PLAN_NAMES_TYPE,
  useQueryCommonsText
} from '@legal/shared/data/graphql/queries/useQueryCommonsText'

import { pricingPlanNamesTransformToJson } from 'src/modules/price'

export const PricingDealCard: React.FC<PricingDealCardProps> = ({
  headerTitle,
  planTypeName,
  textUnderIcon,
  textOverButton,
  buttonLabel,
  footnote,
  onSelectPlan
}) => {
  const strapiCommonsText = useQueryCommonsText()
  const pricingPlanNames = pricingPlanNamesTransformToJson(strapiCommonsText.pricingDealPlanNames)

  let pricingDealClass = ''
  let pricingIcon
  let monthlyStarIcon
  let buttonTypeProp = {}
  switch (planTypeName) {
    case pricingPlanNames[PRICING_DEAL_PLAN_NAMES_TYPE.best_value].text:
      pricingDealClass = 'best-value'
      pricingIcon = YearlyIcon
      buttonTypeProp = { color: 'secondary' }
      break
    case pricingPlanNames[PRICING_DEAL_PLAN_NAMES_TYPE.access_7_day].text:
      pricingDealClass = 'free-week'
      pricingIcon = FreeWeekIcon
      buttonTypeProp = { color: 'secondary' }
      break
    case pricingPlanNames[PRICING_DEAL_PLAN_NAMES_TYPE.monthly].text:
      pricingDealClass = 'monthly'
      pricingIcon = MonthlyIcon
      monthlyStarIcon = MonthlyStarIcon
      buttonTypeProp = { color: 'primary' }
      break
  }
  const [isDisabled, setIsDisabled] = React.useState(false)
  const buttonsDataQa = buttonLabel.toLowerCase().split(' ').splice(0, 2).join('-')

  const selectPlanOnce = (): void => {
    onSelectPlan()
    setIsDisabled(true)
    const dealButtonsHTMLCollection = document.getElementsByClassName('deal-button')
    const dealButtons = Array.from(dealButtonsHTMLCollection)
    dealButtons.forEach((item) => {
      item.classList.add('--is-disabled')
    })
  }

  const substringsToHighlight =
    planTypeName === 'Best Value' ? ['7 day access', 'one month'] : ['7 day access', 'unlimited access', 'one month']

  const updatedHeaderTitle = highlightSubstrings(headerTitle, substringsToHighlight)

  return (
    <div className={`pricing-deal-card ${pricingDealClass}`}>
      <div className='pricing-deal-card__header'>
        <p>{updatedHeaderTitle}</p>
      </div>
      <div className='pricing-deal-card__body'>
        <div className='body-top-info'>
          <div className='card-icon'>
            <img src={pricingIcon} alt=''></img>
            <img className='monthly-star' src={monthlyStarIcon} alt=''></img>
          </div>
          <p className='under-icon'>{textUnderIcon}</p>
          <p className='over-button'>
            <strong>{textOverButton}</strong>
          </p>
        </div>
        <div className='body-bottom-half'>
          <Button
            {...buttonTypeProp}
            label={buttonLabel}
            noLink
            onClick={selectPlanOnce}
            givenClass={isDisabled ? 'deal-button --is-disabled' : 'deal-button'}
            functionParameters={''}
            dataQa={`${buttonsDataQa}-button`}
          />
          {footnote && <p className='footnote'>{footnote}</p>}
        </div>
      </div>
    </div>
  )
}
