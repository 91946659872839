import { sendAmplitudeData } from './amplitude'

const amplitudeCompleteUpgrade = ({
  formName,
  subscriptionTypeName,
  version
}: {
  formName: string
  subscriptionTypeName: string
  version: string
}): void => {
  const eventProps = {
    pricing_plan: subscriptionTypeName,
    initial_document_type: formName,
    version
  }
  sendAmplitudeData('complete_upgrade', eventProps)
}

export { amplitudeCompleteUpgrade }
