import React from 'react'

import { type Subscription, getSubscriptionTypeByCodeUseCase } from '@legal/subscription'

export const useSubscriptionToShowCustomer = (): {
  isSevenDayAccess: boolean
  subscription?: Subscription
  setSubscription: React.Dispatch<React.SetStateAction<Subscription | undefined>>
  loadingSubscription: boolean
  subscriptionsType: Subscription[]
} => {
  const [subscription, setSubscription] = React.useState<Subscription>()
  const [subscriptionsType, setSubscriptionsType] = React.useState<Subscription[]>([])
  const [loadingSubscription, setLoadingSubscription] = React.useState<boolean>(true)
  const [isSevenDayAccess, setIsSevenDayAccess] = React.useState<boolean>(false)

  React.useEffect(() => {
    async function getSubscriptionType(): Promise<void> {
      await getSubscriptionTypeByCodeUseCase({
        successCallback: (subscriptions) => {
          setSubscriptionsType(subscriptions)
          setSubscription(subscriptions[0])
          setIsSevenDayAccess(subscriptions[0].isSevenDayAccess)
        },
        finallyCallback: () => {
          setLoadingSubscription(false)
        }
      })
    }
    getSubscriptionType()
  }, [])

  return {
    isSevenDayAccess,
    subscription,
    setSubscription,
    loadingSubscription,
    subscriptionsType
  }
}
