import { useEffect, useState } from 'react'
import { TimerLocalStorage } from '../../services/storage/localStorage/TimerLocalStorage'

const timerLocalStorage = new TimerLocalStorage()

const formatTime = (seconts: number): string => {
  const minutes = Math.trunc(seconts / 60)
  const numberSeconts = seconts % 60

  let textSeconts = `${numberSeconts}`
  if (numberSeconts < 10) {
    textSeconts = `0${numberSeconts}`
  }

  return `0${minutes}:${textSeconts}`
}

export const useCountDown = (timeInitial = 299): string | undefined => {
  const [time, setTime] = useState<string>()
  const [seconts, setSeconts] = useState<number>(timeInitial)

  useEffect(() => {
    if (timerLocalStorage.timer) {
      setSeconts(timerLocalStorage.timer)
    }

    const intervalTimer = setInterval(() => {
      setSeconts((seconts) => {
        if (seconts === 0) {
          return timeInitial
        }
        return seconts - 1
      })
    }, 1000)

    return () => {
      clearInterval(intervalTimer)
    }
  }, [timeInitial])

  useEffect(() => {
    const timeFormated = formatTime(seconts)
    setTime(timeFormated)
    timerLocalStorage.timer = seconts
  }, [seconts])

  return time
}
