import { type Transaction } from '../../domain'
import { type TransactionData } from '../dataObjects'

export function TransactionToTransactionData(transaction: Transaction): TransactionData {
  return {
    transactionId: transaction.id,
    email: transaction.email,
    card_holder: transaction.creditCard.holder,
    card_number: transaction.creditCard.number,
    exp_month: transaction.creditCard.expirationMonth,
    exp_year: transaction.creditCard.expirationYear,
    cvc: transaction.creditCard.cvc
  }
}
