/* eslint-disable @typescript-eslint/no-explicit-any */

import { replaceAll } from '../../../../services/utils/replaceAll'
import { sendAmplitudeData } from '@legal/shared/amplitude'

export const updateCurrentStepAndStructure = (
  stepsData,
  updateState,
  formData
): { updateStepAndStructure: (step: number, updatedStepStructure: any) => void } => {
  const updateStepAndStructure = (step: number, updatedStepStructure: any): void => {
    const stepsCount = stepsData.newStepStructure.steps.length
    const isLastStep = step === stepsCount
    if (isLastStep) {
      const productType = replaceAll(formData.formType.typeId.toLowerCase(), ' ', '-')

      const subTypeState = replaceAll(formData.url, `-${productType}`, '')
      const eventProps = {
        document_type: productType,
        document_subtype: formData.subtype || subTypeState || ''
      }
      sendAmplitudeData('step_printanddownload', eventProps)
      sendAmplitudeData('show_candy_page', eventProps)
    }

    updateState({
      currentStep: step,
      currentStructure: updatedStepStructure
    })
  }

  return {
    updateStepAndStructure
  }
}
