import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'

export async function getApplicationSkuUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (sku: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): Promise<void> {
  await ApplicationRepositoryApi.getSku(applicationId)
    .then((sku: string) => {
      logInfo(`GetApplicationSkuUseCase applicationId: ${applicationId}`)
      successCallback(sku)
    })

    .catch((error: Error | undefined) => {
      logError(`GetApplicationSkuUseCase applicationId: ${applicationId}`, undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
