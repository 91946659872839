import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type ActiveSubscriptionLastStepProps } from './ActiveSubscriptionLastStepProps'
import { FormContext } from '../../../services/utils/contexts'
import { TemplateContainer } from '../../template'
import { updateApplicationDataUseCase } from '@legal/application'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import './ActiveSubscriptionLastStep.scss'

export const ActiveSubscriptionLastStep: React.FC<ActiveSubscriptionLastStepProps> = ({
  previousStep,
  getDataFromStep,
  step,
  applicationId
}) => {
  const { formType, formSubType, template, formStateName } = React.useContext(FormContext)
  const { applicationCookie } = useApplicationCookie()
  const { multiStepFormWizard } = useQueryFormPage()

  const saveAndFinish = React.useCallback(async () => {
    const htmlObject = ReactDOMServer.renderToStaticMarkup(template)
    const eventProps = {
      document_type: formType,
      document_subtype: formSubType || formStateName || '',
      application_ID: applicationCookie?.id ?? applicationId,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('complete_application', eventProps)
    const lastStepData = {
      step,
      data: getDataFromStep()
    }
    await updateApplicationDataUseCase({
      applicationId: applicationId ?? applicationCookie?.id,
      request: {
        step: lastStepData.step.slug,
        lastStep: true,
        data: lastStepData.data,
        html: htmlObject
      },
      successCallback: () => {
        navigate('/document-actions')
      }
    })
  }, [getDataFromStep, step, template, formType, formSubType, formStateName, applicationId, applicationCookie?.id])

  return (
    <>
      <section className={'buttons-wrapper'}>
        <div className={'button button__back'}>
          <Button
            color='secondary'
            label={multiStepFormWizard?.btnBackLabel ?? ''}
            noLink
            onClick={previousStep}
            functionParameters={''}
            dataQa={'back'}
          />
        </div>
        <div className={'button button__next'}>
          <Button
            label={multiStepFormWizard?.btnSaveAndFinishLabel ?? ''}
            noLink
            onClick={saveAndFinish}
            functionParameters={''}
            dataQa={'saveAndFinishLastStep'}
          />
        </div>
      </section>

      <div className='preview-container-wrapper'>
        <div className='template-container-wrapper'>
          <TemplateContainer
            template={template}
            hideHeader
            documentType={formType}
            categoryProduct={formSubType}
            draftActive
          />
        </div>
      </div>
    </>
  )
}
