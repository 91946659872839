const getItemsParamsUrl = ({ urlParams }: { urlParams: string }): Record<string, string> => {
  const params: Record<string, string> = {}
  const searchParams = new URLSearchParams(urlParams)

  searchParams.forEach(function (value, key) {
    params[key] = value
  })

  return params
}

const getParamsUrl = (): Record<string, string> | null => {
  let params: Record<string, string> | null = null
  if (typeof window !== 'undefined') {
    const windowLocationSearch = window.location.search
    if (windowLocationSearch) params = getItemsParamsUrl({ urlParams: windowLocationSearch })
  }
  return params
}

const existsParamInUrl = (item: string, itemsUrl: Record<string, string> | null = getParamsUrl()): boolean => {
  return !!itemsUrl?.[item]
}

const getParamUrlValue = (
  item: string,
  itemsUrl: Record<string, string> | null = getParamsUrl()
): string | undefined => {
  return itemsUrl?.[item]
}

export { getParamsUrl, getItemsParamsUrl, existsParamInUrl, getParamUrlValue }
