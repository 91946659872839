import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { type CreateCustomerSubscriptionRequest } from './interfaces'
import { SubscriptionRepositoryApi } from '../infra'

export async function createCustomerSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (subscriptionId: string) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CreateCustomerSubscriptionRequest
}): Promise<void> {
  await SubscriptionRepositoryApi.createForCustomer(request.subscriptionPriceId, request.applicationId)
    .then(({ subscriptionId }: { subscriptionId: string }) => {
      logInfo('CreateCustomerSubscriptionUseCase', request)
      successCallback(subscriptionId)
    })
    .catch((error?: ApiError) => {
      logError(`CreateCustomerSubscriptionUseCase: ${error?.message}`, request)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
