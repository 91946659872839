import React from 'react'

import { type ProductListMenuProps } from './ProductListMenuProps'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useConfigs } from '@legal/core/configs'

export const ProductListMenu: React.FC<ProductListMenuProps> = ({ slug, linkText, categoryUrl }) => {
  const { TARGET_ADDRESS } = useConfigs()

  return (
    <li className='products__list__section__li'>
      <a
        title={linkText}
        data-qa={`${stringSlugify(linkText)}_document-list`}
        onClick={() => setCookiePolicyFunc}
        href={`${TARGET_ADDRESS}/${categoryUrl ? `${categoryUrl}/${slug}` : slug}`}
      >
        {linkText}
      </a>
    </li>
  )
}
