import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import { type CreditCard, deleteCreditCardUseCase, findCreditCardsActiveByCustomerUseCase } from '@legal/creditCard'
import { DeleteCardErrorModal } from '../DeleteCardErrorModal'
import { LoadingCards } from '../LoadingCards'
import { Modal } from '../../layout'
import { NotSavedCard } from '../NotSavedCard'
import { PaymentMethod } from '../PaymentMethod'
import { type PaymentMethodsModalProps } from './PaymentMethodsModalProps'
import { useModal } from '../../../services/hooks/useModal'
import './PaymentMethodsModal.scss'

export const PaymentMethodsModal: React.FC<PaymentMethodsModalProps> = ({
  closeFunction,
  dataTexts,
  dataTextsDeleteCard
}) => {
  const [loading, updateStatus] = React.useState<boolean>(true)
  const [creditCards, setCreditCards] = React.useState<CreditCard[]>([])
  const deleteCardErrorModal = useModal(false)

  React.useEffect(() => {
    initData()
  }, [])

  const initData = async (): Promise<void> => {
    await findCreditCardsActiveByCustomerUseCase({
      successCallback: (creditCards) => {
        setCreditCards(creditCards)
        updateStatus(false)
      }
    })
  }

  const onDeleteCard = React.useCallback(async (id: string) => {
    await deleteCreditCardUseCase({
      request: { cardId: id },
      successCallback: () => {
        initData()
      },
      errorCallback: () => {
        deleteCardErrorModal.changeModalState()
      }
    })
  }, [])

  return (
    <Modal
      title={dataTexts?.ctaPaymentMethods ?? ''}
      size='L'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
      customClass='payments-modal'
    >
      <div className='payment-list-container'>
        {loading && <LoadingCards />}
        {!loading && (
          <>
            {creditCards.length > 0 &&
              creditCards.map((creditCard) => {
                return (
                  <PaymentMethod
                    key={creditCard.id}
                    creditCard={creditCard}
                    onDeleteCard={onDeleteCard}
                    dataTexts={dataTexts}
                  />
                )
              })}
            {creditCards.length === 0 && <NotSavedCard />}
          </>
        )}
      </div>
      <Button
        noLink
        centered
        label={dataTexts?.ctaClose ?? ''}
        onClick={closeFunction}
        dataQa='modal--payment-methods__button'
      />
      {deleteCardErrorModal.modalOpen && (
        <DeleteCardErrorModal
          onClickButton={deleteCardErrorModal.closeModal}
          closeFunction={closeFunction}
          dataTexts={dataTextsDeleteCard}
        />
      )}
    </Modal>
  )
}
