/* eslint-disable no-console */

import React from 'react'
import { Snackbar } from '@npm_leadtech/legal-lib-components/Snackbar'

import { AcceptCookieContext } from '../../../services/utils/contexts'
import { GetCustomerGeoLocation } from '@legal/customer'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { SnackbarLayoutInner } from '../SnackbarLayoutInner'
import { type SnackbarLayoutProps } from './SnackbarLayoutProps'
import { ToastProvider } from 'react-toast-notifications'
import euCountries from '@legal/shared/data/json/euCountries.json'
import './SnackbarLayout.scss'

export const SnackbarLayout: React.FC<SnackbarLayoutProps> = ({ children, location, snackbarLayoutOverflow }) => {
  const referrerCookie = new ReferrerCookie()

  const [snackbarData, setSnackbarData] = React.useState(null)
  const [removeAllSnackbars, setRemoveAllSnackbars] = React.useState(false)

  const cookieContextValue = React.useContext(AcceptCookieContext)
  const [acceptCookie, setAcceptCookie] = React.useState(cookieContextValue)

  React.useEffect(() => {
    referrerCookie.setReferrer()
  }, [location])

  React.useEffect(() => {
    if (location.state?.toast) {
      setSnackbarData({
        type: location.state.toast.type,
        title: location.state.toast.title,
        text: location.state.toast.text,
        timeout: location.state.toast.timeout
      })
    } else if (!location.pathname.includes('my-account')) {
      setSnackbarData(null)
      setRemoveAllSnackbars((prevVal) => !prevVal)
    }
  }, [location])

  // manage state of cookie policy bar (show or hide)
  React.useEffect(() => {
    const IPCountry = localStorage.getItem('IPCountry')
    if (IPCountry) {
      handleCookieBarState(IPCountry)
    } else {
      GetCustomerGeoLocation.Execute()
        .then((response) => {
          let IPCountryValue
          if (response.result.country_code) {
            IPCountryValue = response.result.country_code
          } else {
            IPCountryValue = 'missingIPCountry'
          }
          localStorage.setItem('IPCountry', IPCountryValue)
          handleCookieBarState(IPCountryValue)
        })
        .catch((error: unknown) => {
          console.log(error)
        })
    }
  }, [location])

  const handleCookieBarState = (IPCountry): void => {
    if (isCountryEuropean(IPCountry) || IPCountry === 'missingIPCountry') {
      setAcceptCookie(acceptCookie !== null)
    }
  }

  const isCountryEuropean = (countryCode): boolean => {
    return euCountries[countryCode] !== undefined
  }

  return (
    <ToastProvider autoDismiss={true} autoDismissTimeout={11000} components={{ Toast: Snackbar }}>
      <div className={`snackbar-layout ${snackbarLayoutOverflow ?? ''}`}>
        <AcceptCookieContext.Provider value={acceptCookie}>
          <SnackbarLayoutInner
            snackbarData={snackbarData}
            removeAllSnackbars={removeAllSnackbars}
          ></SnackbarLayoutInner>
          {children}
        </AcceptCookieContext.Provider>
      </div>
    </ToastProvider>
  )
}
