import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'

import { Modal } from '../../layout'
import { type ReadyDocumentModalProps } from './ReadyDocumentModalProps'
import componentDocumentImage from '../../../assets/images/svg/illustration-component-document.svg'
import './ReadyDocumentModal.scss'

export const ReadyDocumentModal: React.FC<ReadyDocumentModalProps> = ({
  closeFunction,
  primaryActionText,
  title,
  text
}) => {
  return (
    <Modal
      title={title}
      size='S'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
      customClass='downloaded-modal'
    >
      <div className='modal--downloaded__container'>
        <img className='modal--downloaded__image' alt='' src={componentDocumentImage} />
      </div>
      <div className='modal--downloaded__container'>
        <RichTextStrapi {...text} />
      </div>
      <div className='modal--downloaded__actions'>
        <Button
          givenClass='modal--downloaded__button'
          label={primaryActionText}
          onClick={closeFunction}
          noLink
          dataQa='modal--downloaded__button'
        />
      </div>
    </Modal>
  )
}
