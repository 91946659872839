import { CustomerAuthGoogleUseCase } from '@legal/customer'
import React from 'react'

export const useDoGoogleSignInCallback = ({
  isModal = false,
  formName
}: {
  isModal?: boolean
  formName?: string
}): {
  doGoogleSignIn: () => void
} => {
  const doGoogleSignIn = React.useCallback((): void => {
    let redirectUrl
    if (isModal) {
      redirectUrl = typeof window !== 'undefined' ? window.location.pathname : ''
    }
    CustomerAuthGoogleUseCase({
      request: {
        redirectUrl,
        formName
      }
    })
  }, [formName, isModal])

  return { doGoogleSignIn }
}
