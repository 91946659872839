export const sanitizeOptions = {
  allowedAttributes: {
    span: ['style'],
    table: ['style', 'border', 'width'],
    div: ['style'],
    strong: ['style'],
    td: ['style', 'width'],
    tr: ['style', 'width']
  },
  allowedClasses: {
    span: ['itemChanged']
  }
}
