import React from 'react'

import { type Subscription, getSubscriptionTypeByCodeUseCase } from '@legal/subscription'
import { type PaymentFormProps } from './PaymentFormProps'
import { PaymentGateway } from '../PaymentGateway'
import { PaymentSummary } from '../PaymentSummary'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { getApplicationSkuUseCase } from '@legal/application'
import { pushPaymentDataToDataLayer } from '@legal/shared/utils'
import './PaymentForm.scss'

export const PaymentForm: React.FC<PaymentFormProps> = ({
  applicationId,
  currencyData,
  currencySymbol,
  everyFourWeeks,
  htmlAstChildren,
  hasPaymentError,
  paymentGatewayData,
  paymentProvider,
  paymentSummaryData,
  providersCustomMessages,
  pspGatewayData,
  subscriptionTypeId,
  timeCountDown,
  transactionInYourAccountAppearAs,
  trialSevenDays,
  timeoutModal,
  validatorsI18n,
  callbackUserCreate
}) => {
  const subscriptionCookie = new SubscriptionCookie()
  const [subscription, setSubscription] = React.useState<Subscription>()

  React.useEffect(() => {
    getSkuHandler()
  }, [subscription])

  React.useEffect(() => {
    const getSubscriptionType = async (): Promise<void> =>
      await getSubscriptionTypeByCodeUseCase({
        successCallback: (subscriptions) => {
          if (subscriptionTypeId) getSubscriptionInformation(subscriptions.find((sub) => sub.id === subscriptionTypeId))
        },
        filterNotSingleDocument: true
      })
    getSubscriptionType()
  }, [])

  const getSubscriptionInformation = (sub: Subscription): void => {
    setSubscription(sub)
    subscriptionCookie.subscriptionTypeName = sub.type.name ?? null
  }

  const getSkuHandler = async (): Promise<void> => {
    if (applicationId && subscription?.displayPrice) {
      await getApplicationSkuUseCase({
        applicationId,
        successCallback: (sku) => {
          pushPaymentDataToDataLayer({ sku, subscription })
        }
      })
    }
  }

  return (
    <div className={'m-paymentForm'}>
      <div className='m-paymentForm-left'>
        <PaymentGateway
          applicationId={applicationId}
          htmlAstChildren={htmlAstChildren}
          hasPaymentError={hasPaymentError}
          paymentGatewayData={paymentGatewayData}
          paymentProvider={paymentProvider}
          providersCustomMessages={providersCustomMessages}
          pspGatewayData={pspGatewayData}
          subscriptionDisplayPrice={subscription?.displayPrice}
          subscriptionTypeId={subscriptionTypeId}
          subscriptionTypeName={subscription?.type.name}
          transactionInYourAccountAppearAs={transactionInYourAccountAppearAs}
          trialSevenDays={trialSevenDays}
          validatorsI18n={validatorsI18n}
          timeoutModal={timeoutModal}
          callbackUserCreate={callbackUserCreate}
        />
      </div>
      <PaymentSummary
        currencyData={currencyData}
        currencySymbol={currencySymbol}
        everyFourWeeks={everyFourWeeks}
        paymentSummaryData={paymentSummaryData}
        subscriptionInformation={subscription}
        timeCountDown={timeCountDown}
      />
    </div>
  )
}
