export class DataStore {
  constructor({ data }) {
    this._data = data
  }

  read(key): unknown {
    return this._data[key]
  }

  // noinspection JSUnusedGlobalSymbols
  write(): void {
    throw new Error('Not Supported')
  }
}
