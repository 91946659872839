function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function replaceAll(str: string | null | undefined, find: string, replace: string): string | null | undefined {
  if (str === undefined) return undefined
  if (str === null || typeof str !== 'string' || typeof find !== 'string' || typeof replace !== 'string') return null
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

type Keys =
  | 'CURRENCY_SYMBOL'
  | 'CURRENCY'
  | 'CURRENT_YEAR'
  | 'DATE_UNSUBSCRIBE_AT'
  | 'DISPLAY_PRICE'
  | 'FORM_NAME'
  | 'INITIAL_AMOUNT'
  | 'REMAINING_DAYS'
  | 'SITE_NAME'
  | 'SITE_PHONE'
  | 'SITE_URL'
  | 'USER_EMAIL'
  | 'WWW'

type STRAPI_TEXT_VARIABLES = {
  [key in Keys]: string
}

function replaceValues(
  str: string | null | undefined,
  valuesToReplace: Partial<STRAPI_TEXT_VARIABLES>
): string | undefined {
  if (str === undefined || str === null || typeof str !== 'string') return undefined
  return str.replace(/{{(\w+)}}/g, (valueFromStrapiMatch, key: Keys) => {
    return valuesToReplace[key] ?? valueFromStrapiMatch
  })
}

export { replaceAll, replaceValues }
