/* eslint-disable @typescript-eslint/no-explicit-any */
import { type CreateSubscriptionNoAuthRequest, type CreateSubscriptionNoAuthResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { SubscriptionRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export async function createSubscriptionNoAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CreateSubscriptionNoAuthResponse) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateSubscriptionNoAuthRequest
}): Promise<void> {
  await SubscriptionRepositoryApi.createNoAuth(request)
    .then((response) => {
      const user = new UserCookie()
      if (user.token) {
        logError(
          'CreateSubscriptionNoAuthUseCase: Usuario logeado intenta crear una subscripcion sin autenticacion',
          request
        )
        return
      }
      logInfo('CreateSubscriptionNoAuthUseCase', request)
      successCallback(response)
    })
    .catch((error) => {
      logError('CreateSubscriptionNoAuthUseCase', request, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
