import { saveAs } from 'file-saver'

import { DocumentActions, DocumentBlob } from '@legal/document'
import { downloadPdf } from './downloadPdf'

export const openPreviewPDF = (
  documentBlob: DocumentBlob,
  documentAction: DocumentActions,
  fileName: string,
  isMobile: boolean
): void => {
  if (documentAction === DocumentActions.DOWNLOAD) {
    downloadPdf({ documentBlob, fileName })
    return
  }
  if (isMobile) {
    saveAs(documentBlob.blob, documentBlob.fileName)
    return
  }
  window.open(documentBlob.blobUrl, '_blank')?.focus()
}
