/* eslint-disable @typescript-eslint/no-unused-vars */

import { DocumentBlob } from '@legal/document'
import { saveAs } from 'file-saver'

export const downloadPdf = ({
  documentBlob,
  fileName,
  isAndroidDevice = false
}: {
  documentBlob: DocumentBlob
  fileName: string
  isAndroidDevice?: boolean
}): void => {
  const downloadDocumentAndroid = (): void => {
    const fileURL = URL.createObjectURL(documentBlob.blob)
    window.open(fileURL)
    const a = document.createElement('a')
    a.href = fileURL
    a.target = '_blank'
    a.download = fileName
    document.body.appendChild(a)
    a.click()
  }
  try {
    if (isAndroidDevice) {
      downloadDocumentAndroid()
      saveAs(documentBlob.androidBlob, fileName)
      return
    }
    saveAs(documentBlob.blob, fileName)
  } catch (_) {
    saveAs(documentBlob.androidBlob, fileName)
  }
}
