import React, { Fragment } from 'react'

import { ImageStrapi, LinkStrapi } from '../../atoms/StrapiElementsRichText'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'

export const FormatHTMLAstChildren = ({
  htmlAstChildren,
  givenClassLink = '',
  CURRENT_YEAR,
  WWW
}: {
  htmlAstChildren: React.ReactNode | React.ReactNode[]
  givenClassLink?: string
  CURRENT_YEAR?: string
  WWW?: string
}): React.JSX.Element => {
  const {
    SITE_METADATA: { SITE_NAME, SITE_URL }
  } = useConfigs()
  return htmlAstChildren?.map((htmlContent) =>
    htmlContent.children.map((child, index) => {
      const elementByTagName: Record<string, Record<string, React.ReactNode>> = {
        element: {
          a: (
            <LinkStrapi
              key={index}
              givenClass={givenClassLink}
              href={child?.properties?.href}
              text={Array.isArray(child?.children) && child?.children[0]?.value}
            />
          ),
          img: <ImageStrapi key={index} src={child?.properties?.src} />,
          strong: (
            <strong>
              <Fragment key={index}>{Array.isArray(child?.children) && child?.children[0]?.value}</Fragment>
            </strong>
          )
        }
      }

      return child.type === 'text' ?
          <Fragment key={index}>{replaceValues(child.value, { SITE_NAME, SITE_URL, CURRENT_YEAR, WWW })}</Fragment>
        : elementByTagName[child.type][child.tagName]
    })
  )
}
