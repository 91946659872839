exports.components = {
  "component---src-pages-ai-legal-document-reviewer-password-tsx": () => import("./../../../src/pages/ai-legal-document-reviewer/password.tsx" /* webpackChunkName: "component---src-pages-ai-legal-document-reviewer-password-tsx" */),
  "component---src-pages-create-password-tsx": () => import("./../../../src/pages/create-password.tsx" /* webpackChunkName: "component---src-pages-create-password-tsx" */),
  "component---src-pages-document-actions-tsx": () => import("./../../../src/pages/document-actions.tsx" /* webpackChunkName: "component---src-pages-document-actions-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loading-page-tsx": () => import("./../../../src/pages/loading-page.tsx" /* webpackChunkName: "component---src-pages-loading-page-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-account-my-documents-create-document-pdf-paid-tsx": () => import("./../../../src/pages/my-account/my-documents/create-document-pdf-paid.tsx" /* webpackChunkName: "component---src-pages-my-account-my-documents-create-document-pdf-paid-tsx" */),
  "component---src-pages-my-account-my-documents-create-document-pdf-tsx": () => import("./../../../src/pages/my-account/my-documents/create-document-pdf.tsx" /* webpackChunkName: "component---src-pages-my-account-my-documents-create-document-pdf-tsx" */),
  "component---src-pages-no-api-tsx": () => import("./../../../src/pages/no-api.tsx" /* webpackChunkName: "component---src-pages-no-api-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-test-forms-tsx": () => import("./../../../src/pages/testForms.tsx" /* webpackChunkName: "component---src-pages-test-forms-tsx" */),
  "component---src-templates-access-reminder-template-tsx": () => import("./../../../src/templates/AccessReminderTemplate.tsx" /* webpackChunkName: "component---src-templates-access-reminder-template-tsx" */),
  "component---src-templates-authorization-template-tsx": () => import("./../../../src/templates/AuthorizationTemplate.tsx" /* webpackChunkName: "component---src-templates-authorization-template-tsx" */),
  "component---src-templates-form-template-tsx": () => import("./../../../src/templates/FormTemplate.tsx" /* webpackChunkName: "component---src-templates-form-template-tsx" */),
  "component---src-templates-help-center-template-tsx": () => import("./../../../src/templates/HelpCenterTemplate.tsx" /* webpackChunkName: "component---src-templates-help-center-template-tsx" */),
  "component---src-templates-my-documents-template-tsx": () => import("./../../../src/templates/MyDocumentsTemplate.tsx" /* webpackChunkName: "component---src-templates-my-documents-template-tsx" */),
  "component---src-templates-my-profile-template-tsx": () => import("./../../../src/templates/MyProfileTemplate.tsx" /* webpackChunkName: "component---src-templates-my-profile-template-tsx" */),
  "component---src-templates-page-404-template-tsx": () => import("./../../../src/templates/Page404Template.tsx" /* webpackChunkName: "component---src-templates-page-404-template-tsx" */),
  "component---src-templates-payment-details-template-tsx": () => import("./../../../src/templates/PaymentDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-details-template-tsx" */),
  "component---src-templates-payment-error-template-tsx": () => import("./../../../src/templates/PaymentErrorTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-error-template-tsx" */),
  "component---src-templates-payment-processing-template-tsx": () => import("./../../../src/templates/PaymentProcessingTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-processing-template-tsx" */),
  "component---src-templates-payment-template-tsx": () => import("./../../../src/templates/PaymentTemplate.tsx" /* webpackChunkName: "component---src-templates-payment-template-tsx" */),
  "component---src-templates-preview-pricing-template-tsx": () => import("./../../../src/templates/PreviewPricingTemplate.tsx" /* webpackChunkName: "component---src-templates-preview-pricing-template-tsx" */),
  "component---src-templates-pricing-deals-template-tsx": () => import("./../../../src/templates/PricingDealsTemplate.tsx" /* webpackChunkName: "component---src-templates-pricing-deals-template-tsx" */),
  "component---src-templates-pricing-template-tsx": () => import("./../../../src/templates/PricingTemplate.tsx" /* webpackChunkName: "component---src-templates-pricing-template-tsx" */),
  "component---src-templates-register-template-tsx": () => import("./../../../src/templates/RegisterTemplate.tsx" /* webpackChunkName: "component---src-templates-register-template-tsx" */)
}

