/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
export const TypeSelector = (type: string, value: string | number | Date, checked: boolean): any => {
  switch (type) {
    case 'DatePicker':
      return value ? new Date(value).toISOString() : ''
    case 'Check':
      return checked ? '1' : '0'
    default:
      return value
  }
}
