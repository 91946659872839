/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'

import { TestABCookie } from '../storage/cookies/TestABCookie'
import { existsParamInUrl } from '../utils/paramsUrl'

interface useTestABProps {
  id: string
  inUrlParam?: boolean
  inCookie?: boolean
}

export const useTestAB = ({ id, inUrlParam = true, inCookie = true }: useTestABProps): boolean[] => {
  const [isTestAB, setIsTestAB] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const validateParamInUrl = (): void => {
    if (existsParamInUrl(id)) {
      setIsTestAB(true)
    }
  }

  const validateInCookie = (): void => {
    const testABCookie = new TestABCookie(id)
    if (testABCookie.value === 'true') {
      setIsTestAB(true)
    }
  }

  React.useEffect(() => {
    inUrlParam && validateParamInUrl()
    inCookie && validateInCookie()
    setLoading(false)
  }, [inUrlParam, inCookie])

  return [isTestAB, loading]
}
