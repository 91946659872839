import sanitizeHtml from 'sanitize-html'
import shortid from 'shortid'

import type {
  FieldRadioValueItemProps,
  FieldSelectItemProps,
  FieldSelectValueProps,
  GroupFieldProps
} from './FormProps'
import type {
  GroupFieldAddButtonStrapiDataProps,
  GroupFieldCheckboxStrapiDataProps,
  GroupFieldDatePickerStrapiDataProps,
  GroupFieldRadioStrapiDataProps,
  GroupFieldRemoveButtonStrapiDataProps,
  GroupFieldSelectStrapiDataProps,
  GroupFieldSelectUsaStateStrapiDataProps,
  GroupFieldStrapiDataProps,
  GroupFieldTextAreaStrapiDataProps,
  GroupFieldTextStrapiDataProps,
  ValueItemStrapiDataProps
} from './FormStrapiProps'
import { toDate } from '@legal/shared/utils'
import usaStates from '@legal/shared/data/json/usaStates.json'

export const setValues = (field: GroupFieldStrapiDataProps): GroupFieldProps => {
  let formField
  switch (field.__typename) {
    case 'STRAPI__COMPONENT_FORMS_FIELD_TEXT':
      formField = field as GroupFieldTextStrapiDataProps
      return {
        id: shortid.generate(),
        component: 'TextInput',
        type: 'text',
        givenClass: '',
        placeholder: formField.placeholder ?? '',
        label: formField.label ?? '',
        value: formField.value ?? '',
        format: formField.mask ? formField.mask.replace(/9/g, '#') : '',
        mask: formField.mask ?? '',
        name: formField.name ?? '',
        tooltip: formField.tooltip ?? '',
        errorMessage: '',
        required: false,
        valueType: formField.valueFormat?.valueType ?? 'Regular',
        validate: true,
        isValidGroup: true,
        disabled: false
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_RADIO': {
      formField = field as GroupFieldRadioStrapiDataProps
      const fieldChecked: ValueItemStrapiDataProps[] = formField.values.filter(
        (value: ValueItemStrapiDataProps) => value.checked
      )
      let radioHasIcons = false
      const radioItems: FieldRadioValueItemProps[] = formField.values.map((item: ValueItemStrapiDataProps) => {
        if (item.iconName) radioHasIcons = true
        return {
          value: item.value ?? '',
          label: item.label ?? '',
          checked: item.checked ?? '',
          iconName: item.iconName ?? ''
        }
      })
      const radioClasses: string = getRadioClasses(formField, radioHasIcons)
      return {
        id: shortid.generate(),
        component: 'Radio',
        type: 'radio',
        class: radioClasses,
        placeholder: '',
        tooltip: formField.tooltip ?? '',
        name: formField.name ?? '',
        label: formField.label ?? '',
        value: fieldChecked[0]?.value ?? '',
        required: false,
        disabled: false,
        errorMessage: '',
        validate: true,
        isValidGroup: true,
        items: radioItems ?? []
      }
    }
    case 'STRAPI__COMPONENT_FORMS_DATE_PICKER':
      formField = field as GroupFieldDatePickerStrapiDataProps
      return {
        id: shortid.generate(),
        component: 'DatePicker',
        type: 'date',
        class: '',
        label: formField.label ?? '',
        tooltip: formField.tooltip ?? '',
        placeholder: formField.placeholder ?? 'Please select a date',
        value: formField.valueDate ? toDate(formField.valueDate) : null,
        name: formField.name ?? '',
        errorMessage: '',
        required: false,
        validate: true,
        isValidGroup: true,
        disabled: false,
        isStartDate: formField.alternateStyle,
        relatedDateFormName: formField.reference ?? null
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_SELECT': {
      formField = field as GroupFieldSelectStrapiDataProps
      const selectItems: FieldSelectItemProps[] = formField.options.map((item: ValueItemStrapiDataProps) => {
        return {
          value: item.value ?? '',
          label: item.label ?? ''
        }
      })
      const value: string | FieldSelectValueProps = getSelectValue(formField)
      return {
        component: 'SearchSelect',
        id: shortid.generate(),
        type: 'select',
        isSearchable: true,
        customClass: '',
        tooltip: formField.tooltip ?? '',
        label: formField.label ?? '',
        value,
        disabled: false,
        name: formField.name ?? '',
        errorMessage: '',
        isSortAlphabetically: formField.isSortAlphabetically,
        required: false,
        validate: true,
        isValidGroup: true,
        items: selectItems ?? []
      }
    }
    case 'STRAPI__COMPONENT_FORMS_CHECKBOX':
      formField = field as GroupFieldCheckboxStrapiDataProps
      return {
        id: shortid.generate(),
        component: 'Check',
        type: 'checkbox',
        value: formField.valueCheck ? '1' : '0',
        class: '',
        tooltip: formField.tooltip ?? '',
        name: formField.name ?? '',
        disabled: false,
        errorMessage: '',
        required: false,
        validate: true,
        isValidGroup: true,
        label: sanitizeHtml(field.label ?? '')
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_SELECT_USA_STATE':
      formField = field as GroupFieldSelectUsaStateStrapiDataProps
      return {
        component: 'SearchSelect',
        id: shortid.generate(),
        type: 'select',
        class: '',
        label: formField.label ?? '',
        tooltip: formField.tooltip ?? '',
        value: formField.value ?? '',
        name: formField.name ?? '',
        disabled: false,
        errorMessage: '',
        required: false,
        isValidGroup: true,
        validate: true,
        items: usaStates.usaStates ?? []
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_TEXT_AREA':
      formField = field as GroupFieldTextAreaStrapiDataProps
      return {
        id: shortid.generate(),
        component: 'TextArea',
        type: 'textarea',
        class: '',
        placeholder: formField.placeholder ?? '',
        label: formField.label ?? '',
        value: formField.value ?? '',
        tooltip: formField.tooltip ?? '',
        name: formField.name ?? '',
        errorMessage: '',
        required: false,
        validate: true,
        disabled: false,
        isValidGroup: true
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_ADD_BUTTON':
      formField = field as GroupFieldAddButtonStrapiDataProps
      return {
        id: shortid.generate(),
        name: formField.name,
        class: 'addButton',
        component: 'AddButton',
        value: formField.value,
        required: false,
        validate: true,
        tooltip: formField.tooltip,
        isValidGroup: true,
        label: formField.values[0]?.label ?? '',
        items: {
          addButton: {
            value: formField.values[0]?.value ?? '',
            checked: formField.values[0]?.checked ?? ''
          }
        }
      }
    case 'STRAPI__COMPONENT_FORMS_FIELD_REMOVE_BUTTON':
      formField = field as GroupFieldRemoveButtonStrapiDataProps
      return {
        id: shortid.generate(),
        name: formField.name,
        class: 'removeButton',
        required: false,
        validate: true,
        tooltip: formField.tooltip,
        isValidGroup: true,
        component: 'RemoveButton',
        label: formField.values[0]?.label ?? '',
        items: {
          removeButton: {
            value: formField.values[0]?.value ?? '',
            checked: formField.values[0]?.checked ?? '',
            reference: formField.values[0]?.reference ?? ''
          }
        }
      }
    default:
      return {
        component: 'TextInput',
        id: shortid.generate(),
        type: 'text',
        givenClass: '',
        placeholder: '',
        tooltip: field.tooltip ?? '',
        label: field.label ?? '',
        format: 'mask' in field ? (field.mask?.replace(/9/g, '#') ?? '') : '',
        mask: '',
        value: '',
        valueType: 'valueFormat' in field ? (field.valueFormat?.valueType ?? 'Regular') : 'Regular',
        errorMessage: '',
        isValidGroup: true,
        required: false,
        disabled: false,
        name: field.name ?? '',
        validate: true
      }
  }
}

const getRadioClasses = (field: GroupFieldRadioStrapiDataProps, radioHasIcons: boolean): string => {
  let radioClasses: string = field.alternateStyle ? '--tabs' : '--regular'
  if (radioHasIcons || field.iconStyle) radioClasses = radioClasses.concat(' --icons')
  return radioClasses
}

const getSelectValue = (field: GroupFieldSelectStrapiDataProps): string | FieldSelectValueProps => {
  const defaultOption: ValueItemStrapiDataProps | undefined = field.options.find(
    (option: ValueItemStrapiDataProps) => option.checked
  )
  if (defaultOption) return defaultOption
  return ''
}
