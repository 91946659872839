import { type Application } from '../../domain'
import { type CreateApplicationRequest } from '../interfaces'

export function CreateApplicationRequestToApplication(
  request: CreateApplicationRequest,
  id: string
): Partial<Application> {
  return {
    id,
    state: request.stateCode,
    testAbIdentifier: request.testAbIdentifier,
    form: {
      driveId: request.driveId
    }
  }
}
