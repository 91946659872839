import { DOCS_PER_PAGE } from '../constants/uploadConstants'
import { Document } from '../Document'

export function slicedDocumentsByCurrentPage(
  documents: Document[],
  currentPage: number,
  isMobile: boolean,
  isTablet: boolean
): Document[] {
  if (isMobile || isTablet) return documents
  return documents.slice((currentPage - 1) * DOCS_PER_PAGE, currentPage * DOCS_PER_PAGE)
}
