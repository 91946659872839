import { graphql, useStaticQuery } from 'gatsby'

export const useQueryDesktopPane = (): Queries.STRAPI_DESKTOP_PANE => {
  const { strapiDesktopPane } = useStaticQuery(graphql`
    query {
      strapiDesktopPane {
        text
        labelGoToDashboard
        labelCreateFreeAccount
        labelLogIn
      }
    }
  `)

  return strapiDesktopPane
}
