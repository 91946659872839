/* eslint-disable @typescript-eslint/no-explicit-any */
import { getConfigs } from '@legal/core/configs'
import { useAllFeaturedProducts } from '@legal/shared/hooks'

export const useNavbarMicroformat = (): {
  '@context': string
  '@graph': any
} => {
  const navbarItems = useAllFeaturedProducts()
  const { TARGET_ADDRESS } = getConfigs()
  const itemNavsMicroformat = navbarItems.map((product, key) => {
    return {
      '@type': 'SiteNavigationElement',
      '@id': '#navbarContent',
      position: key + 1,
      name: product.jumboTitle,
      url: `${TARGET_ADDRESS}/${product.slug}`
    }
  })

  return {
    '@context': 'https://schema.org',
    '@graph': itemNavsMicroformat
  }
}
