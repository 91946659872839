const getUrlFromEnv = (
  env
): {
  $www$: string
  $app$: string
} => {
  switch (env) {
    case 'https://local-api.legal-contracts.com/api':
      return {
        $www$: 'http://localhost:9001',
        $app$: 'http://localhost:9000'
      }
    case 'https://stage-api.lawdistrict.com/api':
      return {
        $www$: 'https://stage.lawdistrict.com',
        $app$: 'https://stage-app.lawdistrict.com'
      }
    default:
      return {
        $www$: 'https://www.lawdistrict.com',
        $app$: 'https://app.lawdistrict.com'
      }
  }
}

export { getUrlFromEnv }
