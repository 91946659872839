import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import { type ContinueModalContentProps } from './ContinueModalContentProps'
import { Modal } from '../../layout'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import './ContinueModalContent.scss'

export const ContinueModalContent: React.FC<ContinueModalContentProps> = ({
  createApplicationModal,
  resumeApplicationModal,
  closeFunction
}) => {
  const { continueModalContent } = useQueryFormPage()

  return (
    <Modal
      title={continueModalContent?.title}
      size='S'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
      customClass='continue-modal'
    >
      <div className='modal--continue__container'>
        <p className='modal--continue__paragraph'>{continueModalContent?.titleFeedback}</p>
      </div>
      <div className='modal--continue__actions'>
        <Button
          givenClass='modal--continue__button'
          color='secondary'
          label={continueModalContent?.ctaFeedback ?? ''}
          onClick={createApplicationModal}
          noLink
        />
        <Button
          givenClass='modal--continue__button'
          label={continueModalContent?.textFeedback ?? ''}
          onClick={resumeApplicationModal}
          noLink
        />
      </div>
    </Modal>
  )
}
