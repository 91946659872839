/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { type Transaction } from '../domain'
import { TransactionRepositoryApi } from '../infra'

export async function createTransactionExecuteUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  transaction
}: {
  successCallback?: () => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  transaction: Transaction
}): Promise<void> {
  await TransactionRepositoryApi.create(transaction)
    .then(() => {
      logInfo('CreateTransactionExecuteUseCase')
      successCallback?.()
    })
    .catch((error) => {
      logError('CreateTransactionExecuteUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
