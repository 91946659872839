import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'

import { AMPLITUDE_TEST_AB_VERSION, amplitudeCompleteUpgrade } from '@legal/shared/amplitude'
import { LoadingPaymentProcessing } from '../../molecules'
import { type PaymentProcessingProps } from './PaymentProcessingProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { useApplicationCookie } from '@legal/shared/hooks'
import { usePaymentProcessingLogic } from '../../../services/hooks/usePaymentProcessingLogic'

export const PaymentProcessing: React.FC<PaymentProcessingProps> = ({
  paymentError,
  successNavigateToDownload,
  changeStatusToTimeout,
  paymentGatewayData,
  loadingPaymentMessage
}) => {
  const transactionStatus = usePaymentProcessingLogic()
  const { applicationCookie } = useApplicationCookie()
  const [mainComponent, setMainComponent] = React.useState(
    <span>
      <LoadingPaymentProcessing text={loadingPaymentMessage} />
    </span>
  )

  const subscriptionCookie = new SubscriptionCookie()

  React.useEffect(
    function checkingForStatusChanges() {
      switch (transactionStatus) {
        case 'authorised':
        case 'success':
          amplitudeCompleteUpgrade({
            formName: applicationCookie?.form?.name,
            subscriptionTypeName: subscriptionCookie.subscriptionTypeName,
            version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
          })
          successNavigateToDownload()
          break
        case 'error':
          setMainComponent(
            <span>
              <Feedback
                button={{
                  label: paymentGatewayData?.feedBackError.btnLabel ?? '',
                  onClick: paymentError,
                  noLink: true
                }}
                title={paymentGatewayData?.feedBackError.title ?? ''}
                theme='error'
                text={paymentGatewayData?.feedBackError.text ?? ''}
              />
            </span>
          )
          break
        case 'timeout':
          changeStatusToTimeout()
          break
        default:
          break
      }
    },
    [transactionStatus]
  )

  return <div>{mainComponent}</div>
}
