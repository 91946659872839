import { navigate } from 'gatsby'

import { PaymentCookie } from '../storage/cookies/PaymentCookie'
import { getAndSetDataLayer } from '@legal/shared/utils'

export interface toastResponseProps {
  text: string | null
  title: string | null
  type: string | null
}

export const navigateToPaymentDetails = (
  urlParameters: string,
  applicationId?: string,
  toastResponse?: toastResponseProps
): void => {
  if (applicationId) {
    getAndSetDataLayer({ applicationId })
  }
  const paymentCookie = new PaymentCookie()
  paymentCookie.clear()

  navigate('/payment-details' + urlParameters, {
    state: {
      toast: toastResponse
    }
  })
}
