/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { type FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'

import { ResetPassword } from '../../molecules'
import './ResetPasswordTemplate.scss'

export const ResetPasswordTemplate: FC = () => {
  const resetPasswordData = useQueryResetPassword()
  const Form = <ResetPassword resetPasswordData={resetPasswordData} />

  return (
    <div className='reset-password-container'>
      <div className='reset-password-module-container'>
        <BoxForm title={resetPasswordData.title} body={Form} />
      </div>
    </div>
  )
}

export const useQueryResetPassword = (): Record<string, any> => {
  const { strapiResetPasswordPage } = useStaticQuery(graphql`
    query {
      strapiResetPasswordPage {
        title
        minCharactersError
        passwordMatchError
        somethingWrongErrorToast {
          title
          text
          buttonText
          type
        }
        successToast {
          title
          text
          buttonText
          type
        }
        passwordInputField {
          label
          placeholder
        }
        repeatPasswordInputField {
          label
        }
        text {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
        buttonLabel
      }
    }
  `)

  return strapiResetPasswordPage
}
