/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames'

import { type AccordionItemProps } from './AccordionItemProps'
import Clear from '../../../../assets/images/componentsSvg/icon-clear.svg'
import './AccordionItem.scss'

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  title,
  index,
  onChangeItem,
  active = false,
  className
}) => {
  const handleSelectedItem = (): void => {
    if (active) {
      onChangeItem?.(null)
    } else {
      onChangeItem?.(index ?? null)
    }
  }

  const handleClose = (): void => {
    onChangeItem?.(null)
  }

  const classAccordionItem = classNames({
    accordionItem: true,
    [`${className}`]: className
  })

  return (
    <div className={classAccordionItem}>
      <div className={`accordionItem__title ${active ? 'open' : ''}`} onClick={handleSelectedItem}>
        {title}
      </div>
      <div className={`accordionItem__body ${!active ? 'collapsed' : ''}`}>
        <div className='accordionItem__content'>
          {children}
          <button className='accordionItem__close' onClick={handleClose}>
            <Clear className='accordionItem__close__icon' />
          </button>
        </div>
      </div>
    </div>
  )
}
