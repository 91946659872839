import React from 'react'
import { useWindowWidth } from './useWindowWidth'

export const useIsTablet = (timeoutInterval = 150): boolean => {
  const width = useWindowWidth(timeoutInterval)

  const [isTablet, setIsTablet] = React.useState<boolean>((width ?? 0) <= 1024 && (width ?? 0) >= 721)

  React.useEffect(() => {
    setIsTablet((width ?? 0) <= 1024 && (width ?? 0) >= 721)
  }, [width])

  return isTablet
}
