import { BaseBoxList } from '@npm_leadtech/legal-lib-components/BaseBoxList'
import { ContactBox } from '@npm_leadtech/legal-lib-components/ContactBox'
import React from 'react'

import ContactEmailIcon from '../../../assets/images/componentsSvg/icon-mail-24-p.svg'
import { type HelpCenterContactBoxProps } from './HelpCenterContactBoxProps'
import { IconWeb } from '../../../assets/images/componentsSvg/IconWeb'
import { useConfigs } from '@legal/core/configs'
import { useIsMobile } from '@legal/shared/hooks'
import './HelpCenterContactBox.scss'

export const HelpCenterContactBox: React.FC<HelpCenterContactBoxProps> = ({
  title,
  phone,
  schedule,
  email,
  text,
  mobileGtmTag
}) => {
  const { IS_JONSNOW } = useConfigs()
  const isMobile = useIsMobile()
  const emailComponent = (
    <div className='base-box__container'>
      <div className='contact-email'>
        <ContactEmailIcon />
        <a className='contact-email-link' href={`mailto:${email}`}>
          {email}
        </a>
      </div>
      <p className='contact-text'>{text}</p>
    </div>
  )

  const renderPhoneComponent = (): {
    active: boolean
    children: React.JSX.Element
  } | null => {
    if (isMobile) {
      return null
    }
    const phoneComponent = (
      <div className='base-box__container'>
        <span className='contact-phone'>
          <IconWeb />
          {phone}
        </span>
        <p className='contact-schedule'>{schedule}</p>
      </div>
    )
    return {
      active: true,
      children: phoneComponent
    }
  }

  return (
    <div className='help-center-contact-box'>
      <h2 className='help-center-contact-box__title'>{title}</h2>
      {isMobile && !IS_JONSNOW && (
        <ContactBox
          mobile
          phone={phone ?? ''}
          schedule={schedule ?? ''}
          mobileGtmTag={mobileGtmTag}
          dataQa={'contact_box'}
        />
      )}
      <BaseBoxList
        list={[
          !IS_JONSNOW && renderPhoneComponent(),
          {
            children: emailComponent
          }
        ]}
      />
    </div>
  )
}
