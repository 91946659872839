import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'

import { Modal } from '../../layout'
import { type PaypalErrorModalProps } from './PaypalErrorModalProps'

export const PaypalErrorModal: React.FC<PaypalErrorModalProps> = ({
  closeFunction,
  customClass,
  onPayWithPaypalClicked,
  title,
  feedBackError
}) => {
  return (
    <Modal
      title={title}
      size='S'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
      customClass={`paypal-error-modal ${customClass ?? ''}`}
    >
      <Feedback
        theme='error'
        title={feedBackError?.title}
        text={feedBackError?.text}
        button={{
          label: feedBackError?.btnLabel ?? '',
          onClick: closeFunction,
          noLink: true,
          dataQa: 'paypal-error-modal-button'
        }}
        cancel={{
          label: feedBackError?.btnCancelLabel,
          onClick: onPayWithPaypalClicked
        }}
      />
    </Modal>
  )
}
