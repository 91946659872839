import React from 'react'

import { IconSvgURLWithThemeColor } from '@npm_leadtech/legal-lib-components/IconSvgURLWithThemeColor'
import { type MenuSectionTitleProps } from './MenuSectionTitleProps'
import './MenuSectionTitle.scss'

export const MenuSectionTitle: React.FC<MenuSectionTitleProps> = ({ icon, title }) => {
  return (
    <div className='menu-section-title-container'>
      <IconSvgURLWithThemeColor url={icon} height='16' width='16' />
      <div className=''>
        <div className='jumbotron__title'>{title}</div>
      </div>
    </div>
  )
}
