/* eslint-disable @typescript-eslint/no-explicit-any */

export const getSectionList = (stepsData): any[] => {
  const allStepsInForm = stepsData.newStepStructure.steps
  const sections = []
  allStepsInForm.forEach((step) => {
    if (step.sectionNav && !sections.includes(step.sectionNav)) {
      sections.push(step.sectionNav)
    }
  })
  return sections
}
