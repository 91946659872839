/* eslint-disable @typescript-eslint/no-explicit-any */

// Return an array with the form fields that were just rendered in the current step as a result of the last input change
export function getNewRenderedFields(tempNoRender, oldNoRenderFields, structure, currentStep): any[] {
  let fieldsInCurrentStep = []
  if (structure?.steps) {
    structure.steps[currentStep - 1].groups.forEach((group) => {
      fieldsInCurrentStep = fieldsInCurrentStep.concat(group.fields)
    })
  }

  // Find all fields in current step that were not rendered previously and now are.
  const difference = []
  fieldsInCurrentStep.forEach((field) => {
    if (oldNoRenderFields.indexOf(field) !== -1 && tempNoRender.indexOf(field) === -1) {
      difference.push(field)
    }
  })

  return difference
}
