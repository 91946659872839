export function base64ToBlobFile(base64: string, mimeType: string): { blob: Blob; blobUrl: string } {
  const byteCharacters = atob(base64.replace(/\s/g, ''))
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: mimeType })
  const blobUrl = URL.createObjectURL(blob)
  return { blob, blobUrl }
}
