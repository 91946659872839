import { type CreditCard } from '../../domain'
import { type CreditCardData } from '../dataObjects/CreditCardData'
import { CreditCardDataToCreditCard } from '../adapters'
import { ENDPOINTS } from './Endpoints'
import { get } from '@legal/shared/HttpClient'

export async function findCreditCardsActiveByCustomer(): Promise<CreditCard[]> {
  const creditCardsdata = await get<CreditCardData[]>(ENDPOINTS.FIND_CREDIT_CARDS_ACTIVE_BY_CUSTOMER, true)
  return creditCardsdata.map(CreditCardDataToCreditCard)
}
