import React from 'react'

import { type CardStatusProps } from './CardStatusProps'
import './CardStatus.scss'

export const CardStatus: React.FC<CardStatusProps> = ({ status, isActive = false }) => {
  return (
    <div className='card-container'>
      <div className={`card-bg status-${isActive ? 'green' : 'red'}`}>
        <p className={`card-text status-${isActive ? 'green' : 'red'}`}>{status}</p>
      </div>
    </div>
  )
}
