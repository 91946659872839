import React from 'react'

import { Benefits } from '../../atoms'
import { FormContext } from '../../../services/utils/contexts'
import { Loading } from '../'
import { type PreviewAndPricingProps } from './PreviewAndPricingProps'
import { PriceContent } from '../PriceContent'
import { PricingTest } from '../../../services/constants/pricingTest'
import { TemplateContainer } from '../../template'
import { TestABCookie } from '../../../services/storage/cookies/TestABCookie'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'
import { useTestAB } from '../../../services/hooks/useTestAB'
import './PreviewAndPricing.scss'

export const PreviewAndPricing: React.FC<PreviewAndPricingProps> = ({
  buttonCta,
  title,
  template,
  formType,
  formSubType,
  draftActive = false,
  benefitsList,
  handleContinue,
  loading = true,
  subscription,
  subscriptionTypeName,
  version,
  loadingDataToPaymentPage = false,
  newPricingPage = false,
  callbackBottomOverlay
}) => {
  const { subscriptions } = useQuerySharedComponentsFromPaymentPage()
  const { formName, setSubscription, subscriptionsType } = React.useContext(FormContext)
  const [isMobileOrTablet, setIsMobileOrTablet] = React.useState<boolean>(false)
  const [isPricingTest19858] = useTestAB({ id: PricingTest.TEST_CODE, inUrlParam: true, inCookie: true })
  const strapiCommonsText = useQueryCommonsText()
  const shortBillingText = subscriptions?.find((sub) => sub?.name == subscription?.type?.name)?.shortBillingText ?? ''

  React.useEffect(() => {
    if (!!(window.screen.width <= 920) !== isMobileOrTablet) {
      setIsMobileOrTablet(!isMobileOrTablet)
    }
  }, [isMobileOrTablet])

  React.useEffect(() => {
    if (!isPricingTest19858) return
    const testABCookie = new TestABCookie(PricingTest.TEST_CODE)
    testABCookie.value = 'true'
  }, [isPricingTest19858])

  return (
    <>
      {title && newPricingPage && (
        <h1 className={`serif preview-and-pricing__title ${newPricingPage ? 'new-pricing-page' : ''}`}>{title}</h1>
      )}
      <div className={newPricingPage ? 'new-pricing-page' : ''}>
        <div className='preview-and-pricing'>
          {title && !newPricingPage && <h1 className='serif preview-and-pricing__title'>{title}</h1>}
          {!loading && isMobileOrTablet && (
            <div className={`preview-and-pricing__price-and-benefits ${isPricingTest19858 ? 'test19858' : ''}`}>
              <PriceContent
                formName={formName}
                buttonCta={buttonCta}
                handleContinue={handleContinue}
                subscription={subscription}
                setSubscription={setSubscription}
                subscriptionsType={subscriptionsType}
                currrencySymbol={strapiCommonsText.currrencySymbol || ''}
                currencyCode={strapiCommonsText.currencyCode || ''}
                subscriptionTypeName={subscriptionTypeName || subscription?.type.name}
                multipleSubscriptions={isPricingTest19858}
                loadingDataToPaymentPage={loadingDataToPaymentPage}
                hideMobileButtons={version === 'messi'}
              />
              <div className='preview-and-pricing__benefitsmobile'>
                <Benefits
                  list={benefitsList}
                  displayPrice={subscription?.displayPriceFormated ?? ''}
                  shortBillingText={shortBillingText}
                />
              </div>
            </div>
          )}
          {loading && isMobileOrTablet && <Loading />}
          <div className='preview-and-pricing__preview-and-price'>
            <div className='preview-and-pricing__preview'>
              <div className='template-container-wrapper'>
                <TemplateContainer
                  template={template}
                  hideHeader
                  documentType={formType}
                  categoryProduct={formSubType}
                  draftActive={draftActive}
                  borderRadius={true}
                  version={version}
                  newPricingPage
                  loadingDataToPaymentPage={loadingDataToPaymentPage}
                />
              </div>
            </div>
            {loading && <Loading />}
            {!loading && !isMobileOrTablet && (
              <div className='preview-and-pricing__price-and-benefits'>
                <PriceContent
                  formName={formName}
                  buttonCta={buttonCta}
                  handleContinue={handleContinue}
                  subscription={subscription}
                  setSubscription={setSubscription}
                  subscriptionsType={subscriptionsType}
                  currrencySymbol={strapiCommonsText.currrencySymbol || ''}
                  currencyCode={strapiCommonsText.currencyCode || ''}
                  subscriptionTypeName={subscriptionTypeName || subscription?.type.name}
                  multipleSubscriptions={isPricingTest19858}
                  loadingDataToPaymentPage={loadingDataToPaymentPage}
                  callbackBottomOverlay={callbackBottomOverlay}
                />
                <div className='preview-and-pricing__benefits'>
                  <Benefits
                    list={benefitsList}
                    displayPrice={subscription?.displayPriceFormated ?? ''}
                    shortBillingText={shortBillingText}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
