import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import './LoadingCards.scss'

export const LoadingCards: React.FC = () => (
  <div className='loading-cards-container'>
    <Spinner className='loading-cards-container__spinner spinner--primary' />
  </div>
)
