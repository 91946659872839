import * as REQUESTS from './apiRequests'
import { ContactFormRepository } from '../domain'

export const ContactFormRepositoryApi: ContactFormRepository = {
  async getDetails(): Promise<string> {
    return await REQUESTS.getContactCustomerDetails()
  },
  async send(data): Promise<void> {
    return await REQUESTS.sendContactForm(data)
  }
}
