import React, { useContext } from 'react'
import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'

import { type NavbarOverlayProps } from './NavbarOverlayProps'
import { OverlayContext } from '../../../services/utils/contexts'
import { useIsMobile } from '@legal/shared/hooks'
import './NavbarOverlay.scss'

export const NavbarOverlay: React.FC<NavbarOverlayProps> = ({
  customClassNames,
  steps,
  footer,
  onClose,
  sectionTrigger
}) => {
  const modalOpen = useContext(OverlayContext)
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <BottomOverlay
        givenClass='mobile-navbar'
        body={<ul className='sections__list'>{steps}</ul>}
        footer={footer}
        open={modalOpen}
        onClose={onClose}
      />
    )
  }

  return (
    <div>
      <nav className={`overlay desktop-navbar ${customClassNames} ${modalOpen ? 'show' : ''}`}>
        <div className={'overlay__delimiter'} />
        <div className='overlay__scrollable-section'>
          <ul className='sections__list'>{steps}</ul>
        </div>
      </nav>
      {sectionTrigger}
    </div>
  )
}
