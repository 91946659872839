/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { type FC } from 'react'

import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import { ForgotPassword } from '../../molecules'
import { replaceValues } from 'src/services/utils/replaceAll'
import { useConfigs } from '@legal/core/configs'

import './ForgotPasswordTemplate.scss'

export const ForgotPasswordTemplate: FC = () => {
  const {
    SITE_METADATA: { SITE_NAME }
  } = useConfigs()
  const forgotPasswordData = useQueryForgotPassword()
  const Form = <ForgotPassword forgotPasswordData={forgotPasswordData} />

  const boxFooter = {
    text: replaceValues(forgotPasswordData?.footerText, { SITE_NAME }) ?? `New to ${SITE_NAME}?`,
    button: {
      label: forgotPasswordData.footerButtonText,
      link: '/register',
      LinkComponent: Link
    }
  }

  return (
    <div className='forgot-password-container'>
      <div className='login-module-container'>
        <BoxForm title={forgotPasswordData.title} body={Form} footer={boxFooter} />
      </div>
    </div>
  )
}

export const useQueryForgotPassword = (): Record<string, any> => {
  const { strapiForgotPasswordPage } = useStaticQuery(graphql`
    query {
      strapiForgotPasswordPage {
        title
        footerText
        footerButtonText
        successToast {
          title
          text
          buttonText
          type
        }
        somethingWrongErrorToast {
          title
          text
          buttonText
          type
        }
        invalidEmailError
        emailInstructionsText
        emailInputField {
          label
          placeholder
        }
        sendEmailButtonText
        returnButtonText
      }
    }
  `)

  return strapiForgotPasswordPage
}
