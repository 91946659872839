/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import classNames from 'classnames'

import { type TemplateContainerProps } from './TemplateContainerProps'
import { sendAmplitudeData } from '@legal/shared/amplitude'
import { useQueryFormPage } from '@legal/shared/data/graphql/queries/useQueryFormPage'
import './TemplateContainer.scss'

export const TemplateContainer: React.FC<TemplateContainerProps> = ({
  template,
  hideHeader = false,
  documentType,
  categoryProduct,
  draftActive = false,
  borderRadius = false,
  minHeight = false,
  version = '',
  newPricingPage = false,
  dinamicScroll = false
}) => {
  const [isScrolling, setIsScrolling] = React.useState(false)
  const [templateFontSize, setTemplateFontSize] = React.useState('medium')
  const { templateContainer } = useQueryFormPage()
  const templateRef = React.useRef<HTMLDivElement | null>(null)
  const templateTopDivRef = React.useRef<HTMLDivElement | null>(null)
  const adjustScroll = 250
  const tableNodeNames = ['TD', 'TR', 'TBODY']

  React.useEffect(() => {
    dinamicScroll && scrollTemplate()
  }, [template])

  React.useEffect(() => {
    if (documentType && categoryProduct) {
      if (templateRef.current) {
        templateRef.current.addEventListener('scroll', scrollPreviewCheck)
      }
    }

    return () => {
      if (templateRef.current) {
        templateRef.current.removeEventListener('scroll', scrollPreviewCheck)
      }
    }
  }, [categoryProduct])

  const scrollPreviewCheck = (): void => {
    templateRef.current.removeEventListener('scroll', scrollPreviewCheck)
    const eventProps = {
      document_type: documentType,
      document_subtype: categoryProduct
    }
    sendAmplitudeData('scroll_preview_form', eventProps)
  }
  // fin testab 8814
  let timer = null
  const onScroll = function (): void {
    if (timer !== null) clearTimeout(timer)

    timer = setTimeout(() => {
      setIsScrolling(false)
    }, 150)
  }

  // Get element offset to the top of template container. It handles special case for tables.
  const getCumulativeYOffset = (element): any => {
    let yOffset = element.offsetTop

    while (element.offsetParent && tableNodeNames.includes(element.offsetParent.nodeName)) {
      yOffset += element.offsetParent.offsetTop
      element = element.offsetParent
    }
    return yOffset
  }

  const scrollTemplate = (): void => {
    const itemChangedElements = templateRef.current?.getElementsByClassName('itemChanged')
    const arrItemChangedElements = Array.prototype.slice.call(itemChangedElements)
    if (arrItemChangedElements && arrItemChangedElements.length > 0) {
      arrItemChangedElements.sort((a, b) => {
        // Sort by closest elements to scroll to
        if (
          Math.abs(a.offsetTop - templateRef.current.scrollTop) < Math.abs(b.offsetTop - templateRef.current.scrollTop)
        ) {
          return -1
        }
        if (
          Math.abs(a.offsetTop - templateRef.current.scrollTop) > Math.abs(b.offsetTop - templateRef.current.scrollTop)
        ) {
          return 1
        }
        return 0
      })

      const closestChangedItemForScroll = arrItemChangedElements[0]
      const offsetDistance = getCumulativeYOffset(closestChangedItemForScroll)

      if (!isScrolling && Math.abs(offsetDistance - (templateRef.current.scrollTop + adjustScroll)) > 100) {
        templateRef.current.scrollTo ?
          templateRef.current.scrollTo({
            top: offsetDistance - adjustScroll,
            behavior: 'smooth'
          })
        : (templateRef.current.scrollTop = offsetDistance - adjustScroll)
        setIsScrolling(true)
        templateRef.current?.addEventListener('scroll', onScroll, false)
      }
    }
  }

  const classTemplate = classNames({
    template: true,
    serif: true,
    [version]: true,
    draft: draftActive,
    'min-height-template': minHeight,
    'border-radius-template': borderRadius,
    'new-pricing-page-template': newPricingPage
  })

  return (
    <div
      className={`template-container ${templateFontSize} copying-not-allowed ${
        newPricingPage ? 'new-pricing-page-template' : ''
      }`}
      ref={templateTopDivRef}
    >
      {!hideHeader && (
        <div className='template__header'>
          <div className={'sans-serif --extra-small'}>{templateContainer?.item}</div>
          <div className='template__header__right'>
            <button
              data-qa={'changeSizeBig'}
              className={templateFontSize === 'big' ? 'isActive' : ''}
              onClick={() => {
                setTemplateFontSize('big')
              }}
            >
              <span>A</span>
            </button>
            <button
              data-qa={'changeSizeRegular'}
              className={templateFontSize === 'medium' ? 'isActive' : ''}
              onClick={() => {
                setTemplateFontSize('medium')
              }}
            >
              <span>A</span>
            </button>
            <button
              data-qa={'changeSizeSmall'}
              className={templateFontSize === 'small' ? 'isActive' : ''}
              onClick={() => {
                setTemplateFontSize('small')
              }}
            >
              <span>A</span>
            </button>
          </div>
        </div>
      )}
      <div className={classTemplate} ref={templateRef}>
        {template}
      </div>
    </div>
  )
}
