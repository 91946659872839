import { DocumentActions, DocumentBlob } from '../domain'
import { logError, logInfo } from '@legal/shared/logger'
import { DocumentRepositoryApi } from '../infra'
import { sendAmplitudeData } from '@legal/shared/amplitude'

export async function getDocumentBlobUseCase({
  request,
  successCallback,
  errorCallback,
  finallyCallback
}: {
  request: { documentId: string; documentAction: DocumentActions }
  successCallback: (documentBlob: DocumentBlob) => void
  errorCallback?: () => void
  finallyCallback?: () => void
}): Promise<void> {
  await DocumentRepositoryApi.download(request.documentId)
    .then((documentBlob: DocumentBlob) => {
      logInfo('getDocumentBlobUseCase success', request)
      const eventProps = {
        document_property: 'Client'
      }
      sendAmplitudeData(request.documentAction, eventProps)
      successCallback(documentBlob)
    })
    .catch((error: Error) => {
      logError(`getDocumentBlobUseCase error`, undefined, error)
      errorCallback?.()
    })
    .finally(() => {
      finallyCallback?.()
    })
}
