import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class VersionTestCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: setEnvironmentPrefixToCookie('versiontest') })
  }

  readItem(name): unknown {
    return this.read(name)
  }

  newItem(name, value): void {
    this.write({ key: name, data: value })
  }
}
