/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { navigate } from 'gatsby-link'

import { DASHBOARD_PATH, HOME_PATH, MY_ACCOUNT_PATH } from './payment.config'

const redirectOnTransactionPaid = ({ referrer, response = {}, subscriptionId, token }): null | undefined => {
  if (!response && !token) {
    return null
  }

  const errorMessage = `The subscription <${subscriptionId}> is paid`

  if (response.message === errorMessage && response.code === 412) {
    if (referrer?.includes(MY_ACCOUNT_PATH)) {
      navigate(HOME_PATH)
    } else {
      navigate(DASHBOARD_PATH)
    }
  }
}

export { redirectOnTransactionPaid }
