class ValidatorString {
  isEmptyValue(value: string): boolean {
    return value !== ''
  }

  isString(value: string): boolean {
    return typeof value === 'string'
  }

  isNumber(value: string): boolean {
    return !isNaN(Number(value))
  }

  containNumberOfCharsOrMore(value: string, numberOfChars: number): boolean {
    return value.length >= numberOfChars
  }

  containNumberOfCharsOrLess(value: string, numberOfChars: number): boolean {
    return value.length <= numberOfChars
  }

  containSpecificCharAtSpecificPostion(value: string, character: string, position: number): boolean {
    return value.indexOf(character) === position
  }

  containLowerCase(value: string): boolean {
    return /[a-z]/.test(value)
  }

  containUppercase(value: string): boolean {
    return /[A-Z]/.test(value)
  }

  containNumber(value: string): boolean {
    return /[0-9]/.test(value)
  }

  containSymbol(value: string): boolean {
    return /[^\w!@£]/.test(value)
  }
}

export const validatorString = new ValidatorString()
