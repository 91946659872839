import { type Application } from '../../domain'
import { type ApplicationData } from '../dataObjects'

export function ApplicationDataToApplication(applicationData: ApplicationData): Application {
  return {
    id: applicationData.id,
    name: applicationData.name,
    reference: applicationData.reference,
    state: applicationData.state,
    stateSlug: applicationData.state.toLowerCase().split(' ').join('-'),
    data: applicationData.data,
    lastStep: applicationData.lastStep ?? undefined,
    form: {
      id: applicationData.form.id,
      name: applicationData.form.name,
      url: getFormUrl(applicationData.form.name, applicationData.form.type, applicationData.state),
      type: applicationData.form.type,
      subType: applicationData.form.subType ?? undefined,
      driveId: applicationData.form.driveId ?? undefined
    }
  }
}

function getFormUrl(formName: string, formType: string, state?: string): string {
  const productFormUrl = formName.toLowerCase().split(' ').join('-')
  let formUrl = `/${productFormUrl}/form/?product=${formType.toLowerCase().split(' ').join('-')}`
  if (state !== formType) {
    formUrl = `${formUrl}&type=${state?.toLowerCase().split(' ').join('-')}`
  }
  return formUrl
}
