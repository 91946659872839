import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'

import { Modal } from '../../layout'
import { type PaymentErrorModalProps } from './PaymentErrorModalProps'

export const PaymentErrorModal: React.FC<PaymentErrorModalProps> = ({
  closeFunction,
  customClass,
  title,
  feedBackError
}) => {
  return (
    <Modal
      title={title}
      size='S'
      type='default'
      closeFunction={closeFunction}
      closeButton={false}
      customClass={customClass ?? ''}
    >
      <Feedback
        theme='error'
        title={feedBackError.title}
        text={feedBackError.text}
        button={{
          label: feedBackError.btnLabel ?? '',
          onClick: closeFunction,
          noLink: true,
          dataQa: 'modal--payment-error__button'
        }}
      />
    </Modal>
  )
}
