/* eslint-disable react-compiler/react-compiler */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link, graphql, useStaticQuery } from 'gatsby'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Divider } from '@npm_leadtech/legal-lib-components/Divider'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import { GoogleButton } from '@npm_leadtech/legal-lib-components/GoogleButton'
import React from 'react'
import { navigate } from 'gatsby-link'

import { type CustomerAuthResponse, CustomerAuthUseCase } from '@legal/customer'
import { Loading } from '../'
import { type SignInProps } from './SignInProps'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { TextInput } from '../../form'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { scrollToRef } from '../../../services/form/scrollToRef'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useDoGoogleSignInCallback } from '@legal/shared/hooks/useDoGoogleSignInCallback'
import './SignIn.scss'

export const SignIn: React.FC<SignInProps> = ({ isModal, actionLoginSucess, togglePasswordModal, closeModal }) => {
  const loginData = useQueryLogin()
  const [loading, updateLoading] = React.useState(false)
  const [message, updateMessage] = React.useState(loginData.somethingWrongErrorToast.text)
  const { applicationCookie } = useApplicationCookie()
  const [formData, setFormData] = React.useState({
    username: {
      value: '',
      validate: true,
      error: loginData.invalidEmailError
    },
    password: {
      value: '',
      validate: true,
      error: loginData.minCharactersError
    }
  })

  const { doGoogleSignIn } = useDoGoogleSignInCallback({
    isModal,
    formName: applicationCookie?.form?.name
  })

  const [status, updateStatus] = React.useState('initial')

  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()

  const onChange = (e): void => {
    const target = e.target
    const value = target.value
    const name = target.name
    const tempForm = { ...formData }

    tempForm[name].value = value

    setFormData(tempForm)
  }

  const resetErrors = (e): void => {
    const target = e.target
    const name = target.name
    const tempForm = { ...formData }

    tempForm[name].validate = true
    setFormData(tempForm)
  }

  const resetStatus = (): void => {
    updateStatus('initial')
    updateLoading(false)
  }

  const submitForm = React.useCallback(
    (e) => {
      e.preventDefault()
      const validate = (): boolean => {
        const tempForm = { ...formData }
        let result = true
        const reEmail = /^([\w][\w+&.-]+)@([\w-]+\.)+([\w-]{2,4})$/
        const isMail = reEmail.test(String(tempForm.username.value).toLowerCase())

        if (tempForm.password.value.length < 8) {
          tempForm.password.validate = false
          scrollToRef('password', 100)
          setFormData(tempForm)
          result = false
        }

        if (tempForm.username.value === '' || !isMail) {
          tempForm.username.validate = false
          scrollToRef('username', 100)
          setFormData(tempForm)
          result = false
        }

        return result
      }

      const handleRedirect = (response: CustomerAuthResponse): void => {
        const userHasRecurrentActiveSubscription = response.subscriptionId
        if (userHasRecurrentActiveSubscription) {
          subscriptionCookie.subscriptionId = response.subscriptionId
          navigate('/my-account/my-documents/create-document-pdf/')
        } else if (applicationCookie?.id) {
          navigate('/pricing')
        } else {
          navigate('/my-account/my-documents')
        }
      }

      const handleSuccessfulLogin = (response: CustomerAuthResponse): boolean | undefined => {
        userCookie.token = response.token
        userCookie.email = formData.username.value
        if (actionLoginSucess) {
          actionLoginSucess(response)
          return
        }
        if (isModal) {
          location.reload()
          if (closeModal) closeModal()
          return false
        }
        handleRedirect(response)
      }

      const dataIsValid = validate()

      if (dataIsValid) {
        updateLoading(true)
        CustomerAuthUseCase({
          request: {
            username: formData.username.value,
            password: formData.password.value,
            applicationId: applicationCookie?.id,
            subscriptionId: subscriptionCookie.subscriptionId
          },
          successCallback: handleSuccessfulLogin,
          errorCallback: (error) => {
            updateLoading(false)
            if (error?.message) {
              updateMessage(error.message)
            }
            updateStatus('error')
          }
        })
      }
    },
    [formData, subscriptionCookie, userCookie, actionLoginSucess, isModal, closeModal]
  )

  const feedbackError = {
    theme: loginData.somethingWrongErrorToast.type,
    title: loginData.somethingWrongErrorToast.title,
    text: message,
    button: {
      label: loginData.somethingWrongErrorToast.buttonText,
      noLink: true,
      onClick: resetStatus
    }
  }

  const MainComponent =
    status === 'initial' ?
      <div>
        {loading ?
          <Loading />
        : <form onSubmit={submitForm}>
            <GoogleButton onClick={doGoogleSignIn}></GoogleButton>
            <Divider text={loginData.orText}></Divider>
            <TextInput
              type={'text'}
              placeholder={loginData.emailInputField.placeholder}
              value={formData.username.value}
              name={'username'}
              label={loginData.emailInputField.label}
              validate={formData.username.validate}
              errorMessage={formData.username.error}
              required={false}
              isValidGroup={true}
              onChange={onChange}
              onClick={resetErrors}
            />
            <TextInput
              type={'password'}
              placeholder={loginData.passwordInputField.placeholder}
              maxlength={'16'}
              value={formData.password.value}
              name={'password'}
              label={loginData.passwordInputField.label}
              validate={formData.password.validate}
              errorMessage={formData.password.error}
              required={false}
              isValidGroup={true}
              onChange={onChange}
              onClick={resetErrors}
            />
            <div className='box--form__body__footer'>
              <Button
                label={loginData.signInButtonText}
                givenClass='box--form__button--main'
                noLinkNoFunc
                dataQa={'signIn'}
              />
              {isModal ?
                <Button
                  givenClass='forgot-password-signin'
                  color='tertiary'
                  noLink
                  onClick={() => {
                    togglePasswordModal()
                  }}
                  label={loginData.forgottenPasswordButtonText}
                  dataQa={'forgotPassword'}
                />
              : <Button
                  givenClass='forgot-password-signin'
                  color='tertiary'
                  link={'/forgot-password'}
                  LinkComponent={Link}
                  label={loginData.forgottenPasswordButtonText}
                  dataQa={'forgotPassword'}
                />
              }
            </div>
          </form>
        }
      </div>
    : <Feedback {...feedbackError} />

  return <div>{MainComponent}</div>
}

export const useQueryLogin = (): Record<string, any> => {
  const { strapiLoginPage } = useStaticQuery(graphql`
    query {
      strapiLoginPage {
        title
        footerText
        footerButtonText
        invalidEmailError
        minCharactersError
        somethingWrongErrorToast {
          title
          text
          buttonText
          type
        }
        orText
        emailInputField {
          label
          placeholder
        }
        passwordInputField {
          label
          placeholder
        }
        signInButtonText
        forgottenPasswordButtonText
      }
    }
  `)

  return strapiLoginPage
}
