import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { logError, logInfo } from '@legal/shared/logger'
import { getConfigs } from '@legal/core/configs'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ResetPasswordCustomer {
  public static async Execute({
    token,
    password
  }: {
    token: string
    password: object
  }): Promise<HttpClientResponse<unknown>> {
    const { API_CONFIG } = getConfigs()
    const http = HttpClient.create({
      baseUrl: API_CONFIG.API_PREFIX,
      defaults: {
        headers: {
          Authorization: API_CONFIG.HTTP_SECRET_KEY
        }
      }
    })

    const body = { token, password }

    const responsePromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
      http
        .put('customer/auth/update-password', body)
        .then((response) => {
          logInfo('ResetPasswordCustomer')
          resolve(response)
        })
        .catch((error: object | undefined) => {
          logError('ResetPasswordCustomer', error)
          reject(error)
        })
    })

    return await responsePromise
  }
}
