/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'
import { type PdfEncoded } from '../domain'

export async function downloadApplicationPdfUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (pdfEncoded?: PdfEncoded) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): Promise<void> {
  await ApplicationRepositoryApi.downloadPdf(applicationId)
    .then((pdfEncoded: PdfEncoded) => {
      logInfo(`DownloadApplicationPdfUseCase applicationId: ${applicationId}`)
      successCallback(pdfEncoded)
    })
    .catch((error: Error | undefined) => {
      logError(`DownloadApplicationPdfUseCase applicationId: ${applicationId}`, undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
