import { graphql, useStaticQuery } from 'gatsby'

export interface SideMenuProps {
  sideMenu: {
    menuText: string
    logoutText: string
    sideMenuItems: SideMenuItemsProps[]
  }
  documentsSection: {
    newDocumentButtonText: string
  }
}

export interface SideMenuItemsProps {
  mobileText: string
  desktopText: string
}

export const useQuerySideMenu = (): SideMenuProps => {
  const { strapiMyDocumentsPage } = useStaticQuery(graphql`
    query {
      strapiMyDocumentsPage {
        uploadButtonText
        sideMenu {
          menuText
          logoutText
          sideMenuItems {
            mobileText
            desktopText
          }
        }
        documentsSection {
          newDocumentButtonText
        }
      }
    }
  `)

  return strapiMyDocumentsPage
}
