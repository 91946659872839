type FormatDates = Record<string, Intl.DateTimeFormatOptions | undefined>
interface FormatDateParams {
  date: Date | string
  format?: string
  locale?: string
}

function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime())
}

export const toDate = (date?: string | null): Date => {
  const _date = new Date(date ?? Date.now())
  if (!isValidDate(_date)) {
    throw new Error('Invalid Date')
  }
  return _date
}

export const isDateBefore = (start: string, end: string): boolean => toDate(start) < toDate(end)

export const getDaysDifferenceBetweenDates = (start: string, end: string): number => {
  const _start = toDate(start)
  const _end = toDate(end)

  const timeDifference = _end.getTime() - _start.getTime()

  return Math.trunc(timeDifference / (1000 * 60 * 60 * 24))
}

const formats: FormatDates = {
  'MM/DD/YYYY': { day: '2-digit', month: '2-digit', year: 'numeric' },
  'MMMM Do, YYYY': { day: 'numeric', month: 'long', year: 'numeric' },
  'MMMM, YYYY': { month: 'long', year: 'numeric' },
  'DD/MM/YYYY [at] h:mm a': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
}

export const formatDate = ({ date, format, locale = process.env.STRAPI_LOCALE }: FormatDateParams): string => {
  const _date = typeof date === 'string' ? toDate(date) : date
  const formatter = new Intl.DateTimeFormat(locale, formats[format ?? ''])
  const formattedDate = formatter.format(_date)
  return formattedDate
}
