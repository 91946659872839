import React from 'react'

export const AcceptCookieContext = React.createContext(null)

export const LoginContext = React.createContext(false)

export const OverlayContext = React.createContext(false)

export const FormContext = React.createContext({})

export const PaymentContext = React.createContext({
  isFreeTrial: false,
  toggleFreeTrial: () => {}
})
