/* eslint-disable @typescript-eslint/no-explicit-any */
import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'
import { type InfoDatalayer } from '../domain'

export async function getInfoDatalayerUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  applicationId
}: {
  successCallback: (infoDatalayer: InfoDatalayer) => void
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  applicationId: string
}): Promise<void> {
  await ApplicationRepositoryApi.getInfoDatalayer(applicationId)
    .then((infoDatalayer: InfoDatalayer) => {
      logInfo(`GetInfoDatalayerUseCase applicationId: ${applicationId}`)
      successCallback(infoDatalayer)
    })
    .catch((error: Error | undefined) => {
      logError(`GetInfoDatalayerUseCase applicationId: ${applicationId}`, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
