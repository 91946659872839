/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react'

import { useConfigs } from '@legal/core/configs'

import { DocumentSubMenu } from '../DocumentSubMenu/DocumentSubMenu'

import { menuMapper } from '../../../../src/modules/shared/utils/menuMapper'
import { stringSlugify } from '../../../services/utils/stringSlugify'

import { type DocumentMainMenuProps } from './DocumentMainMenuProps'
import { IconSvgURLWithThemeColor } from '@npm_leadtech/legal-lib-components/IconSvgURLWithThemeColor'

export const DocumentMainMenu: React.FC<DocumentMainMenuProps> = ({
  products,
  isDocumentActionsPage = false,
  data,
  closeNewDocumentMenu
}) => {
  const { TARGET_ADDRESS } = useConfigs()

  const finalMenu = menuMapper(products)
  const menuItemsRef = useRef<HTMLUListElement | null>(null)

  useEffect(() => {
    if (menuItemsRef.current) {
      const menuItems = menuItemsRef.current.querySelectorAll<HTMLLIElement>('.menu-items-li')
      let submenuCounter = 0
      menuItems.forEach((item) => {
        const submenu = item.querySelector<HTMLUListElement>('.submenu__list')
        if (submenu) {
          submenu.style.top = `${-1 - submenuCounter * 2.5}rem`
          submenuCounter++
        }
      })
    }
  }, [finalMenu])

  return (
    <>
      {isDocumentActionsPage && (
        <>
          <div className='documents-title-container'>
            <h3 className='documents-title'>{data ? data.title : ''}</h3>
          </div>
          <div className='modal__close' onClick={closeNewDocumentMenu}>
            &times;
          </div>
        </>
      )}
      <div className='menu-items' ref={menuItemsRef}>
        {finalMenu.map((item, index) => (
          <li className='menu-items-li' key={index}>
            <IconSvgURLWithThemeColor url={item.icon} height='16' width='16' />
            <div>{item.type}</div>
            <ul className={'submenu__list'} key={index}>
              {item.products.slice(0, 6).map((product, index) => (
                <DocumentSubMenu product={product} key={index} />
              ))}
              <li className='menu-items-li all-item'>
                <a
                  title={`See All ${item.type} Documents`}
                  data-qa={`${stringSlugify(item.type)}_all-documents`}
                  href={`${TARGET_ADDRESS}/legal-documents/#${stringSlugify(item.type)}`}
                >
                  See All {item.type} Documents
                </a>
              </li>
            </ul>
          </li>
        ))}
        <li className='menu-items-li all-item'>
          <a title='All Legal Documents' data-qa='all-documents' href={`${TARGET_ADDRESS}/legal-documents/`}>
            See All Legal Documents
          </a>
        </li>
      </div>
    </>
  )
}
