export const formatPrice = (price: number | undefined): string => {
  if (price === undefined || price === null) return '0'
  const initialAmount = price.toFixed(2)
  const [integer, decimal] = initialAmount.split('.')
  if (decimal === '00') {
    return integer
  }
  if (decimal.length === 1) {
    return `${initialAmount}0`
  }
  return initialAmount
}
