import React from 'react'
import { useWindowWidth } from './useWindowWidth'

export const useIsSmallDevice = (timeoutInterval = 150): boolean => {
  const width = useWindowWidth(timeoutInterval)

  const [isSmallDevice, setIsSmallDevice] = React.useState<boolean>((width ?? 0) < 720)

  React.useEffect(() => {
    setIsSmallDevice((width ?? 0) < 575)
  }, [width])

  return isSmallDevice
}
