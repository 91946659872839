import React from 'react'

import { type AllTypeDocumentsItemMenuProps } from './AllTypeDocumentsItemMenuProps'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useConfigs } from '@legal/core/configs'

export const AllTypeDocumentsItemMenu: React.FC<AllTypeDocumentsItemMenuProps> = ({ type }) => {
  const { TARGET_ADDRESS } = useConfigs()
  return (
    <li className='products__list__section__li all-item'>
      <a
        title={`See All ${type} Documents`}
        data-qa={`${stringSlugify(type)}_all-documents`}
        href={`${TARGET_ADDRESS}/legal-documents/#${stringSlugify(type)}`}
      >
        See All {type} Documents
      </a>
    </li>
  )
}
