import { VersionTestCookie } from '../../../storage/cookies/VersionTestCookie'
import { getParamUrlValue } from '../../../utils/paramsUrl'

const getVersionTestEnableWithProduct = ({ dataVersion, product }): unknown => {
  let version = null
  if (dataVersion?.trim()) {
    version = dataVersion
  }

  const versionParamInUrl = !version && versionInParamUrl()
  if (versionParamInUrl) {
    version = versionParamInUrl
  }

  const versionInCookie = !versionParamInUrl && validateVersionInCookie(product)
  if (versionInCookie) {
    version = versionInCookie
  }

  return version
}

const versionInParamUrl = (): unknown => {
  return getParamUrlValue('version')
}

const validateVersionInCookie = (product): unknown => {
  if (product) {
    const versionTestCookie = new VersionTestCookie()
    const productClean = product.trim()
    const versionTestCookieData = versionTestCookie.readItem(productClean)
    if (versionTestCookieData) return versionTestCookieData
  }
  return null
}

export { getVersionTestEnableWithProduct }
