import * as REQUESTS from './apiRequests'
import { TransactionRepository } from '../domain'

export const TransactionRepositoryApi: TransactionRepository = {
  async adyenExecute(transaction) {
    return REQUESTS.adyenExecuteTransaction(transaction)
  },
  async create(transaction) {
    return REQUESTS.createTransactionExecute(transaction)
  },
  async getStatus(transactionId) {
    return REQUESTS.getTransactionStatus(transactionId)
  },
  async getStatusNoAuth(transactionId) {
    return REQUESTS.getTransactionStatusNoAuth(transactionId)
  },
  async updateStatus(transactionId) {
    return REQUESTS.updateTransactionStatus(transactionId)
  }
}
