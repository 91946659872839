import { sendAmplitudeData, setAmplitudeUserProperties } from './amplitude'

const amplitudeSubmitPayment = ({
  formProduct,
  subscriptionTypeName,
  version
}: {
  formProduct: string
  subscriptionTypeName: string
  version: string
}): void => {
  const eventProps = {
    pricing_plan: subscriptionTypeName,
    initial_document_type: formProduct,
    version
  }
  sendAmplitudeData('submit_payment', eventProps)
  setAmplitudeUserProperties('plan_type', subscriptionTypeName)
}

export { amplitudeSubmitPayment }
