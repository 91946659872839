export const scrollToRef = (ref: string, windowOffset = 200): void => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const item = document.getElementById(ref)
    if (item) {
      window.scrollTo({
        top: document.getElementById(ref).getBoundingClientRect().top + window.scrollY - windowOffset,
        behavior: 'smooth'
      })
    }
  }
}
