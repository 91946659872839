import { type Application } from '@legal/application'

export const addValuesToTemporalForm = (application: Application, tempForm): void => {
  Object.keys(application.data).forEach((step) => {
    Object.keys(application.data[step]).forEach((field) => {
      if (tempForm[field]) {
        tempForm[field].value = application.data[step][field]
      }
    })
  })
}
