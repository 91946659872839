import * as REQUESTS from './apiRequest'
import { ApplicationRepository } from '../domain/ApplicationRepository'

export const ApplicationRepositoryApi: ApplicationRepository = {
  async create(request) {
    return await REQUESTS.createApplication(request)
  },
  async delete(applicationId) {
    return await REQUESTS.deleteApplication(applicationId)
  },
  async downloadPdf(applicationId) {
    return await REQUESTS.downloadApplicationPdf(applicationId)
  },
  async duplicate(applicationId) {
    return await REQUESTS.duplicateApplication(applicationId)
  },
  async findById(applicationId) {
    return await REQUESTS.findApplicationByUuid(applicationId)
  },
  async getSku(applicationId) {
    return await REQUESTS.getApplicationSku(applicationId)
  },
  async getStatus(applicationId) {
    return await REQUESTS.getApplicationStatus(applicationId)
  },
  async getHtmlPreview(applicationId) {
    return await REQUESTS.getHtmlPreviewByUuid(applicationId)
  },
  async getInfoDatalayer(applicationId) {
    return await REQUESTS.getInfoDatalayer(applicationId)
  },
  async updateData(applicationId, request) {
    return await REQUESTS.updateApplicationData(applicationId, request)
  },
  async updateForm(applicationId, request) {
    return await REQUESTS.updateApplicationForm(applicationId, request)
  },
  async updateName(applicationId, name) {
    return await REQUESTS.updateApplicationName(applicationId, name)
  }
}
