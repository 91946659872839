import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function CreateCustomerWithSubscription({
  email,
  subscriptionId,
  applicationId
}: {
  email: string
  subscriptionId: string
  applicationId: string | undefined
}): Promise<{ token: string; customerId: string }> {
  const body = { email, password: '', subscriptionId, applicationId }
  const result = await post<{
    token: string
    customerId: string
  }>(ENDPOINTS.CUSTOMER_WITH_SUBSCRIPTION, body)
  return { token: result.token, customerId: result.customerId }
}
